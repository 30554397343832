import {
  Breadcrumbs,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useDevelopment } from "@/shared/hooks/api";

import { UnitCreateForm } from "../components";

export function UnitCreate() {
  const { developmentId, blockId } = useRequiredParams();
  const developmentQuery = useDevelopment(developmentId);
  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <div className="flex-1 space-y-4 overflow-y-scroll p-8 animate-in fade-in slide-in-from-left-2">
        <Breadcrumbs />
        <PageTitle primary>Unidade</PageTitle>
        <QueryHandler {...developmentQuery} renderLoading={() => <Spinner />}>
          {(data) => (
            <UnitCreateForm
              redirectTo="../"
              useFormProps={{
                defaultValues: {
                  unidade: {
                    blocoId: +blockId,
                    dataEntrega: data?.dataEntrega
                      ? new Date(data?.dataEntrega)
                      : undefined,
                  },
                },
              }}
              empreendimentoId={+developmentId}
            />
          )}
        </QueryHandler>
      </div>
    </div>
  );
}
