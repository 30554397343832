import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";
import {
  FieldError,
  Input,
  Label,
  TextField,
  ToggleButton,
} from "react-aria-components";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface FormInputProps<T extends FieldValues>
  extends Omit<React.ComponentProps<typeof TextField>, "name"> {
  label: string;
  control: Control<T>;
  name: Path<T>;
}

export function PublicFormTextInput<T extends FieldValues>({
  control,
  label,
  name,
  ...textFieldProps
}: FormInputProps<T>) {
  const isSecret = textFieldProps?.type === "password";
  const [showSecret, setShowSecret] = useState(!isSecret);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <TextField
          {...textFieldProps}
          className="relative mb-5 mt-3"
          type={showSecret ? "text" : "password"}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          validationBehavior="aria"
          isInvalid={invalid}
        >
          <Input
            className={` ${isSecret && "pr-8"} peer h-12 w-full border-b border-gray-1 placeholder-transparent`}
            placeholder={label}
            ref={ref}
          />
          <Label
            className={`absolute -top-3 left-0 text-xs text-gray-12 transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:transform peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-3 peer-focus:-top-3 peer-focus:text-xs peer-focus:text-gray-10`}
          >
            {label}
          </Label>
          {isSecret && (
            <ToggleButton
              className={`absolute right-1 top-3 transform text-gray-6`}
              isSelected={showSecret}
              onPress={() => setShowSecret(!showSecret)}
            >
              {({ isSelected }) => (
                <>
                  {isSelected ? (
                    <Eye variant="Bold" />
                  ) : (
                    <EyeSlash variant="Bold" />
                  )}
                </>
              )}
            </ToggleButton>
          )}
          <FieldError className="text-xs font-semibold text-error">
            {error?.message}
          </FieldError>
        </TextField>
      )}
    />
  );
}
