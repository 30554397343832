import {
  AnimatedContainer,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationSystemDelete,
  useMutationSystemUpdate,
  useSystem,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function SystemData() {
  const { systemId } = useRequiredParams();
  const systemQuery = useSystem(systemId);
  const mutationSystemUpdate = useMutationSystemUpdate();
  const mutationSystemDelete = useMutationSystemDelete();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler {...systemQuery}>
          {(data) => (
            <>
              <div className="flex flex-col">
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <PageTitle primary className="break-all">
                        {data?.nome}
                      </PageTitle>
                      <Can I="update.system">
                        <EditLink />
                      </Can>
                      <Can I="delete.system">
                        <DeleteResourceButton
                          resource="sistema"
                          subject={data?.nome ?? ""}
                          action={() =>
                            mutationSystemDelete.mutateAsync({
                              id: +systemId,
                            })
                          }
                          showSpinner={mutationSystemDelete.isPending}
                        />
                      </Can>
                    </div>
                  </div>
                  {data && (
                    <Can I="update.system">
                      <SwitchStatusButton
                        isDisabled={mutationSystemUpdate.isPending}
                        isSelected={data?.flagAtivo}
                        onChange={(status) =>
                          mutationSystemUpdate.mutateAsync({
                            id: +systemId,
                            requestBody: { flagAtivo: status },
                          })
                        }
                      />
                    </Can>
                  )}
                </div>
                <div className="flex gap-6 text-xs text-primary">
                  <p>
                    Tipo do cliente:{" "}
                    <span className="font-semibold">
                      {data?.tipoCliente?.nome}
                    </span>
                  </p>
                </div>
              </div>

              <Separator />
              <p className="mb-3 text-sm text-black-2">Descrição</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.descricao ?? "Vazio..."),
                  }}
                />
              </div>
              <p className="mb-3 text-sm text-black-2">Cuidados de uso</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.cuidados ?? "Vazio..."),
                  }}
                />
              </div>
              <p className="mb-3 text-sm text-black-2">Perdas de garantia</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.perdas ?? "Vazio..."),
                  }}
                />
              </div>
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
