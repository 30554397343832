import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  Unidade,
  UpdateObjUnidadesIdPutData,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationUnitUpdateConfig
  extends Omit<
    UseMutationOptions<Unidade, Error, UpdateObjUnidadesIdPutData>,
    "mutationFn"
  > {}

export function useMutationUnitUpdate(options?: UseMutationUnitUpdateConfig) {
  return useMutation({
    mutationFn: apiProvider.services.UnidadesService.updateObjUnidadesIdPut,
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["unit", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar a unidade: ${e.message}`,
      }),
    ...options,
  });
}
