import { Button } from "./button";

interface ApprovalHeaderProps {
  title: string;
  turnApproveModalOn: () => void;
  turnRejectModalOn: () => void;
}

export function ApprovalHeader({
  title,
  turnApproveModalOn,
  turnRejectModalOn,
}: ApprovalHeaderProps) {
  return (
    <div className="flex items-center justify-between rounded-lg border border-white-3 bg-white-0 p-4">
      <span className="text-sm font-semibold text-black-2">{title}</span>
      <div className="flex gap-3">
        <Button
          className="rounded bg-success-20 px-3 py-2 text-sm font-medium text-success"
          onPress={turnApproveModalOn}
        >
          Aprovar
        </Button>
        <Button
          className="rounded bg-error-20 px-3 py-2 text-sm font-medium text-error"
          onPress={turnRejectModalOn}
        >
          Reprovar
        </Button>
      </div>
    </div>
  );
}
