import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { PostCreate } from "../../create/schemas";

export async function postEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<PostCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, capa, ...payload }) => {
      const postId = +params.postId!;

      await apiProvider.services.PostsService.updateObjPostIdPut({
        id: postId,
        requestBody: {
          capa: capa?.[0].key,
          ...payload,
        },
      });

      toastQueue.add(
        { type: "success", message: "Post atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
