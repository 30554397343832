import { cn } from "../utils";

interface ActivityCardProps extends React.ComponentProps<"div"> {
  style?: React.CSSProperties;
}

export function ActivityCard({
  className,
  children,
  style,
}: ActivityCardProps) {
  return (
    <div
      className={cn(
        "w-full rounded-md bg-primary-light-1 px-4 pb-4 pt-3",
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}
