import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";

import { falsyToNull, getDigits, z } from "@/shared/utils";

export const createUnitNewClientSchemaFactory = (minDataEntrega: Date) =>
  z.object({
    clientCreationType: z.literal("new-client"),
    unidade: z.object({
      nome: z.string().trim().min(1, "Obrigatório"),
      blocoId: z.number().int(),
      dataEntrega: z.date().min(minDataEntrega).nullish(),
      tipoClientId: z.number().int(),
    }),
    responsavel: z.object({
      nome: z.string().trim().min(1, "Obrigatório"),
      email: z.string().email(),
      telefone: z
        .string()
        .nullish()
        .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
      cpf: z
        .string()
        .nullish()
        .transform((v) => (v ? getDigits(v) : v))
        .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
    }),
  });

export const createUnitRegisteredClientSchemaFactory = (minDataEntrega: Date) =>
  z.object({
    clientCreationType: z.literal("registered-client"),
    unidade: z.object({
      nome: z.string().trim().min(1, "Obrigatório"),
      blocoId: z.number().int(),
      dataEntrega: z.date().min(minDataEntrega).nullish(),
      donoId: falsyToNull(z.number().int().optional()),
      tipoClientId: z.number().int().nullish(),
    }),
  });

export const createUnitNoClientSchemaFactory = (minDataEntrega: Date) =>
  z.object({
    clientCreationType: z.literal("no-client"),
    unidade: z.object({
      nome: z.string().trim().min(1, "Obrigatório"),
      blocoId: z.number().int(),
      dataEntrega: z.date().min(minDataEntrega).nullish(),
      tipoClientId: z.number().int().nullish(),
    }),
  });

export const createUnitSchemaFactory = (minDataEntrega: Date) =>
  z.discriminatedUnion("clientCreationType", [
    createUnitNewClientSchemaFactory(minDataEntrega),
    createUnitRegisteredClientSchemaFactory(minDataEntrega),
    createUnitNoClientSchemaFactory(minDataEntrega),
  ]);

export type CreateUnit = z.infer<ReturnType<typeof createUnitSchemaFactory>>;
export type CreateUnitNoClient = z.infer<
  ReturnType<typeof createUnitNoClientSchemaFactory>
>;
