import { getLocalTimeZone } from "@internationalized/date";
import {
  ArrowLeft2,
  ArrowRight2,
  Calendar as CalendarIcon,
  CloseCircle,
} from "iconsax-react";
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker,
  type DatePickerProps,
  DateSegment,
  type DateValue,
  Dialog,
  FieldError,
  Group,
  Heading,
  Label,
  Popover,
  type ValidationResult,
} from "react-aria-components";

import { cn } from "../utils";

export interface DatePickerInputProps<T extends DateValue>
  extends Omit<DatePickerProps<T>, "onChange"> {
  label?: string;
  hiddenLabel?: boolean;
  errorMessage?: string | ((validation: ValidationResult) => string);
  onReset?: () => void;
  onChange?: (date?: string) => void;
}

export function DatePickerInput<T extends DateValue>({
  label,
  hiddenLabel,
  errorMessage,
  onReset,
  onChange,
  className,
  ...props
}: DatePickerInputProps<T>) {
  return (
    <DatePicker
      className={cn("text-sm text-gray-6", className)}
      onChange={(date) =>
        onChange?.(
          date
            ? date.toDate(getLocalTimeZone()).toISOString().split("T")[0]
            : undefined,
        )
      }
      {...props}
    >
      <Label hidden={hiddenLabel}>{label}</Label>
      <Group className="group flex w-full justify-between gap-2 rounded-lg border border-white-3 bg-white-0 p-3">
        {props.value && (
          <Button slot={null} onPress={onReset}>
            <CloseCircle size={20} />
          </Button>
        )}
        <DateInput className="flex flex-1">
          {(segment) => <DateSegment segment={segment} />}
        </DateInput>
        <Button className="relative">
          <CalendarIcon
            className="absolute group-hover:animate-ping group-hover:repeat-1"
            size={20}
          />
          <CalendarIcon size={20} />
        </Button>
      </Group>
      <FieldError>{errorMessage}</FieldError>
      <Popover className="rounded-lg border border-white-3 bg-white-0 p-3 text-sm shadow-dialog">
        <Dialog>
          <Calendar>
            <header className="mb-4 flex items-center justify-center gap-3">
              <Button className="group" slot="previous">
                <ArrowLeft2
                  className="transition-all group-hover:-translate-x-1"
                  variant="Bold"
                />
              </Button>
              <Heading className="flex-1 cursor-default text-center first-letter:uppercase" />
              <Button className="group" slot="next">
                <ArrowRight2
                  className="transition-all group-hover:translate-x-1"
                  variant="Bold"
                />
              </Button>
            </header>
            <CalendarGrid className="w-full border-spacing-1">
              {(date) => (
                <CalendarCell
                  className="flex size-9 cursor-default items-center justify-center rounded-md rac-hover:bg-primary/10 rac-selected:bg-primary rac-selected:text-white-0 rac-disabled:text-transparent"
                  date={date}
                />
              )}
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </DatePicker>
  );
}
