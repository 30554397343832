import { ArrowDown2 } from "iconsax-react";
import { forwardRef } from "react";
import React from "react";
import {
  Button,
  FieldError,
  Group,
  Label,
  ListBox,
  Popover,
  Select,
  SelectProps,
  SelectValue,
} from "react-aria-components";

import { cn } from "@/shared/utils";

import { ClearSelectButton } from "../clear-select-button";
import {
  InputStyleProps,
  container,
  input,
  label as labelStyle,
} from "./style/input";

export interface SelectInputProps<T extends object>
  extends SelectProps<T>,
    InputStyleProps {
  label?: string;
  errorMessage?: string;

  /* HACK: Behavior should be implemented by lib but was done manually */
  isReadOnly?: boolean;
}

function SelectInput<T extends object>(
  {
    className,
    label,
    errorMessage,
    children,
    isReadOnly,
    isDisabled,
    ...props
  }: SelectInputProps<T>,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <Select
      {...props}
      className={cn(container(props), className)}
      placeholder="Selecione"
      isDisabled={isDisabled || isReadOnly}
    >
      {props.styleType !== "filter" && (
        <Label className={labelStyle(props)}>{label}</Label>
      )}
      <Group className="flex items-center gap-1">
        {props.styleType !== "filter" && !isDisabled && !isReadOnly && (
          <ClearSelectButton />
        )}
        <Button
          ref={ref}
          className={cn(
            input(props),
            "group flex flex-1 items-center justify-between",
          )}
          data-cy={props.name ?? "select"}
        >
          <div className="flex items-center">
            <span>
              {props.styleType === "filter" && (
                <Label className={labelStyle(props)}>{label}: </Label>
              )}
              <SelectValue
                className={cn("rac-placeholder-shown:text-gray-4", {
                  "font-semibold": props.styleType === "filter",
                })}
              />
            </span>
          </div>
          <span aria-hidden="true">
            <ArrowDown2
              className="text-gray-9 transition-all group-rac-hover:translate-y-1"
              size={20}
              variant="Bold"
            />
          </span>
        </Button>
      </Group>
      <FieldError className="text-xs font-semibold text-error">
        {errorMessage}
      </FieldError>
      <Popover className="max-h-[inherit] w-[--trigger-width] overflow-auto rounded-lg border border-white-3 bg-white-0 p-3 text-sm shadow-dialog">
        <ListBox className="*:rounded-lg *:p-2">{children}</ListBox>
      </Popover>
    </Select>
  );
}

const ForwardedRefSelectInput = forwardRef(SelectInput);

export { ForwardedRefSelectInput as SelectInput };
