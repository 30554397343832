import { useFormContext } from "react-hook-form";

import { FormSignature } from "@/shared/components";

import { SignaturePoints } from "../schema";

export function SignatureForm() {
  const { control } = useFormContext<SignaturePoints>();

  return <FormSignature name="points" control={control} />;
}
