import axios from "axios";

import { ModalFormWizard } from "@/shared/components/form/modal-form-wizard";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

import { SignatureCreateFormSchema, SignatureCreateSchema } from "../schema";
import { SignatureForm } from "./signature-form";
import { SignatureNotice } from "./signature-notice";

export interface SignatureCreateModalProps {
  manutencaoId: number;
}

export function SignatureCreateModal({
  manutencaoId,
}: SignatureCreateModalProps) {
  return (
    <ModalFormWizard
      formSchema={SignatureCreateFormSchema}
      submitSchema={SignatureCreateSchema}
      steps={[
        {
          heading: {
            head: "Finalizar assinatura",
          },
          element: <SignatureNotice />,
        },
        {
          heading: {
            head: "Finalizar assinatura",
          },
          element: <SignatureForm />,
        },
      ]}
      onSubmit={({ points: _points }, close) => {
        const points = _points as SVGSVGElement;
        const svgelm = points.cloneNode(true) as SVGSVGElement;
        const clientWidth = points.clientWidth;
        const clientHeight = points.clientHeight;
        svgelm.removeAttribute("style");
        svgelm.setAttribute("width", `${clientWidth}px`);
        svgelm.setAttribute("height", `${clientHeight}px`);
        svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);
        const data = new XMLSerializer().serializeToString(svgelm);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = async () => {
          canvas.width = clientWidth || 0;
          canvas.height = clientHeight || 0;
          ctx?.drawImage(img, 0, 0);

          const dataUrl = canvas.toDataURL("image/png");
          const image = document.createElement("img");
          image.src = dataUrl;

          const fetchImg = await (await fetch(dataUrl)).blob();

          const presignedUrl =
            await apiProvider.services.FilesService.getPresignedUploadUrlFilesUploadGet(
              { contentType: "png" },
            );

          await axios.put(presignedUrl.url, fetchImg, {
            headers: {
              "Content-Type": "png",
            },
          });

          await apiProvider.services.AssinaturasService.createObjAssinaturaPost(
            {
              requestBody: {
                manutencaoId,
                assinaturaUrl: presignedUrl.key,
              },
            },
          );

          await queryClient.invalidateQueries({
            queryKey: ["signatureInfinite", { manutencaoId }],
          });

          await queryClient.invalidateQueries({
            queryKey: ["maintenance", manutencaoId.toString()],
          });
          close();
        };
        img.src = `data:image/svg+xml;base64,${window.btoa(data)}`;
      }}
    />
  );
}
