import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";

import { Dialog, Modal, Spinner } from "@/shared/components";
import { useCompanyCategories } from "@/shared/hooks/api";
import { CategoriaConstrutora } from "@/shared/services";

import { CompanyCategory } from "../schemas";

interface CategoriesCompanyListModalProps
  extends Omit<ModalOverlayProps, "children"> {
  onAdd?: (category: CategoriaConstrutora) => void;
  selectedCategories: CompanyCategory[];
}

export function CategoriesCompanyListModal({
  onAdd,
  selectedCategories,
  ...props
}: CategoriesCompanyListModalProps) {
  const { data: categories, isLoading } = useCompanyCategories();

  const filteredMemorials = categories?.filter(
    (category) =>
      !selectedCategories.some((selected) => selected.nome === category.nome),
  );
  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Escolha uma categoria"
        data-cy="category-company-modal"
      >
        {({ close }) => (
          <div className="space-y-4">
            <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll pr-2">
              {filteredMemorials?.map((e) => (
                <AriaButton
                  key={e.id}
                  className="mr-4 rounded-lg border border-white-3 p-3 text-left"
                  onPress={() => {
                    onAdd?.(e);
                    close();
                  }}
                >
                  {e.nome}
                </AriaButton>
              ))}
              {isLoading && <Spinner className="mx-auto" />}
            </div>
          </div>
        )}
      </Dialog>
    </Modal>
  );
}
