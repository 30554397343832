import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Produto, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationAddSubsystemProductProps
  extends Omit<
    UseMutationOptions<Produto, Error, AddSubsystemProductload>,
    "mutationFn"
  > {}

export interface AddSubsystemProductload {
  productId: number;
  subsystemId: number;
}

export function useMutationAddSubsystemProduct(
  options?: UseMutationAddSubsystemProductProps,
) {
  return useMutation({
    mutationFn: async ({ productId, subsystemId }: AddSubsystemProductload) => {
      //TODO WAITING FOR BACKEND ROUTE TO DEEP CLONE
      const { id: _, ...product } =
        await apiProvider.services.ProdutosService.getOneObjProdutoIdGet({
          id: productId,
        });
      return await apiProvider.services.ProdutosService.createObjProdutoPost({
        requestBody: { ...product, subsistemaId: subsystemId },
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["products"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar produto no subsistema: ${e.message}`,
      }),
    ...options,
  });
}
