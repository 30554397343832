import { Cell, DialogTrigger } from "react-aria-components";

import {
  Can,
  ComboBoxDevelopmentInput,
  DetailsCell,
  FiltersAccordion,
  PaginationButtons,
  ProtocolFilter,
  ResponsibleFilter,
  SearchInput,
  ServiceStatusFilter,
  Table,
  TableRow,
  UnauthorizedModal,
} from "@/shared/components";
import { useMaintenances } from "@/shared/hooks/api";
import { MaintenanceTypeId } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { ServiceStatusCell } from "../components";

export function ListAssistances() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useMaintenances({
      text: "",
      tipoManutencaoId: MaintenanceTypeId.ASSISTANCE,
    });
  return (
    <div className="mt-0 flex flex-col pt-4">
      <div className="mb-4 flex gap-4">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <DialogTrigger>
          {/* TODO: Add create call modal */}
          <UnauthorizedModal />
        </DialogTrigger>
      </div>
      <FiltersAccordion className="mb-4">
        {/* TODO: Missing filters */}
        <ComboBoxDevelopmentInput styleType="filter" isDisabled />
        <ResponsibleFilter isDisabled />
        <ProtocolFilter isDisabled />
        <ServiceStatusFilter showLabel isDisabled />
      </FiltersAccordion>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0 shadow-header">
        <div className="overflow-y-auto">
          <Can I="get.client">
            {(allowed) => (
              <Table
                aria-label="Lista chamados de assistêcia"
                headers={[
                  "Protocolo",
                  "Solicitante",
                  "Tipo do Cliente",
                  "Empreendimento",
                  "Bloco",
                  "Responsável",
                  "Status",
                ]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "chamado de assistência",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell>{data.id}</Cell>
                    <Cell>{nullishStringMask(data.unidade?.dono?.nome)}</Cell>
                    <Cell>
                      {nullishStringMask(data.unidade?.tipoCliente?.nome)}
                    </Cell>
                    <Cell>{nullishStringMask(data.empreendimento?.nome)}</Cell>
                    <Cell>{nullishStringMask(data.unidade?.bloco.nome)}</Cell>
                    <Cell>
                      {nullishStringMask(data.colaboradorResponsavel?.nome)}
                    </Cell>
                    <ServiceStatusCell
                      status={data.statusManutencao?.nome ?? "Desconhecido"}
                    />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
