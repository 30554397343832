import { TabPanel } from "react-aria-components";
import { Outlet, useLocation, useParams } from "react-router";

import {
  Breadcrumbs,
  PageTitle,
  SubtitleHandler,
  Tabs,
} from "@/shared/components";
import {
  useAuthorizedTabs,
  useRequiredParams,
  useTabBaseUrl,
} from "@/shared/hooks";
import { useProduct, useSubsystem, useSystem } from "@/shared/hooks/api";

export function ProductDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("product");

  const { productId } = useRequiredParams();
  const { subsystemId, systemId } = useParams();

  const subsystemQuery = useSubsystem(subsystemId);
  const systemQuery = useSystem(systemId);
  const productQuery = useProduct(productId);

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do produto",
      action: "get.product",
    },
    {
      id: `${baseUrl}garantia/`,
      href: `${baseUrl}garantia/`,
      label: "Garantias",
      action: "list.warranty",
    },
    {
      id: `${baseUrl}preventivo/`,
      href: `${baseUrl}preventivo/`,
      label: "Preventivos",
      action: "list.preventive",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <div className="flex flex-col">
          <PageTitle primary>Detalhes do Produto</PageTitle>
          <div className="flex flex-col gap-1">
            {systemId && (
              <SubtitleHandler label="Sistema" query={systemQuery} />
            )}
            {subsystemId && (
              <SubtitleHandler label="Subsistema" query={subsystemQuery} />
            )}
            <SubtitleHandler label="Produto" query={productQuery} />
          </div>
        </div>
      </div>
      <Tabs aria-label="Modulos do produto" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
