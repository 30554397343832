import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { BlockHouseCreateForm } from "../components";

export function DevelopmentHouseBulkCreate() {
  const { developmentId } = useRequiredParams();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar blocos e unidades de casa</PageTitle>
      </div>
      <BlockHouseCreateForm
        redirectTo="../"
        useFormProps={{
          defaultValues: {
            empreendimentoId: +developmentId,
            qtdBlocos: 1,
            qtdCasaBloco: 1,
          },
        }}
      />
    </AnimatedContainer>
  );
}
