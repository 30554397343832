import { VariantProps, tv } from "tailwind-variants";

export const trigger = tv({
  base: "[&[data-state=open]>div>svg]:rotate-180, transition-all",
  variants: {
    styleType: {
      default:
        "flex flex-1 items-center gap-3 py-4 text-base font-bold text-black-2 outline-none transition-all [&[data-state=open]>div>svg]:rotate-180",
      filter:
        "flex flex-1 items-center gap-3 py-4 text-sm font-medium text-gray-8 outline-none",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export const content = tv({
  base: "pb-4 pt-0",
  variants: {
    styleType: {
      default: "border-t border-gray-1 py-4",
      filter: "grid grid-cols-2 gap-2",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export const root = tv({
  base: "rounded-lg bg-white-0 shadow-header",
  variants: {
    styleType: {
      default: "px-6",
      filter: "px-4",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export type AccordionStyleProps = VariantProps<typeof trigger>;
