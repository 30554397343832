import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormNumberInput,
  FormProps,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { z } from "@/shared/utils";

interface DevelopmentStepCreateFormProps
  extends Omit<
    FormProps<typeof DevelopmentStepCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

const DevelopmentStepCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  percentagem: z.number().default(0),
  empreendimentoId: z.number(),
});

export function DevelopmentStepCreateForm({
  redirectTo,
  ...formProps
}: DevelopmentStepCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={DevelopmentStepCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={redirectTo} />
            <div className="space-y-4">
              <FormTextInput control={control} label="Nome" name="nome" />
              <FormNumberInput
                control={control}
                label="Porcentagem"
                name="percentagem"
                maxValue={100}
                minValue={0}
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
