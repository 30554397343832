export function SignatureNotice() {
  return (
    <div className="flex flex-col gap-2 px-10 py-12">
      <p className="text-center text-2xl font-semibold text-success">
        Solicitar assinatura
      </p>
      <p className="text-base text-gray-8">
        Para finalizar a vistoria solicite a assinatura do responsável no local
      </p>
    </div>
  );
}
