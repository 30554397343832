import {
  Separator as AriaSeparator,
  SeparatorProps,
} from "react-aria-components";

import { cn } from "../utils";

export function Separator({ className, ...props }: SeparatorProps) {
  return (
    <AriaSeparator
      {...props}
      className={
        props.orientation !== "vertical"
          ? cn("my-4 w-full border-white-3", className)
          : cn("mx-4 self-stretch border-r border-white-3", className)
      }
    />
  );
}
