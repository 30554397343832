import { useParams } from "react-router";

import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { ProductFormWizard } from "../components/product-form-wizard";
import { ProductCreateFormSchema, ProductCreateSchema } from "../schemas";

export function ProductCreate() {
  const { subsystemId } = useParams();

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar produto</PageTitle>
        <ProductFormWizard
          formSchema={ProductCreateFormSchema}
          submitSchema={ProductCreateSchema}
          useFormProps={{
            defaultValues: {
              subsistemaId: subsystemId ? +subsystemId : undefined,
            },
          }}
        />
      </div>
    </AnimatedContainer>
  );
}
