import { FormWizardProps } from "@/shared/components/form/form-wizard";
import { ModalFormWizard } from "@/shared/components/form/modal-form-wizard";
import { useMutationAppointmentCreate } from "@/shared/hooks/api";

import {
  AppointmentCreateFormSchema,
  AppointmentCreateSchema,
} from "../schemas";
import { AppointmentDateForm } from "./appointment-date-form";
import { AppointmentTypeForm } from "./appointment-type-form";

export interface AppointmentCreateModalProps
  extends Pick<
    FormWizardProps<
      typeof AppointmentCreateFormSchema,
      typeof AppointmentCreateSchema
    >,
    "useFormProps"
  > {
  manutencaoId: number;
}

export function AppointmentCreateModal({
  manutencaoId,
  useFormProps,
}: AppointmentCreateModalProps) {
  const appointmentCreateMutation = useMutationAppointmentCreate();
  return (
    <ModalFormWizard
      formSchema={AppointmentCreateFormSchema}
      submitSchema={AppointmentCreateSchema}
      useFormProps={{
        ...useFormProps,
        defaultValues: { ...useFormProps?.defaultValues, manutencaoId },
      }}
      steps={[
        {
          heading: {
            head: "Defina o tipo de agendamento",
          },
          element: <AppointmentTypeForm />,
        },
        {
          heading: {
            head: "Agendar vistoria",
          },
          element: <AppointmentDateForm />,
        },
      ]}
      onSubmit={async ({ appointmentType, data, ...values }, close) => {
        await appointmentCreateMutation.mutateAsync({
          ...values,
          seRemoto: appointmentType === "remote",
          data: data.toISOString(),
        });
        close();
      }}
    />
  );
}
