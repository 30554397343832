import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

import {
  CompanyAddressSchema,
  CompanyAddressStepSchema,
  CompanyCostTermsSchema,
  CompanyCostTermsStepSchema,
  CompanyInfoSchema,
} from "../create/schemas";

export const CompanyInfoWithoutResponsibleSchema = z.object({
  ...CompanyInfoSchema.omit({ responsavel: true }).shape,
});

export const CompanyEditSchema = z.object({
  construtoraId: z.number().int(),
  ...CompanyInfoWithoutResponsibleSchema.shape,
  ...CompanyAddressSchema.shape,
  ...CompanyCostTermsSchema.shape,
});

const CompanyInfoWithoutResponsibleStepSchema = generateStepSchema(
  CompanyInfoWithoutResponsibleSchema,
  0,
);

export const CompanyEditFormSchema = generateMultiStepSchema([
  CompanyInfoWithoutResponsibleStepSchema,
  CompanyAddressStepSchema,
  CompanyCostTermsStepSchema,
]);

export type CompanyInfoWithoutResponsible = z.infer<
  typeof CompanyInfoWithoutResponsibleSchema
>;
export type CompanyEdit = z.infer<typeof CompanyEditSchema>;

export type CompanyEditForm = z.infer<typeof CompanyEditFormSchema>;
