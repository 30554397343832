import { useParams } from "react-router";

import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SubtitleHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useClientType,
  useMutationPreventiveDelete,
  useMutationPreventiveUpdate,
  usePreventive,
  useProduct,
  useSubsystem,
  useSystem,
  useTimeUnit,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { PreventiveDetailError, PreventiveDetailLoading } from "../components";

export function PreventiveDetail() {
  const { preventiveId } = useRequiredParams();
  const { systemId, subsystemId, productId } = useParams();
  const preventiveQuery = usePreventive(preventiveId);
  const { findClientById } = useClientType();
  const { findTimeQueryById } = useTimeUnit();

  const subsystemQuery = useSubsystem(subsystemId);
  const systemQuery = useSystem(systemId);
  const productQuery = useProduct(productId);

  const mutationPreventiveUpdate = useMutationPreventiveUpdate();
  const mutationPreventiveDelete = useMutationPreventiveDelete();
  return (
    <AnimatedContainer className="m-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <div className="flex flex-col">
          <PageTitle primary>Detalhes do preventivo</PageTitle>
          <div className="flex flex-col gap-1">
            {systemId && (
              <SubtitleHandler label="Sistema" query={systemQuery} />
            )}
            {subsystemId && (
              <SubtitleHandler label="Subsistema" query={subsystemQuery} />
            )}
            {productId && (
              <SubtitleHandler label="Produto" query={productQuery} />
            )}
          </div>
        </div>
      </div>
      <QueryHandler
        {...preventiveQuery}
        renderLoading={PreventiveDetailLoading}
        renderError={(error) => <PreventiveDetailError error={error} />}
      >
        {(data) => (
          <Card className="shadow-header">
            <div className="flex justify-between gap-2">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.nome}</PageTitle>
                <Can I="update.preventive">
                  <EditLink />
                </Can>
                <Can I="delete.preventive">
                  <DeleteResourceButton
                    resource="preventivo"
                    subject={data?.nome ?? ""}
                    action={() =>
                      mutationPreventiveDelete.mutateAsync({
                        id: +preventiveId,
                      })
                    }
                    showSpinner={mutationPreventiveDelete.isPending}
                  />
                </Can>
              </div>
              <Can I="update.preventive">
                <SwitchStatusButton
                  isDisabled={mutationPreventiveUpdate.isPending}
                  isSelected={data?.flagAtivo}
                  onChange={(status) =>
                    mutationPreventiveUpdate.mutateAsync({
                      id: +preventiveId,
                      requestBody: { flagAtivo: status },
                    })
                  }
                />
              </Can>
            </div>
            <Separator />
            {data?.descricao && (
              <div
                className="tiptap text-black-0"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data.descricao),
                }}
              />
            )}
            <p className="mb-3 text-sm text-black-2">Dados gerais</p>
            <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
              <div>
                <p>Frequência</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(data?.frequencia?.toString())}
                </p>
              </div>

              <div>
                <p>Unidade de tempo</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(findTimeQueryById(data))}
                </p>
              </div>

              <div>
                <p>Tipo do cliente</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(findClientById(data?.tipoClienteId))}
                </p>
              </div>
            </div>
          </Card>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
