import { Control, FieldValues, Path, useController } from "react-hook-form";

import { FormAvailabilityDayInput } from "./form-availability-day-input";
import { TimeInterval } from "./time-interval";

export interface FormAvailabilityInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  isDisabled?: boolean;
}

export function FormWeekAvailabilityInput<T extends FieldValues>({
  control,
  name,
  isDisabled,
}: FormAvailabilityInputProps<T>) {
  const { field, fieldState } = useController({
    name,
    control,
    disabled: isDisabled,
  });

  return (
    <div className="flex flex-col gap-2">
      <p className="text-sm font-semibold text-gray-12">Horário</p>
      <p className="text-xs text-gray-5">
        Defina quais horários mostrar no agendamento online
      </p>
      <div className="flex flex-col gap-4 px-2">
        {Array.from({ length: 7 }).map((_, i) => (
          <FormAvailabilityDayInput
            key={i}
            day={i}
            active={!!field.value?.[i]}
            setActive={(active) => {
              const newDaysIntervals = [...field.value];
              newDaysIntervals[i] = active ? [new TimeInterval()] : null;
              field.onChange(newDaysIntervals);
            }}
            onIntervalsChange={(interval) => {
              const newDaysIntervals = [...field.value];
              newDaysIntervals[i] = interval;
              field.onChange(newDaysIntervals);
            }}
            onCopy={(days) => {
              const dayIntervalsToCopy = [...(field.value?.[i] ?? [])];
              if (!dayIntervalsToCopy) return;

              const newDaysIntervals = [...field.value];
              for (const day of days) {
                newDaysIntervals[day] = dayIntervalsToCopy;
              }
              field.onChange(newDaysIntervals);
            }}
            value={field.value[i]}
          />
        ))}
      </div>
      {fieldState.invalid && fieldState.error && (
        <p className="text-xs font-semibold text-error">
          {fieldState.error?.message}
        </p>
      )}
    </div>
  );
}
