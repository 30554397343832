import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormFileInput,
  FormRootError,
  Modal,
} from "@/shared/components";
import { useMutationMediaFilesCreate } from "@/shared/hooks/api";
import { megabyte } from "@/shared/utils";

import { MediaFilesCreate, MediaFilesCreateSchema } from "../schemas";

export interface MediaFilesCreateModalProps
  extends Omit<ModalOverlayProps, "children"> {
  manutencaoId: number;
}

export function MediaFilesCreateModal({
  manutencaoId,
  ...props
}: MediaFilesCreateModalProps) {
  const { formState, handleSubmit, control, reset } = useForm<MediaFilesCreate>(
    {
      resolver: zodResolver(MediaFilesCreateSchema),
      values: {
        manutencaoId,
        arquivo: undefined,
        seImagem: false,
      },
    },
  );

  const mediaFilesCreateMutation = useMutationMediaFilesCreate();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const onSubmit: SubmitHandler<MediaFilesCreate> = ({
    manutencaoId,
    ...values
  }) => {
    const isImage = !!values.arquivo?.[0]?.key?.match(/\.(jpeg|jpg|gif|png)$/);

    const basePayload = {
      ...values,
      manutencaoId,
      arquivo: values.arquivo ? values.arquivo[0].key : "",
      seImagem: isImage,
    };

    mediaFilesCreateMutation.mutateAsync(basePayload);
    props.onOpenChange?.(false);
  };

  return (
    <Modal {...props}>
      <Dialog
        role="alertdialog"
        title="Upload de arquivo"
        onClose={reset}
        renderButtons={({ close }) => (
          <div className="flex justify-between">
            <FormRootError />
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              onPress={() => handleSubmit(onSubmit)()}
              showSpinner={mediaFilesCreateMutation.isPending}
            >
              Adicionar
            </Button>
          </div>
        )}
      >
        <form className="flex min-w-96 flex-col">
          <FormFileInput
            control={control}
            label="Arquivo"
            name="arquivo"
            maxFileSize={50 * megabyte}
            className="w-full"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
