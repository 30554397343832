import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useDevelopment } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { cepMask, cnpjMask } from "@/shared/utils/masks";

import { DevelopmentFormWizard } from "../../create/components/development-form-wizard";
import {
  DevelopmentCreateFormSchema,
  DevelopmentCreateSchema,
} from "../../create/schemas";

export function EditDevelopment() {
  const { developmentId } = useRequiredParams();

  const developmentQuery = useDevelopment(developmentId);

  const getDefaultValues = (data: typeof developmentQuery.data) => {
    if (!data) {
      return undefined;
    }
    const { cnpj, cep, contrato, artCrea, foto, capa, dataEntrega, ...values } =
      data;

    const module = ["manual"];
    if (data.assistenciaTecnica) {
      module.push("technical-support");
    }
    if (data.gestaoDeManutencaoPreventiva) {
      module.push("preventive-management");
    }

    return {
      defaultValues: {
        ...values,
        cep: cep ? cepMask(cep) : undefined,
        cnpj: cnpj ? cnpjMask(cnpj) : undefined,
        module,
        contrato: contrato
          ? [
              {
                fileName: contrato,
                key: contrato,
              },
            ]
          : undefined,
        artCrea: artCrea
          ? [
              {
                fileName: artCrea,
                key: artCrea,
              },
            ]
          : undefined,
        foto: foto
          ? [
              {
                fileName: foto,
                key: foto,
              },
            ]
          : undefined,
        capa: capa
          ? [
              {
                fileName: capa,
                key: capa,
              },
            ]
          : undefined,
        dataEntrega: dataEntrega ? new Date(dataEntrega) : undefined,
      },
    };
  };

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle>Editar empreendimento</PageTitle>
        <QueryHandler
          {...developmentQuery}
          renderLoading={() => <Spinner />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Usuário não encontrado..."
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <DevelopmentFormWizard
              onSuccessRedirect="../"
              formSchema={DevelopmentCreateFormSchema}
              submitSchema={DevelopmentCreateSchema}
              useFormProps={getDefaultValues(data)}
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
