import { Folder } from "iconsax-react";

import { DownloadFile } from "@/shared/components";
import { useMutationFileDownload } from "@/shared/hooks/api";
import { nullishStringMask, numberToCurrencyMask } from "@/shared/utils/masks";

interface CostCardProps {
  type?: string | null;
  value?: number | null;
  invoiceUrl?: string | null;
}

export default function CostCard({ type, invoiceUrl, value }: CostCardProps) {
  const fileMutation = useMutationFileDownload();
  return (
    <div className="grid w-full grid-cols-3 gap-4 rounded-lg bg-primary-light-1 px-16 py-6">
      <div className="flex items-center gap-1 text-sm font-normal text-black-1">
        Tipo:<span className="font-bold">{nullishStringMask(type)}</span>
      </div>
      <div className="flex items-center gap-1 text-sm font-normal text-black-1">
        Valor: <span className="font-bold">{numberToCurrencyMask(value)}</span>
      </div>
      {invoiceUrl && (
        <DownloadFile
          className="gap-2.5 bg-transparent p-0 text-sm text-info"
          onPress={() => {
            invoiceUrl && fileMutation.mutateAsync(invoiceUrl);
          }}
          isPending={fileMutation.isPending}
        >
          <Folder variant="Bold" />
          Ver nota fiscal
        </DownloadFile>
      )}
    </div>
  );
}
