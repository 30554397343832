import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const SystemUnitSchema = z.object({
  id: z.number(),
  nome: z.string().min(1, "Obrigatório").trim(),
});

export type SystemUnit = z.infer<typeof SystemUnitSchema>;

export const SystemGeneralDataSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  descricao: z.string().trim().nullish(),
  cuidados: z.string().trim().nullish(),
  perdas: z.string().trim().nullish(),
  manualId: z.number().int().nullish(),
  tipoId: z.number().int().nullish(),
});

export const SystemAssociateSchema = z.object({
  tipoUnidade: z.enum(["geral", "personalizada"]),
  unidades: z.array(SystemUnitSchema).default([]),
});

export const SystemCreateSchema = z.object({
  ...SystemGeneralDataSchema.shape,
  ...SystemAssociateSchema.shape,
});

export const SystemGeneralDataStepSchema = generateStepSchema(
  SystemGeneralDataSchema,
  0,
);
const SystemAssociateDataStepSchema = generateStepSchema(
  SystemAssociateSchema,
  1,
);

export const SystemCreateFormSchema = generateMultiStepSchema([
  SystemGeneralDataStepSchema,
  SystemAssociateDataStepSchema,
]);

export type SystemGeneralData = z.infer<typeof SystemGeneralDataSchema>;
export type SystemAssociateData = z.infer<typeof SystemAssociateSchema>;
export type SystemCreate = z.infer<typeof SystemCreateSchema>;
