import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useUnit } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { UnitCreateForm } from "../../create/components";

export function UnitEdit() {
  const { developmentId, unitId } = useRequiredParams();
  const unitQuery = useUnit(unitId);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar unidade</PageTitle>
      </div>
      <QueryHandler
        {...unitQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Unidade não encontrada..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <UnitCreateForm
            redirectTo="../"
            useFormProps={{
              defaultValues: data?.donoId
                ? {
                    clientCreationType: "registered-client",
                    unidade: {
                      ...data,
                      donoId: data.donoId,
                      dataEntrega: data?.dataEntrega
                        ? new Date(data?.dataEntrega)
                        : undefined,
                    },
                  }
                : {
                    clientCreationType: "no-client",
                    unidade: {
                      ...data,
                      dataEntrega: data?.dataEntrega
                        ? new Date(data?.dataEntrega)
                        : undefined,
                    },
                  },
            }}
            empreendimentoId={+developmentId}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
