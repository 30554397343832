import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { DevelopmentCreate } from "../../create/schemas";

export async function editDevelopmentAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<DevelopmentCreate & { redirectTo: string }>(
    request,
    async ({
      redirectTo,
      module,
      foto,
      capa,
      contrato,
      artCrea,
      ...payload
    }) => {
      await apiProvider.services.EmpreendimentosService.updateObjEmpreendimentosIdPut(
        {
          id: +params.developmentId!,
          requestBody: {
            manualInterativo: module.includes("manual"),
            assistenciaTecnica: module.includes("technical-support"),
            gestaoDeManutencaoPreventiva: module.includes(
              "preventive-management",
            ),
            foto: foto?.[0].key,
            capa: capa?.[0].key,
            contrato: contrato?.[0].key,
            artCrea: artCrea?.[0].key,
            ...payload,
          },
        },
      );

      toastQueue.add(
        { type: "success", message: "Empreendimento atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
