import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import {
  Calendar,
  CalendarGrid,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarProps,
  Heading,
} from "react-aria-components";

import { Button } from "@/shared/components";
import { Interval } from "@/shared/components/form/availability/schema";
import { Manutencao } from "@/shared/services";

import { WeekCalendarGrid } from "./week-calendar";

export interface AvailabilityCalendarProps<T extends CalendarDate>
  extends Omit<CalendarProps<T>, "visibleDuration"> {
  datesIntervals: NonNullable<Manutencao["disponibilidade"]>;
  onIntervalSelected?: (date: CalendarDate, interval: Interval) => void;
}

export function AvailabilityCalendar<T extends CalendarDate>({
  datesIntervals,
  onIntervalSelected,
  ...props
}: AvailabilityCalendarProps<T>) {
  return (
    <Calendar
      {...props}
      visibleDuration={{ weeks: 1 }}
      defaultValue={today(getLocalTimeZone()) as T}
    >
      <header className="mb-3 flex items-center justify-between rounded-t-lg bg-primary-light-1 p-3">
        <Button slot="previous" styleType="secondary" className="p-0">
          <ArrowLeft2 size="1.5rem" />
        </Button>
        <Heading className="font-semibold text-primary"></Heading>
        <Button slot="next" styleType="secondary" className="p-0">
          <ArrowRight2 size="1.5rem" />
        </Button>
      </header>
      <div className="max-h-96 overflow-y-auto">
        <CalendarGrid className="w-full" weekdayStyle="short">
          <CalendarGridHeader className="sticky top-0 bg-white-0">
            {(day) => (
              <CalendarHeaderCell className="text-base text-gray-10">
                {day.toLocaleUpperCase()}
              </CalendarHeaderCell>
            )}
          </CalendarGridHeader>
          <WeekCalendarGrid
            datesIntervals={datesIntervals}
            onIntervalSelected={onIntervalSelected}
          />
        </CalendarGrid>
      </div>
    </Calendar>
  );
}
