import { useCallback, useMemo } from "react";
import { Key } from "react-aria";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { ComboBoxInput, ComboBoxInputProps } from "..";

export interface FormComboBoxInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends ComboBoxInputProps<T> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
}

export function FormComboBoxInput<
  T extends object,
  TFieldValues extends FieldValues,
>({
  children,
  control,
  name,
  onSelectionChange,
  onInputChange,
  ...props
}: FormComboBoxInputProps<T, TFieldValues>) {
  const {
    field: { onChange, value, disabled, ref, ...fieldProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: props.isDisabled,
  });

  const onSelectionChangeHandler = useCallback(
    (key: Key | null) => {
      if (!props.allowsCustomValue) {
        onChange(key);
      }
      onSelectionChange?.(key);
    },
    [onChange, onSelectionChange, props.allowsCustomValue],
  );

  const onInputChangeHandler = useCallback(
    (value: string) => {
      if (props.allowsCustomValue) {
        onChange(value);
      }
      onInputChange?.(value);
    },
    [onChange, onInputChange, props.allowsCustomValue],
  );

  const selectedKey = useMemo(
    () => (props.allowsCustomValue ? undefined : (value ?? null)),
    [props.allowsCustomValue, value],
  );
  const inputValue = useMemo(
    () => (props.allowsCustomValue ? (value ?? "") : undefined),
    [props.allowsCustomValue, value],
  );

  return (
    <ComboBoxInput
      {...props}
      {...fieldProps}
      isDisabled={disabled || props.isReadOnly}
      isInvalid={invalid}
      inputRef={ref}
      errorMessage={error?.message}
      validationBehavior="aria"
      selectedKey={selectedKey}
      inputValue={inputValue}
      onSelectionChange={onSelectionChangeHandler}
      onInputChange={onInputChangeHandler}
    >
      {children}
    </ComboBoxInput>
  );
}
