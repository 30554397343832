import { Label } from "react-aria-components";
import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { UseInfiniteMemorialsFilters } from "@/shared/hooks/api";
import { cn, z } from "@/shared/utils";

import { MemorialListModal } from "../../modules/system/modules/detail/modules/subsystem/modules/create/components";
import { MemorialCreateSchema } from "../../modules/system/modules/detail/modules/subsystem/modules/create/schemas";

const MemorialContainerSchema = z.object({
  memoriais: z.array(MemorialCreateSchema).default([]),
});

type MemorialContainer = z.infer<typeof MemorialContainerSchema>;

interface FormWarrantyListProps<T extends MemorialContainer> {
  className?: string;
  control: Control<T>;
  filters?: UseInfiniteMemorialsFilters;
  label?: string;
}

export function FormMemorialList<T extends MemorialContainer>({
  control,
  label,
  className,
  ...props
}: FormWarrantyListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<MemorialContainer>;

  return (
    <div className={cn("flex flex-col gap-2", className)}>
      {label && (
        <Label className="text-sm font-semibold text-gray-12">{label}</Label>
      )}
      <FormListInput
        {...props}
        control={_control}
        name="memoriais"
        onAddPress={turnOn}
        resourceOptions={{
          word: "ambiente",
          pluralRadical: "s",
          isMasculine: true,
        }}
      >
        {(values, update) => (
          <>
            {values.length !== 0 ? (
              values.map((e, i) => (
                <ListInputElement
                  key={e.nome + i}
                  onDelete={() =>
                    update(values.filter((_, index) => index !== i))
                  }
                >
                  {e.nome}
                </ListInputElement>
              ))
            ) : (
              <p className="p-4 text-sm italic text-gray-8">Vazio</p>
            )}

            <MemorialListModal
              filters={props.filters}
              isOpen={isOn}
              onOpenChange={setOpen}
              selectedMemorials={values}
              onAdd={(value) => {
                update([...values, value]);
                return turnOff();
              }}
            />
          </>
        )}
      </FormListInput>
    </div>
  );
}
