import { FormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Breadcrumbs,
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormRootError,
  FormSelectSystemUserInput,
  FormTextInput,
  PageTitle,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { CreateUserSchema } from "../schemas";

export function CreateUser() {
  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <div className="flex-1 space-y-4 overflow-y-scroll p-8 animate-in fade-in slide-in-from-left-2">
        <Breadcrumbs />
        <PageTitle primary>Usuário</PageTitle>
        <Card className="shadow-header">
          <FieldSetTitle>Dados gerais</FieldSetTitle>
          <Form
            schema={CreateUserSchema}
            autoComplete="off"
            validationErrors={formErrors}
          >
            {({ control }) => (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <input type="hidden" name="redirectTo" value="../" />
                  <FormSelectSystemUserInput control={control} name="papelId" />
                  <FormTextInput control={control} label="Nome" name="nome" />
                  <FormTextInput
                    control={control}
                    mask={cpfMask}
                    label="CPF"
                    name="cpf"
                  />
                  <FormTextInput
                    control={control}
                    label="E-mail"
                    name="email"
                  />
                  <FormTextInput
                    control={control}
                    mask={phoneMask}
                    label="Telefone"
                    name="telefone"
                  />
                  <FormTextInput
                    control={control}
                    label="Senha"
                    name="senha"
                    isSecret
                  />
                </div>
                <Separator />
                <div className="flex justify-between">
                  <FormRootError />
                  <Button
                    className="px-10 py-3"
                    type="submit"
                    showSpinner={isSubmitting}
                  >
                    Salvar
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Card>
      </div>
    </div>
  );
}
