import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const AppointmentTypeSchema = z.object({
  appointmentType: z.enum(["in-person", "remote"]),
});

export const AppointmentDateSchema = z.object({
  observacoes: z.string().trim().nullish(),
  data: z.date().min(new Date()),
  responsavelId: z.number().int().nullish(),
});

export const AppointmentCreateSchema = z.object({
  ...AppointmentTypeSchema.shape,
  ...AppointmentDateSchema.shape,
  manutencaoId: z.number().int(),
});

export const AppointmentTypeStepSchema = generateStepSchema(
  AppointmentTypeSchema,
  0,
);
const AppointmentDateStepSchema = generateStepSchema(AppointmentDateSchema, 1);

export const AppointmentCreateFormSchema = generateMultiStepSchema([
  AppointmentTypeStepSchema,
  AppointmentDateStepSchema,
]).and(
  z.object({
    manutencaoId: z.number().int(),
  }),
);
export type AppointmentType = z.infer<typeof AppointmentTypeSchema>;
export type AppointmentDate = z.infer<typeof AppointmentDateSchema>;
export type AppointmentCreate = z.infer<typeof AppointmentCreateSchema>;
