import { FieldValues } from "react-hook-form";

import {
  FormRadioGroupInput,
  FormRadioGroupInputProps,
  Radio,
} from "@/shared/components";

export interface FormTargetAssociateSystemInputProps<T extends FieldValues>
  extends Omit<FormRadioGroupInputProps<T>, "label" | "children"> {}

export function FormTargetAssociateSystemInput<T extends FieldValues>({
  ...props
}: FormTargetAssociateSystemInputProps<T>) {
  return (
    <FormRadioGroupInput {...props} label="Unidades">
      <div className="flex gap-6">
        <Radio value="geral">Geral</Radio>
        <Radio value="personalizada">Personalizada</Radio>
      </div>
    </FormRadioGroupInput>
  );
}
