import { Card, Skeleton } from "@/shared/components";

export function WarrantyDetailLoading() {
  return (
    <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
      <div className="flex justify-between">
        <div className="flex flex-col justify-center">
          <Skeleton className="my-2 h-4 w-24" />
        </div>
      </div>
    </Card>
  );
}
