import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useCompanyWorker, useMutationWorkerUpdate } from "@/shared/hooks/api";
import { cpfMask, nullishStringMask, phoneMask } from "@/shared/utils/masks";

import { WorkerDetailError, WorkerDetailLoading } from "../components";

export function WorkerDetail() {
  const { companyWorkerId } = useRequiredParams();
  const companyWorkerQuery = useCompanyWorker(companyWorkerId);
  const mutationWorkerUpdate = useMutationWorkerUpdate();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle>Detalhes do colaborador</PageTitle>
      </div>
      <div className="space-y-4">
        <QueryHandler
          {...companyWorkerQuery}
          renderLoading={WorkerDetailLoading}
          renderError={(error) => <WorkerDetailError error={error} />}
        >
          {(data) => (
            <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
              <div className="flex flex-col">
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <PageTitle primary className="break-all">
                        {data?.nome}
                      </PageTitle>
                      <Can I="update.worker">
                        <EditLink />
                      </Can>
                    </div>
                  </div>
                  <Can I="update.worker">
                    <SwitchStatusButton
                      isDisabled={mutationWorkerUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationWorkerUpdate.mutateAsync({
                          id: +companyWorkerId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                </div>
                <div className="flex gap-6 text-xs text-primary">
                  <p>
                    Cargo:{" "}
                    <span className="font-semibold">{data?.papel?.nome}</span>
                  </p>
                  <p>
                    CPF:{" "}
                    <span className="font-semibold">
                      {nullishStringMask(cpfMask(data?.cpf ?? undefined))}
                    </span>
                  </p>
                </div>
              </div>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Contato</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                <div>
                  <p>Email</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.email ?? undefined)}
                  </p>
                </div>
                <div>
                  <p>Telefone</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(phoneMask(data?.telefone ?? undefined))}
                  </p>
                </div>
              </div>
            </Card>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
