import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { UsuarioProfile, apiProvider } from "@/shared/services";

export interface ProfileQueryOptions
  extends Omit<UseQueryOptions<UsuarioProfile>, "queryKey" | "queryFn"> {}

export function useProfile(options?: ProfileQueryOptions) {
  return useQuery({
    queryKey: ["profile"],
    queryFn: apiProvider.services.UsuariosService.getProfileUsuariosProfileGet,
    staleTime: 60000,
    ...options,
  });
}
