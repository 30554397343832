import { Handle, RouteObject } from "react-router";

import { createCompanyAction } from "./actions";
import {
  CompanyCreate,
  CompanyEdit,
  ListConstructionCompanies,
} from "./containers";
import {
  CompanyDetailData,
  ListDevelopments,
} from "./modules/detail/components";
import { CompanyLayout } from "./modules/detail/layout";
import { developmentRouter } from "./modules/developments/router";
import { editCompanyAction } from "./modules/edit/actions";
import { editWorkerAction } from "./modules/workers/actions";
import { WorkerDetail, WorkerEdit } from "./modules/workers/containers";

export const companyRouter: RouteObject = {
  path: "construtoras",
  handle: {
    crumb: "Lista de construtoras",
    action: "list.company",
  } as Handle,
  children: [
    {
      index: true,
      element: <ListConstructionCompanies />,
    },
    {
      path: "cadastrar",
      action: createCompanyAction,
      element: <CompanyCreate />,
      handle: {
        crumb: "Cadastrar construtora",
        action: "create.company",
      } as Handle,
    },
    {
      path: "detalhes/:companyId",
      handle: {
        crumb: "Detalhes da construtora",
        tabBaseUrl: "company",
        action: "get.company",
      } as Handle,
      children: [
        {
          element: <CompanyLayout />,
          children: [
            {
              index: true,
              element: <CompanyDetailData />,
            },
            {
              path: "empreendimentos",
              handle: {
                crumb: "Lista de empreendimentos",
                action: "list.development",
              } as Handle,
              element: <ListDevelopments />,
            },
          ],
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar construtora",
            action: "update.company",
          } as Handle,
          action: editCompanyAction,
          element: <CompanyEdit />,
        },
        {
          path: "colaborador/:companyWorkerId",
          handle: {
            crumb: "Detalhes do colaborador",
            action: "get.worker",
          } as Handle,
          children: [
            {
              index: true,
              element: <WorkerDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar colaborador",
                action: "update.worker",
              } as Handle,
              action: editWorkerAction,
              element: <WorkerEdit />,
            },
          ],
        },
        developmentRouter,
      ],
    },
  ],
};
