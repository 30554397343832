import { useParams } from "react-router";

import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useManual } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { SystemFormWizard } from "../components";
import { SystemCreateFormSchema, SystemCreateSchema } from "../schemas";

export function SystemCreate() {
  const { manualId } = useParams();
  const manualQuery = useManual(manualId);

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar sistema</PageTitle>
        <QueryHandler
          {...manualQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Unidades não encontrada..."
                  : undefined
              }
            />
          )}
        >
          {(manual) => (
            <SystemFormWizard
              formSchema={SystemCreateFormSchema}
              submitSchema={SystemCreateSchema}
              useFormProps={{
                defaultValues: {
                  tipoUnidade: "geral",
                  manualId: manualId ? +manualId : undefined,
                  tipoId: manual?.tipoClienteId,
                },
              }}
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
