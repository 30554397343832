import { z } from "@/shared/utils";

import { MemorialCreateSchema } from "../create/schemas";

export const SubsystemEditSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  cuidados: z.string().trim().nullish(),
  perdas: z.string().trim().nullish(),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  sistemaId: z.number().int(),
  memoriais: z.array(MemorialCreateSchema).default([]),
});

export type SubsystemEdit = z.infer<typeof SubsystemEditSchema>;
