import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Custo, CustoCreate, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationCostConfig
  extends Omit<UseMutationOptions<Custo, Error, CustoCreate>, "mutationFn"> {}

export function useMutationCostCreate(options?: UseMutationCostConfig) {
  return useMutation({
    mutationFn: (requestBody: CustoCreate) => {
      return apiProvider.services.CustosService.createObjCustoPost({
        requestBody,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["costsInfinite"],
      });
      toastQueue.add(
        {
          type: "success",
          message: "Sucesso ao adicionar custo",
        },
        { timeout: 2000 },
      );
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar custo: ${e.message}`,
      }),
    ...options,
  });
}
