import { Card, ResourceProfileImage } from "@/shared/components";
import { Contato } from "@/shared/services";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

export interface ContactsDataProps {
  type: "Fornecedor" | "Projetista";
  contact?: Contato | null;
}

export function ContactsDataCard({ type, contact }: ContactsDataProps) {
  return (
    <Card className="flex flex-col gap-2">
      <p className="text-sm font-bold text-typography-1">{type}:</p>
      <div className="flex items-center gap-4">
        <ResourceProfileImage file={contact?.logo} size="extraSmall" />
        <span className="text-typography-2">
          <span className="font-bold">{nullishStringMask(contact?.nome)}</span>{" "}
          - {nullishStringMask(contact?.email)} -{" "}
          {nullishStringMask(phoneMask(contact?.telefone ?? ""))}
        </span>
      </div>
    </Card>
  );
}
