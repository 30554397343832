import { Time } from "@internationalized/date";
import { AddCircle, CloseCircle, Copy } from "iconsax-react";
import { useCallback } from "react";
import {
  Button as AriaButton,
  DialogTrigger,
  TimeValue,
} from "react-aria-components";

import { dayAsString } from "@/shared/utils";

import { Checkbox, TimeField } from "../../inputs";
import { CopyToWeekDaysPopover } from "./copy-to-week-days-popover";
import { TimeInterval } from "./time-interval";

export interface FormAvailabilityDayInputProps {
  day: number;
  onIntervalsChange?: (intervals: TimeInterval[] | null) => void;
  onCopy?: (days: number[]) => void;
  value?: TimeInterval[];
  active?: boolean;
  setActive?: (active: boolean) => void;
}

export function FormAvailabilityDayInput({
  day,
  onIntervalsChange,
  onCopy,
  value,
  active,
  setActive,
}: FormAvailabilityDayInputProps) {
  const updateInterval = useCallback(
    (index: number, prop: "start" | "end") => {
      return (time: TimeValue | null) => {
        if (!(time instanceof Time) || !value?.[index]) {
          return;
        }

        const newInterval = value[index].clone();
        newInterval[prop] = time;
        const newIntervals = [...value];
        newIntervals[index] = newInterval;
        onIntervalsChange?.(newIntervals);
      };
    },
    [onIntervalsChange, value],
  );

  return (
    <div className="flex gap-2">
      <div className="w-24 self-start py-3">
        <Checkbox isSelected={active} onChange={setActive}>
          {dayAsString(day)}
        </Checkbox>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2 text-sm">
          <TimeField
            className="w-[4.5rem]"
            shouldForceLeadingZeros
            placeholderValue={new Time(0, 0)}
            value={value?.[0].start ?? null}
            onChange={updateInterval(0, "start")}
            isInvalid={value?.[0] && value[0].start > value[0].end}
            isDisabled={!active}
            aria-label="start"
          />
          <span>às</span>
          <TimeField
            className="w-[4.5rem]"
            shouldForceLeadingZeros
            placeholderValue={new Time(0, 0)}
            value={value?.[0].end ?? null}
            onChange={updateInterval(0, "end")}
            isInvalid={value?.[0] && value[0].end < value[0].start}
            isDisabled={!active}
            aria-label="end"
          />
          <AriaButton
            isDisabled={!active}
            onPress={() => {
              if (!value || !active) {
                return;
              }

              return onIntervalsChange?.([...value, new TimeInterval()]);
            }}
          >
            <AddCircle className="text-gray-3" variant="Bold" />
          </AriaButton>
          <DialogTrigger>
            <AriaButton>
              <Copy className="text-gray-3" variant="Bold" />
            </AriaButton>
            <CopyToWeekDaysPopover
              onApply={onCopy}
              defaultSelectedDays={[day]}
              readOnlyDays={[day]}
            />
          </DialogTrigger>
        </div>
        {value?.slice(1).map((interval, index) => (
          <div className="flex items-center gap-2 text-sm" key={index}>
            <TimeField
              className="w-[4.5rem]"
              shouldForceLeadingZeros
              minValue={value[index]?.end}
              maxValue={interval.end}
              value={interval.start}
              onChange={updateInterval(index + 1, "start")}
              isInvalid={
                interval.start < value[index]?.end ||
                interval.start > interval.end
              }
              aria-label="start"
            />
            <span>às</span>
            <TimeField
              className="w-[4.5rem]"
              shouldForceLeadingZeros
              minValue={interval.start}
              maxValue={value[index + 2]?.start}
              value={interval.end}
              onChange={updateInterval(index + 1, "end")}
              isInvalid={
                interval.end < interval.start ||
                interval.end > value[index + 2]?.start
              }
              aria-label="end"
            />
            <AriaButton
              onPress={() =>
                onIntervalsChange?.(value.filter((_, i) => i !== index + 1))
              }
            >
              <CloseCircle className="text-gray-3" variant="Bold" />
            </AriaButton>
          </div>
        ))}
      </div>
    </div>
  );
}
