import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormComboBoxUnitTypeInput,
  FormFileInput,
  FormRadioGroupInput,
  FormRootError,
  FormTextInput,
  Modal,
  Radio,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationDocumentCreate } from "@/shared/hooks/api";
import { megabyte } from "@/shared/utils";

import { DocumentCreate, DocumentCreateSchema } from "../schemas";

export interface DocumentCreateOneModalProps
  extends Omit<ModalOverlayProps, "children"> {
  manualId?: number;
  unitId?: number;
  paiId?: number;
}

export function DocumentCreateOneModal({
  manualId,
  unitId,
  paiId,
  ...props
}: DocumentCreateOneModalProps) {
  const { developmentId } = useRequiredParams();
  const { formState, handleSubmit, control, reset, watch, unregister } =
    useForm<DocumentCreate>({
      resolver: zodResolver(DocumentCreateSchema),
      values: {
        documentCreationType: "directory",
        manualId,
        unidadeId: unitId,
        paiId,
        nome: "",
      },
    });

  const documentCreateMutation = useMutationDocumentCreate({
    onSuccess: () => {
      toastQueue.add(
        {
          type: "success",
          message: "Sucesso ao adicionar documento",
        },
        { timeout: 2000 },
      );
      props.onOpenChange?.(false);
    },
  });

  const watchDocumentType = watch("documentCreationType");

  useEffect(() => {
    if (watchDocumentType === "directory") {
      unregister("link");
    }
  }, [unregister, watchDocumentType]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const onSubmit: SubmitHandler<DocumentCreate> = ({ manualId, ...values }) => {
    const basePayload = {
      ...values,
      manualId: values.unidadeId ? undefined : manualId,
    };
    if (values.documentCreationType === "file") {
      return documentCreateMutation.mutateAsync({
        ...basePayload,
        link: values.link[0].key,
      });
    }

    documentCreateMutation.mutateAsync(basePayload);
  };

  return (
    <Modal {...props}>
      <Dialog
        role="alertdialog"
        title="Adicionar novo arquivo/pasta"
        onClose={reset}
        renderButtons={({ close }) => (
          <div className="flex justify-between">
            <FormRootError />
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              onPress={() => handleSubmit(onSubmit)()}
              showSpinner={documentCreateMutation.isPending}
            >
              Adicionar
            </Button>
          </div>
        )}
      >
        <form className="flex min-w-96 flex-col gap-4">
          <FormTextInput control={control} label="Título" name="nome" />
          {!unitId && !paiId && (
            <FormComboBoxUnitTypeInput
              control={control}
              name="unidadeId"
              filters={{
                empreendimentoId: +developmentId,
                status: true,
              }}
            />
          )}
          <FormRadioGroupInput control={control} name="documentCreationType">
            <div className="flex gap-6">
              <Radio value="directory">Pasta</Radio>
              <Radio value="file">Arquivo</Radio>
            </div>
          </FormRadioGroupInput>
          {watchDocumentType === "file" && (
            <FormFileInput
              control={control}
              label="Arquivo"
              name="link"
              maxFileSize={50 * megabyte}
            />
          )}
        </form>
      </Dialog>
    </Modal>
  );
}
