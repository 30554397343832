import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";
import { z } from "zod";

import { falsyToNull, getDigits } from "@/shared/utils";

export const CreateClientNewUnitSchema = z.object({
  unitCreationType: z.literal("new-unit"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    tipoClientId: z.number().int(),
  }),
  cliente: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    email: z.string().email(),
    telefone: z
      .string()
      .nullish()
      .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
    cpf: z
      .string()
      .nullish()
      .transform((v) => (v ? getDigits(v) : v))
      .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
  }),
});

export const CreateClientRegisteredUnitSchema = z.object({
  unitCreationType: z.literal("registered-unit"),
  cliente: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    email: z.string().email(),
    telefone: z
      .string()
      .nullish()
      .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
    cpf: z
      .string()
      .nullish()
      .transform((v) => (v ? getDigits(v) : v))
      .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
    unidadeId: falsyToNull(z.number().int().optional()),
  }),
});

export const CreateClientNoUnitSchema = z.object({
  unitCreationType: z.literal("no-unit"),
  cliente: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    email: z.string().email(),
    telefone: z
      .string()
      .nullish()
      .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
    cpf: z
      .string()
      .nullish()
      .transform((v) => (v ? getDigits(v) : v))
      .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
  }),
});

export const CreateClientSchema = z.discriminatedUnion("unitCreationType", [
  CreateClientNewUnitSchema,
  CreateClientRegisteredUnitSchema,
  CreateClientNoUnitSchema,
]);

export type CreateClient = z.infer<typeof CreateClientSchema>;
export type CreateClientNoUnit = z.infer<typeof CreateClientNoUnitSchema>;
