import { Accordion, AccordionProps } from "../accordion";

export function FiltersAccordion({
  children,
  ...props
}: Omit<AccordionProps, "title">) {
  return (
    <Accordion {...props} title="Filtros" styleType="filter">
      {children}
    </Accordion>
  );
}
