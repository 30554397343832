import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

import {
  ProductGeneralDataSchema,
  ProductGeneralDataStepSchema,
  ProductTechnicalDataSchema,
} from "../create/schemas";

export const ProductTechnicalDataEditSchema = z.object({
  ...ProductTechnicalDataSchema.pick({ cuidados: true, perdas: true }).shape,
});

export const ProductEditSchema = z.object({
  ...ProductGeneralDataSchema.shape,
  ...ProductTechnicalDataEditSchema.shape,
  subsistemaId: z.number().int().nullish(),
});

const ProductTechnicalDataEditStepSchema = generateStepSchema(
  ProductTechnicalDataEditSchema,
  1,
);

export const ProductEditFormSchema = generateMultiStepSchema([
  ProductGeneralDataStepSchema,
  ProductTechnicalDataEditStepSchema,
]);

export type ProductTechnicalDataEdit = z.infer<
  typeof ProductTechnicalDataEditSchema
>;
export type ProductEdit = z.infer<typeof ProductEditSchema>;

export type ProductEditForm = z.infer<typeof ProductEditFormSchema>;
