import { Cell } from "react-aria-components";

import { cn } from "@/shared/utils";

interface ServiceStatusCellProps {
  status: string;
}

export function ServiceStatusCell({ status }: ServiceStatusCellProps) {
  return (
    <Cell>
      <p
        className={cn(
          "inline-block rounded-lg px-5 py-1.5 text-sm font-semibold capitalize",
          {
            "bg-success/15 text-success": status?.toLowerCase() === "concluído",
            "bg-danger/15 text-danger": status?.toLowerCase() === "em análise",
            "bg-info/15 text-info": status?.toLowerCase() === "em execução",
          },
        )}
      >
        {status}
      </p>
    </Cell>
  );
}
