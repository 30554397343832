import { ArrowDown2, ArrowRight2, Edit } from "iconsax-react";
import { DialogTrigger } from "react-aria-components";
import tinycolor from "tinycolor2";

import { MaintenanceEditModal } from "@/modules/assistance/modules/assistance-kanban/modules/edit/components";
import {
  AnimatedContainer,
  ApprovalHeader,
  Breadcrumbs,
  Button,
  Can,
  Card,
  PageTitle,
  ResourceProfileImage,
  ResponsibleListModal,
  Separator,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import { useMutatioMaintenanceUpdate } from "@/shared/hooks/api/maintenance";
import { Manutencao } from "@/shared/services";
import {
  AssistanceStatus,
  MaintenanceTypeId,
  calculateExpirationDate,
} from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { StatusMenu } from "../../../components";
import {
  MaintenanceApproveModal,
  MaintenanceRejectModal,
  WarrantyTag,
} from "../components";

interface CallDetailDataProps extends React.PropsWithChildren {
  maintenance?: Manutencao;
}

export function CallDetailData({ children, maintenance }: CallDetailDataProps) {
  const { serviceId } = useRequiredParams();
  const {
    isOn: isApprovalOn,
    turnOn: approvalTurnOn,
    setOpen: approvalSetOpen,
  } = useSwitch(false);
  const {
    isOn: isRejectOn,
    turnOn: rejectTurnOn,
    setOpen: rejectSetOpen,
  } = useSwitch(false);
  const {
    isOn: isResponsibleOn,
    turnOn: responsibleTurnOn,
    setOpen: responsibleSetOpen,
  } = useSwitch(false);

  const callUpdateMutation = useMutatioMaintenanceUpdate();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do chamado</PageTitle>
      </div>
      <div>
        <div className="space-y-4">
          {maintenance?.statusManutencaoId ===
            AssistanceStatus.UNDER_REVIEW && (
            <>
              <ApprovalHeader
                title="Deseja aprovar esse chamado/assistência?"
                turnApproveModalOn={approvalTurnOn}
                turnRejectModalOn={rejectTurnOn}
              />
              <MaintenanceApproveModal
                maintenanceId={+serviceId}
                isOpen={isApprovalOn}
                onOpenChange={approvalSetOpen}
              />
              <MaintenanceRejectModal
                maintenanceId={+serviceId}
                isOpen={isRejectOn}
                onOpenChange={rejectSetOpen}
              />
            </>
          )}
          <Card className="relative gap-4 rounded-none rounded-t-lg border-none px-6 pt-6">
            <div className="absolute left-0 top-0 h-[calc(100%+2rem)] w-2 rounded-l-lg bg-primary" />
            <div className="flex justify-between">
              <div className="flex items-center gap-4">
                <ResourceProfileImage
                  size="small"
                  file={maintenance?.colaboradorResponsavel?.foto}
                />
                <Can I="update.maintenance">
                  {(allowed) => (
                    <div
                      className="flex cursor-pointer items-center gap-3"
                      onClick={allowed ? responsibleTurnOn : undefined}
                    >
                      <div className="flex cursor-pointer flex-col justify-center">
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-gray-10">
                            {nullishStringMask(
                              maintenance?.colaboradorResponsavel?.nome,
                            )}
                          </p>
                        </div>
                        <div className="flex gap-6 text-xs text-primary">
                          <p className="flex gap-1">Responsável</p>
                        </div>
                        {maintenance?.construtoraId && (
                          <ResponsibleListModal
                            isOpen={isResponsibleOn}
                            onOpenChange={responsibleSetOpen}
                            companyId={maintenance.construtoraId}
                            onSelected={(responsible) => {
                              callUpdateMutation.mutateAsync({
                                id: +maintenance?.id,
                                requestBody: {
                                  colaboradorResponsavelId: responsible.id,
                                },
                              });
                            }}
                          />
                        )}
                      </div>
                      <ArrowDown2 size={16} className="text-gray-6" />
                    </div>
                  )}
                </Can>
                <DialogTrigger>
                  <Button styleType="default">
                    <Edit className="cursor-pointer text-primary" />
                  </Button>
                  {maintenance?.construtoraId && (
                    <MaintenanceEditModal
                      maintenanceId={maintenance.id}
                      useFormProps={{
                        defaultValues: {
                          ...maintenance,
                          tipoManutencaoId: MaintenanceTypeId.ASSISTANCE,
                        },
                      }}
                    />
                  )}
                </DialogTrigger>
              </div>
              <div>
                {maintenance?.statusManutencaoId && (
                  <StatusMenu
                    maintenanceId={maintenance.id}
                    maintenanceStatusId={maintenance.statusManutencaoId}
                  />
                )}
              </div>
            </div>
            <Separator />
            <div className="flex justify-between">
              <div className="flex gap-2 py-4 text-xs xl:gap-11">
                <div>
                  <p>Solicitante</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(maintenance?.unidade?.dono?.nome)}
                  </p>
                </div>
                <div>
                  <p>Empreendimento</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(maintenance?.empreendimento?.nome)}
                  </p>
                </div>
                <div>
                  <p>Unidade</p>
                  <p className="font-bold text-primary">
                    {maintenance?.unidade?.nome}
                  </p>
                </div>
                <div>
                  <p>Número de protocolo</p>
                  <p className="font-bold text-primary">{maintenance?.id}</p>
                </div>
                <div>
                  <p>Canal de abertura</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(maintenance?.tipoCanal?.nome)}
                  </p>
                </div>
                <div>
                  <p>Data de abertura</p>
                  <p className="font-bold text-primary">
                    {new Date(
                      nullishStringMask(maintenance?.criadoEm),
                    ).toLocaleDateString("pt-br")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2 py-4">
                {maintenance?.falha && maintenance?.empreendimento && (
                  <>
                    <WarrantyTag
                      receivedDate={maintenance.criadoEm}
                      prazo={maintenance.falha.prazo}
                      timeUnit={maintenance.falha.unidadeTempo.nome}
                    />
                    <p className="w-full text-right text-xs text-gray-6">
                      Término:{" "}
                      {calculateExpirationDate({
                        receivedDate: maintenance.empreendimento.dataEntrega,
                        prazo: maintenance.falha.prazo,
                        timeUnit: maintenance.falha.unidadeTempo.nome,
                      }).toLocaleDateString("pt-br")}
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="flex gap-8 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
              <div className="w-full">
                <p> Solicitação do cliente</p>
                <div className="flex items-center justify-between">
                  <p className="w-5/6 font-bold text-primary">
                    {nullishStringMask(maintenance?.solicitacao)}
                  </p>
                  {maintenance?.etiqueta?.cor && (
                    <span
                      className="flex items-center rounded px-2 py-1 text-sm font-medium"
                      style={{
                        color: maintenance.etiqueta.cor,
                        backgroundColor: tinycolor(maintenance.etiqueta.cor)
                          .setAlpha(0.5)
                          .toRgbString(),
                      }}
                    >
                      {maintenance.etiqueta.nome}
                      <Separator
                        orientation="vertical"
                        className="ml-5 mr-2"
                        style={{ borderColor: maintenance.etiqueta.cor }}
                      />
                      <ArrowRight2 size="12" color={maintenance.etiqueta.cor} />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>

        {children}
      </div>
    </AnimatedContainer>
  );
}
