import type {
  CheckboxGroupProps as AriaCheckboxGroupProps,
  ValidationResult,
} from "react-aria-components";
import {
  CheckboxGroup as AriaCheckboxGroup,
  FieldError,
  Label,
  Text,
} from "react-aria-components";

import { cn } from "@/shared/utils";

import {
  InputStyleProps,
  container,
  label as labelStyle,
} from "../style/input";

export interface CheckboxGroupProps
  extends Omit<AriaCheckboxGroupProps, "children">,
    InputStyleProps {
  children?: React.ReactNode;
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function CheckboxGroup({
  className,
  label,
  description,
  errorMessage,
  children,
  ...props
}: CheckboxGroupProps) {
  return (
    <AriaCheckboxGroup className={cn(container(props), className)} {...props}>
      <Label className={labelStyle(props)}>{label}</Label>
      {children}
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
    </AriaCheckboxGroup>
  );
}
