import {
  Breadcrumbs,
  Can,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationTopicDelete,
  useMutationTopicUpdate,
  useTopic,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

import {
  ManualTopicDetailError,
  ManualTopicDetailLoading,
} from "../components";

export function ManualTopicDetail() {
  const { topicId } = useRequiredParams();
  const topicQuery = useTopic(topicId);
  const mutationTopicUpdate = useMutationTopicUpdate();
  const mutationTopicDelete = useMutationTopicDelete();

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <QueryHandler
        {...topicQuery}
        renderLoading={ManualTopicDetailLoading}
        renderError={(error) => <ManualTopicDetailError error={error} />}
      >
        {(data) => (
          <>
            <div className="space-y-4">
              <Breadcrumbs />
              <div className="flex justify-between gap-2">
                <PageTitle primary className="flex items-center gap-2">
                  {data?.posicao} {data?.titulo}
                  <Can I="update.topic">
                    <EditLink />
                  </Can>
                  <Can I="delete.topic">
                    <DeleteResourceButton
                      resource="tópico"
                      subject={data?.titulo ?? ""}
                      action={() =>
                        mutationTopicDelete.mutateAsync({
                          id: +topicId,
                        })
                      }
                      showSpinner={mutationTopicDelete.isPending}
                    />
                  </Can>
                </PageTitle>
                <Can I="update.topic">
                  <SwitchStatusButton
                    isDisabled={mutationTopicUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationTopicUpdate.mutateAsync({
                        id: +topicId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              </div>
            </div>
            <div className="space-y-4">
              <div
                className="tiptap text-black-0"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data?.texto ?? ""),
                }}
              />
            </div>
          </>
        )}
      </QueryHandler>
    </div>
  );
}
