import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMemorial,
  useMutationMemorialDelete,
  useMutationUpdateManualMemorial,
} from "@/shared/hooks/api";
import { nullishStringMask } from "@/shared/utils/masks";

import { MemorialDetailError, MemorialDetailLoading } from "../components";

export function MemorialDetail() {
  const { memorialId } = useRequiredParams();
  const memorialQuery = useMemorial(memorialId);
  const mutationMemorialUpdate = useMutationUpdateManualMemorial();
  const mutationMemorialDelete = useMutationMemorialDelete();

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do ambiente</PageTitle>
      </div>
      <Card className="space-y-4 shadow-header">
        <QueryHandler
          {...memorialQuery}
          renderLoading={MemorialDetailLoading}
          renderError={(error) => <MemorialDetailError error={error} />}
        >
          {(data) => (
            <>
              <div className="flex flex-col">
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <PageTitle primary className="break-all">
                        {data?.nome}
                      </PageTitle>
                      <Can I="update.memorial">
                        <EditLink />
                      </Can>
                      <Can I="delete.memorial">
                        <DeleteResourceButton
                          resource="ambiente"
                          subject={data?.nome ?? ""}
                          action={() =>
                            mutationMemorialDelete.mutateAsync({
                              id: +memorialId,
                            })
                          }
                          showSpinner={mutationMemorialDelete.isPending}
                        />
                      </Can>
                    </div>
                  </div>
                  <Can I="update.memorial">
                    <SwitchStatusButton
                      isDisabled={mutationMemorialUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationMemorialUpdate.mutateAsync({
                          id: +memorialId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                </div>
                <p className="text-xs text-primary">
                  Categoria:{" "}
                  <span className="font-semibold">
                    {nullishStringMask(data?.categoria)}
                  </span>
                </p>
              </div>
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
