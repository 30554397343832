export const currencyMask = (value?: string | null) => {
  const numericValue = value?.replace(/[^0-9]/g, "");
  const formattedValue = numericValue
    ? new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(parseInt(numericValue, 10) / 100)
    : "R$ 0,00";
  return formattedValue;
};
