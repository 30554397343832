import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { ApiError, apiProvider } from "@/shared/services";
import { z } from "@/shared/utils";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { ProfilePasswordSchema } from "../schemas";

export async function passwordUpdateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<
    z.infer<typeof ProfilePasswordSchema> & {
      redirectTo: string;
      email: string;
    }
  >(
    request,
    async ({ redirectTo, currentPassword, email, confirmNewPassword }) => {
      try {
        await apiProvider.services.AuthService.loginForAccessTokenAuthTokenPost(
          {
            formData: { username: email, password: currentPassword },
          },
        );
      } catch (e) {
        let message = "Erro inesperado aconteceu...";

        if (e instanceof ApiError && e.status === 400) {
          message = "Senha atual incorreta";
        }

        toastQueue.add(
          { type: "error", message },
          {
            timeout: 5000,
          },
        );
        return null;
      }

      await apiProvider.services.UsuariosService.updateSelfUsuariosProfilePut({
        requestBody: {
          senha: confirmNewPassword,
        },
      });

      toastQueue.add(
        { type: "success", message: "Senha atualizada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
