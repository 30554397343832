import { Control, FieldValues, Path, useController } from "react-hook-form";

import { SelectInput, SelectInputProps } from "..";

export interface FormSelectInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends SelectInputProps<T> {
  className?: string;
  label: string;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  isReadOnly?: boolean;
}

export function FormSelectInput<
  T extends object,
  TFieldValues extends FieldValues,
>({
  children,
  control,
  name,
  isDisabled,
  ...props
}: FormSelectInputProps<T, TFieldValues>) {
  const {
    field: { onChange, value, disabled, ref, ...fieldProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: isDisabled,
  });

  return (
    <SelectInput
      {...props}
      {...fieldProps}
      onSelectionChange={onChange}
      selectedKey={value || ""}
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
      errorMessage={error?.message}
      ref={ref}
    >
      {children}
    </SelectInput>
  );
}
