import { useToggle } from "@uidotdev/usehooks";

import { Button } from "../button";

export interface ModalPageStepButtonsProps {
  isFirstPage?: boolean;
  isLastPage?: boolean;
  onPrevious?: () => void;
  onCancel?: () => void;
  onNext?: () => void;
  onSubmit?: () => Promise<void>;
}

export function ModalPageStepButtons({
  isFirstPage,
  isLastPage,
  onPrevious,
  onCancel,
  onNext,
  onSubmit,
}: ModalPageStepButtonsProps) {
  const [isLoading, setIsLoading] = useToggle(false);

  return (
    <div className="flex gap-6">
      <Button
        className="px-8 py-3.5"
        styleType="default"
        color="primary"
        onPress={isFirstPage ? onCancel : onPrevious}
      >
        {isFirstPage ? "Cancelar" : "Voltar"}
      </Button>
      <Button
        className="min-w-44 px-8 py-3.5"
        onPress={
          isLastPage
            ? async () => {
                setIsLoading(true);
                await onSubmit?.();
                setIsLoading(false);
              }
            : onNext
        }
        showSpinner={isLoading}
      >
        {isLastPage ? "Salvar" : "Próximo"}
      </Button>
    </div>
  );
}
