export const numberToCurrencyMask = (
  valueInCentavos?: number | null,
): string => {
  if (!valueInCentavos) {
    return "Vazio";
  }

  const valueInReais = valueInCentavos / 100;

  return valueInReais.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};
