import { Calendar } from "iconsax-react";

import { AnimatedContainer, Card, FieldSetTitle } from "@/shared/components";

import { AssistanceConfigItem } from "../components/assistance-config-item";

export function AssistanceConfig() {
  return (
    <AnimatedContainer>
      <Card className="flex flex-col gap-5 px-6 py-4">
        <FieldSetTitle className="m-0">Configurações do sistema</FieldSetTitle>
        <div className="flex flex-col gap-3">
          <AssistanceConfigItem
            icon={<Calendar className="size-6 text-primary" variant="Bold" />}
            label="Configurar disponibilidade"
            href="./disponibilidade"
          />
        </div>
      </Card>
    </AnimatedContainer>
  );
}
