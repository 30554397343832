import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useSystem(id?: string) {
  return useQuery({
    queryKey: ["system", id],
    queryFn: async () => {
      if (!id) return;
      return apiProvider.services.SistemasService.getOneObjSistemaIdGet({
        id: +id,
      });
    },
    enabled: !!id,
  });
}
