import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface UnitDetailErrorProps {
  error: Error | null;
}

export function UnitDetailError({ error }: UnitDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Unidade não encontrada..."
          : undefined
      }
    />
  );
}
