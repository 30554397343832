import { Warning2 } from "iconsax-react";
import { FormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  FormFooterButtons,
  SaveDataButton,
} from "@/modules/profile/components";
import {
  AnimatedContainer,
  Card,
  ErrorContainer,
  FieldSetTitle,
  Form,
  FormFileInput,
  FormTextInput,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { useProfile } from "@/shared/hooks/api";
import { megabyte } from "@/shared/utils";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { ProfileUpdateSchema } from "../schemas";

export function ProfileUserData() {
  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();
  const profileQuery = useProfile();

  return (
    <QueryHandler
      {...profileQuery}
      renderLoading={() => <Spinner className="mx-auto" />}
      renderError={() => (
        <div className="space-y-4">
          <ErrorContainer />
        </div>
      )}
    >
      {(data) =>
        !data ? (
          <p>Dados indisponíveis</p>
        ) : (
          <Form
            schema={ProfileUpdateSchema}
            validationErrors={formErrors}
            useFormProps={{
              defaultValues: {
                nome: data.nome,
                cpf: data.cpf ? cpfMask(data.cpf) : undefined,
                email: data.email,
                telefone: data.telefone ? phoneMask(data.telefone) : undefined,
                foto: data.foto
                  ? [
                      {
                        fileName: data.foto,
                        key: data.foto,
                      },
                    ]
                  : undefined,
              },
            }}
          >
            {({ control }) => (
              <>
                <AnimatedContainer className="m-0 flex gap-4">
                  <Card className="flex-1 space-y-6 p-8">
                    <div>
                      <FieldSetTitle>Informações da conta</FieldSetTitle>
                      <div className="space-y-4">
                        <input type="hidden" name="redirectTo" value="/" />
                        <FormTextInput
                          control={control}
                          label="Nome completo"
                          name="nome"
                        />
                        <FormTextInput
                          control={control}
                          mask={cpfMask}
                          label="CPF"
                          name="cpf"
                        />
                      </div>
                    </div>
                    <div>
                      <FieldSetTitle>Dados de contato</FieldSetTitle>
                      <div className="space-y-4">
                        <FormTextInput
                          control={control}
                          label="E-mail"
                          name="email"
                        />
                        <FormTextInput
                          control={control}
                          mask={phoneMask}
                          label="Telefone"
                          name="telefone"
                        />
                      </div>
                    </div>
                  </Card>
                  <Card className="max-w-80 space-y-3 self-start text-xs">
                    <p className="text-black-2">Adicione a foto do perfil</p>
                    <div className="flex items-center gap-2 text-gray-6">
                      <Warning2
                        className="text-primary"
                        size="1.125rem"
                        variant="Bulk"
                      />
                      <span>Fique atento a qualidade da imagem</span>
                    </div>
                    <FormFileInput
                      className="[&>div]:w-full"
                      control={control}
                      name="foto"
                      acceptedFileTypes={["image/png", "image/jpeg"]}
                      maxFileSize={20 * megabyte}
                    />
                    <p className="text-gray-6">
                      Adicionar a sua foto pode ajudar a consolidar a sua
                      identidade.
                    </p>
                  </Card>
                </AnimatedContainer>
                <div className="mt-20">
                  <FormFooterButtons>
                    <SaveDataButton type="submit" showSpinner={isSubmitting} />
                  </FormFooterButtons>
                </div>
              </>
            )}
          </Form>
        )
      }
    </QueryHandler>
  );
}
