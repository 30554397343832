import { nullishStringMask } from "@/shared/utils/masks";

interface KanbanCardSubtitleProps {
  subtitle?: string[];
}
export function KanbanCardSubtitle({ subtitle }: KanbanCardSubtitleProps) {
  return (
    <>
      {subtitle?.map((subtitle) => (
        <span className="text-xs font-light text-gray-10">
          {nullishStringMask(subtitle)}
        </span>
      ))}
    </>
  );
}
