import { z } from "@/shared/utils";

export const MediaFilesCreateSchema = z.object({
  manutencaoId: z.number().int(),
  arquivo: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish()
    .refine((files) => !!files && files.length > 0, "Campo obrigatório"),
  seImagem: z.boolean(),
});
export type MediaFilesCreate = z.infer<typeof MediaFilesCreateSchema>;
