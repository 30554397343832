import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";

import {
  Button,
  Dialog,
  Link,
  Modal,
  SearchInput,
  Spinner,
} from "@/shared/components";
import { useInfinitePosts, useMutationAddManualPost } from "@/shared/hooks/api";

interface PostModalAssociateProps extends Omit<ModalOverlayProps, "children"> {
  manualId: number;
  onSuccess?: () => void;
}

export function PostModalAssociate({
  manualId,
  onSuccess,
  ...props
}: PostModalAssociateProps) {
  const [{ flatData, isFetching }, { handleSearchChange }, { ref }] =
    useInfinitePosts();

  const mutationAddManualPost = useMutationAddManualPost({ onSuccess });

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar post"
        renderButtons={({ close }) =>
          mutationAddManualPost.isPending ? (
            <div className="flex items-center justify-center p-4">
              <Spinner />
            </div>
          ) : (
            <>
              <Button className="px-7 py-3" styleType="default" onPress={close}>
                Cancelar
              </Button>
              <Link
                className="px-7 py-3"
                styleType="default"
                href="/app/cadastros/post/cadastrar/"
              >
                Cadastrar novo post globalmente
              </Link>
              <Link className="px-7 py-3" styleType="default" href="cadastrar/">
                Cadastrar novo post
              </Link>
            </>
          )
        }
      >
        <div className="space-y-4">
          <SearchInput onDebounceChange={handleSearchChange} />
          <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll p-1">
            {flatData?.map((e, i) => (
              <AriaButton
                key={e.id}
                ref={i === flatData.length - 1 ? ref : null}
                className="mr-4 rounded-lg border border-white-3 p-3 text-left"
                onPress={async () => {
                  await mutationAddManualPost.mutateAsync({
                    manualId: +manualId,
                    postId: e.id,
                  });
                }}
                isDisabled={mutationAddManualPost.isPending}
              >
                {e.titulo} · {e.nbr?.nome}
              </AriaButton>
            ))}
            {isFetching && <Spinner className="mx-auto" />}
          </div>
        </div>
      </Dialog>
    </Modal>
  );
}
