import { Navigate, type RouteObject } from "react-router";

import { forgotPasswordAction } from "@/modules/forgot-password/actions/forgot-password-action";
import ForgotPasswordPage from "@/modules/forgot-password/containers/forgot-password";
import { loginAction } from "@/modules/login/actions/login-action";
import LoginPage from "@/modules/login/containers/login";
import { recoverPasswordAction } from "@/modules/recover-password/actions/recover-password-action";
import RecoverPasswordPage from "@/modules/recover-password/containers/recover-password-page";
import { publicLoader } from "@/shared/layout/public/loaders/public-loader";

import { ErrorPage, PublicLayout } from "../layout";

const publicRouter: RouteObject[] = [
  {
    path: "/",
    loader: publicLoader,
    element: <PublicLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/login" />,
      },
      {
        path: "login",
        action: loginAction,
        element: <LoginPage />,
      },
      {
        path: "esqueci-a-senha",
        action: forgotPasswordAction,
        element: <ForgotPasswordPage />,
      },
    ],
  },
  {
    path: "/recuperar-senha",
    action: recoverPasswordAction,
    element: <RecoverPasswordPage />,
    errorElement: <ErrorPage />,
  },
];

export default publicRouter;
