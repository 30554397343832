import { CloseCircle } from "iconsax-react";
import React, { useRef } from "react";
import SliderRoot from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Button } from "..";

interface SliderProps extends React.PropsWithChildren {
  isOpen?: boolean;
  onClose: () => void;
  selectedIndex?: number;
  total?: number | null;
  setCarouselIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function Slider({
  isOpen,
  children,
  onClose,
  total,
  selectedIndex = 0,
  setCarouselIndex,
}: SliderProps) {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: selectedIndex,
    beforeChange: (next: number) => setCarouselIndex(next),
    afterChange: (current: number) => setCarouselIndex(current),
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10 flex flex-col bg-black-2/95 px-10 pb-8 pt-4 backdrop-blur">
      <div className="flex items-center justify-between">
        <span className="text-base font-medium text-white-0">
          Item {selectedIndex + 1} {total && `de ${total}`}
        </span>
        <Button onPress={onClose} className="bg-transparent">
          <CloseCircle className="text-white-0" />
        </Button>
      </div>

      <SliderRoot {...settings} className="h-full" ref={sliderRef}>
        {children}
      </SliderRoot>
    </div>
  );
}
