import { forwardRef } from "react";
import {
  Label,
  TextArea,
  TextAreaProps,
  TextField,
} from "react-aria-components";
import { TextFieldProps } from "react-aria-components";

import { cn } from "@/shared/utils";

import { FieldError } from "..";
import {
  InputStyleProps,
  container,
  input,
  label as labelStyle,
} from "./style/input";

export interface TextAreaInputProps extends TextFieldProps, InputStyleProps {
  label?: string;
  errorMessage?: string;
  mask?: (value?: string) => string | undefined;
  isSecret?: boolean;
  inputProps?: TextAreaProps;
}

function TextAreaInput(
  {
    label,
    className,
    onChange,
    mask,
    errorMessage,

    inputProps,
    ...props
  }: TextAreaInputProps,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  return (
    <TextField
      {...props}
      onChange={(v) => {
        onChange?.(mask ? (mask(v) as string) : v);
      }}
      className={cn(container(props), className)}
    >
      <Label className={labelStyle(props)}>{label}</Label>
      <div className="relative">
        <TextArea
          className={cn(input(props), "h-full w-full")}
          ref={ref}
          placeholder="Insira aqui"
          {...inputProps}
        />
      </div>
      <FieldError>{errorMessage}</FieldError>
    </TextField>
  );
}

const ForwardedRefTextAreaInput = forwardRef(TextAreaInput);

export { ForwardedRefTextAreaInput as TextAreaInput };
