import { CalendarDate } from "@internationalized/date";
import { useContext, useRef } from "react";
import { useCalendarCell } from "react-aria";
import { CalendarStateContext } from "react-aria-components";

import { Interval } from "@/shared/components/form/availability/schema";

import { cellButtonStyles } from "./styles";

interface CellButtonProps<T extends Interval> {
  date: CalendarDate;
  isSelected: boolean;
  interval?: T;
  onClick?: () => void;
}

export function CellButton<T extends Interval>({
  date,
  isSelected,
  interval,
  onClick,
}: CellButtonProps<T>) {
  const ref = useRef(null);
  const state = useContext(CalendarStateContext)!;
  const { buttonProps, isOutsideVisibleRange, isDisabled, isUnavailable } =
    useCalendarCell({ date }, state, ref);

  return (
    <div
      {...buttonProps}
      ref={ref}
      hidden={isOutsideVisibleRange}
      className={cellButtonStyles({ isDisabled, isUnavailable, isSelected })}
      onClick={(e) => {
        buttonProps.onClick?.(e);
        onClick?.();
      }}
    >
      {interval
        ? `${interval?.start.toString().substring(0, 5)} - ${interval?.end.toString().substring(0, 5)}`
        : "Vazio"}
    </div>
  );
}

export default CellButton;
