import { isMobilePhone, isTaxID } from "validator";

import { getDigits, z } from "@/shared/utils";

export const ProfileUpdateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  cpf: z
    .string()
    .transform(getDigits)
    .refine((v) => isTaxID(v, "pt-BR"), "CPF inválido")
    .nullish(),
  email: z.string().email(),
  telefone: z
    .string()
    .refine((v) => isMobilePhone(v, "pt-BR"), "Número inválido")
    .nullish(),
  foto: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
});
