export enum MaintenanceTypeId {
  PREVENTIVE = 1,
  ASSISTANCE = 2,
}

export enum MaintenanceStatusId {
  SOLICITED = 1,
  IN_PROGRESS = 2,
  SCHEDULED = 3,
  SERVICE_IN_PROGRESS = 4,
  COMPLETED = 5,
  REJECTED = 6,
}

export enum AssistanceStatus {
  UNDER_REVIEW = 7,
  OPEN = 8,
  INSPECION_SCHEDULED = 9,
  SERVICE_IN_PROGRESS = 10,
  COMPLETED = 11,
  REJECTED = 12,
}
