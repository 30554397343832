import { useFormContext } from "react-hook-form";

import { Card, FieldSetTitle, FormRichTextInput } from "@/shared/components";

import { CompanyUseTerms } from "../schemas";

export function CompanyCostTermsForm() {
  const { control } = useFormContext<CompanyUseTerms>();

  return (
    <Card className="mb-4 p-6">
      <FieldSetTitle>Termos de custo da construtora</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormRichTextInput
          control={control}
          className="col-span-2"
          label="Termos de custo"
          name="termosDeCusto"
        />
      </div>
    </Card>
  );
}
