import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  ManutencaoArquivo,
  ManutencaoArquivoCreate,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationMediaFilesConfig
  extends Omit<
    UseMutationOptions<ManutencaoArquivo, Error, ManutencaoArquivoCreate>,
    "mutationFn"
  > {}

export function useMutationMediaFilesCreate(
  options?: UseMutationMediaFilesConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ManutencaoArquivoCreate) => {
      return apiProvider.services.ManutencaoArquivosService.createObjManutencaoArquivoPost(
        {
          requestBody,
        },
      );
    },
    onSuccess: async (_, { manutencaoId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["maintenance", manutencaoId.toString()],
      });
      toastQueue.add(
        {
          type: "success",
          message: "Sucesso ao adicionar arquivo",
        },
        { timeout: 2000 },
      );
    },

    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar arquivo: ${e.message}`,
      }),
    ...options,
  });
}
