import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Manutencao, ManutencaoCreate, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationCreateMaintenanceConfig
  extends Omit<
    UseMutationOptions<Manutencao, Error, ManutencaoCreate>,
    "mutationFn"
  > {}

export function useMutationMaintenanceCreate(
  options?: UseMutationCreateMaintenanceConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ManutencaoCreate) =>
      apiProvider.services.ManutencaosService.createObjManutencaoPost({
        requestBody,
      }),
    onSettled: async (_, __) => {
      await queryClient.invalidateQueries({
        queryKey: ["maintenancesInfinite"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar chamado: ${e.message}`,
      }),
    ...options,
  });
}
