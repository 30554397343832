import { CalendarDate } from "@internationalized/date";
import { useContext } from "react";
import { useCalendarGrid } from "react-aria";
import type { CalendarGridProps } from "react-aria-components";
import { CalendarStateContext } from "react-aria-components";

import { Interval } from "@/shared/components/form/availability/schema";
import { Manutencao } from "@/shared/services";

import { CalendarCell } from "./callendar-cell";

interface WeekCalendarGridProps extends CalendarGridProps {
  datesIntervals: NonNullable<Manutencao["disponibilidade"]>;
  onIntervalSelected?: (date: CalendarDate, interval: Interval) => void;
}

export function WeekCalendarGrid({
  datesIntervals,
  onIntervalSelected,
  ...props
}: WeekCalendarGridProps) {
  const state = useContext(CalendarStateContext)!;
  const { gridProps } = useCalendarGrid(props, state);

  return (
    <tbody className="mt-3 w-full gap-1" {...gridProps}>
      <tr>
        {state.getDatesInWeek(0).map((actualDate, i) => {
          if (actualDate !== null) {
            return (
              <CalendarCell
                date={actualDate}
                datesIntervals={datesIntervals}
                key={i}
                onIntervalSelected={onIntervalSelected}
              />
            );
          }
        })}
      </tr>
    </tbody>
  );
}
