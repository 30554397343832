import { ActionFunctionArgs, redirect } from "react-router";

import { serializeDisponibilidade } from "@/shared/components/form/availability/serialization";
import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { AssistanceAvailability } from "../schemas";

export async function updateCompanyAvailabilityAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<AssistanceAvailability & { redirectTo: string }>(
    request,
    async ({ redirectTo, construtoraId, disponibilidade, ...payload }) => {
      await apiProvider.services.ConstrutorasService.updateConfigConstrutorasIdConfigPut(
        {
          id: construtoraId,
          requestBody: {
            ...payload,
            disponibilidade: serializeDisponibilidade(disponibilidade),
          },
        },
      );

      await queryClient.invalidateQueries({
        queryKey: ["company", construtoraId.toString()],
      });

      toastQueue.add(
        { type: "success", message: "Disponibilidade atualizada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
