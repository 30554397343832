import { Accordion } from "@/shared/components";

import {
  ContactsData,
  FaqData,
  MissingWarranty,
  SolicitationData,
  WarrantyData,
} from "../components";
import { ClientSchedule } from "../components/client-schedule";

export function CallGeneralData() {
  return (
    <div className="flex flex-col gap-4">
      <Accordion title="Dados da solicitação" defaultOpen>
        <SolicitationData />
      </Accordion>
      <Accordion title="Disponibilidade do cliente">
        <ClientSchedule />
      </Accordion>
      <Accordion title="Garantias de uso">
        <WarrantyData />
      </Accordion>
      <Accordion title="Contatos">
        <ContactsData />
      </Accordion>
      <Accordion title="FAQ">
        <FaqData />
      </Accordion>
      <Accordion title="Perda de garantia">
        <MissingWarranty />
      </Accordion>
    </div>
  );
}
