import { useParams } from "react-router";

import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useSystem, useUnits } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { SystemFormWizard } from "../../create/components";
import {
  SystemCreateFormSchema,
  SystemCreateSchema,
} from "../../create/schemas";

export function SystemEdit() {
  const { systemId } = useRequiredParams();
  const { manualId } = useParams();

  const systemQuery = useSystem(systemId);
  const unitsQuery = useUnits({
    sistemaIds: [Number(systemId)],
    elementsPerPage: undefined,
  });

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar sistema</PageTitle>
        <QueryHandler
          {...systemQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Sistema não encontrado..."
                  : undefined
              }
            />
          )}
        >
          {(system) => (
            <QueryHandler
              {...unitsQuery[0]}
              renderLoading={() => <Spinner className="mx-auto" />}
              renderError={(error) => (
                <ErrorContainer
                  message={
                    error instanceof ApiError && error.status === 404
                      ? "Unidades não encontradas..."
                      : undefined
                  }
                />
              )}
            >
              {(units) => (
                <SystemFormWizard
                  formSchema={SystemCreateFormSchema}
                  submitSchema={SystemCreateSchema}
                  useFormProps={{
                    defaultValues: {
                      ...system,
                      tipoUnidade: !manualId ? "geral" : "personalizada",
                      unidades: units?.content.map((unit) => ({
                        id: unit.id,
                        nome: `[${unit.bloco.nome}] ${unit.nome}`,
                      })),
                    },
                  }}
                />
              )}
            </QueryHandler>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
