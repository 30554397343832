import { forwardRef } from "react";
import {
  TimeField as AriaTimeField,
  TimeFieldProps as AriaTimeFieldProps,
  TimeValue,
} from "react-aria-components";
import { Label, ValidationResult } from "react-aria-components";

import { cn } from "@/shared/utils";

import { DateInput } from ".";
import { FieldError } from "..";
import { InputStyleProps, container, label as labelStyle } from "./style/input";

export interface TimeFieldProps<T extends TimeValue>
  extends AriaTimeFieldProps<T>,
    InputStyleProps {
  label?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  inputClassName?: string;
}

export const TimeField = forwardRef(
  <T extends TimeValue>(
    { label, errorMessage, className, ...props }: TimeFieldProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <AriaTimeField {...props} className={cn(container(props), className)}>
        {label && <Label className={labelStyle(props)}>{label}</Label>}
        <DateInput center ref={ref} />
        <FieldError>{errorMessage}</FieldError>
      </AriaTimeField>
    );
  },
);
