import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Construtora, apiProvider } from "@/shared/services";

export function useCompany(
  companyId?: string,
  options?: Omit<
    UseQueryOptions<
      | {
          company: Construtora;
          developmentCount: number;
        }
      | undefined
    >,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery({
    queryKey: ["company", companyId],
    queryFn: async () => {
      if (!companyId) return;

      const [{ total: developmentCount }, company] = await Promise.all([
        apiProvider.services.EmpreendimentosService.getObjsEmpreendimentosGet({
          elementsPerPage: 1,
          construtoraId: +companyId,
        }),
        apiProvider.services.ConstrutorasService.getOneObjConstrutorasIdGet({
          id: +companyId,
        }),
      ]);
      return { company, developmentCount };
    },
    retry: false,
    enabled: !!companyId,
    ...options,
  });
}
