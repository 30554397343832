import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";

import { toastQueue } from "@/shared/components/toast";
import { FilesService } from "@/shared/services";

export interface UseMutationFileDownloadOptions
  extends Omit<UseMutationOptions<void, Error, string>, "mutationFn"> {}

export function useMutationFileDownload(
  options?: UseMutationFileDownloadOptions,
) {
  return useMutation({
    mutationFn: async (fileUrl: string) => {
      const response =
        await FilesService.getPresignedDownloadUrlFilesDownloadGet({
          filename: fileUrl,
        });

      const blob = (await axios.get(response.url, { responseType: "blob" }))
        .data as Blob;
      const fileName =
        fileUrl.split("/").pop()?.split("?")[0] || "downloaded_file";
      saveAs(blob, fileName);
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao baixar arquivo do servidor: ${e.message}`,
      }),
    ...options,
  });
}
