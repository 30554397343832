import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Manutencao, apiProvider } from "@/shared/services";

interface UseMaintenanceOptions
  extends Omit<
    UseQueryOptions<Manutencao, Error, Manutencao>,
    "queryKey" | "queryFn"
  > {}

export function useMaintenance(
  maintenanceId: string,
  options?: UseMaintenanceOptions,
) {
  return useQuery({
    queryKey: ["maintenance", maintenanceId],
    queryFn: () =>
      apiProvider.services.ManutencaosService.getOneObjManutencaoIdGet({
        id: +maintenanceId,
      }),
    enabled: !!maintenanceId,
    ...options,
  });
}
