import { Handle, Navigate, RouteObject } from "react-router";

import { CommentList } from "./containers";
import { CallDetailLayout } from "./layout";
import { AppointmentList } from "./modules/appointments/containers";
import { CostList } from "./modules/costs/containers";
import { CallGeneralData } from "./modules/general/containers";
import { HistoryList } from "./modules/history/containers";
import { MediaFilesList } from "./modules/media-files/containers";
import { RatingList } from "./modules/rating/containers";
import { SignatureList } from "./modules/signature/containers";

export const serviceDetailRouter: RouteObject = {
  path: "detalhes/:serviceId",
  handle: {
    crumb: "Detalhes do chamado",
    tabBaseUrl: "service",
  } as Handle,
  children: [
    {
      element: <CallDetailLayout />,
      children: [
        { index: true, element: <Navigate to="geral/" /> },
        {
          path: "geral",
          handle: {
            crumb: "Geral",
          } as Handle,
          element: <CallGeneralData />,
        },
        {
          path: "custos",
          handle: {
            crumb: "Custos",
          } as Handle,
          element: <CostList />,
        },
        {
          path: "arquivos-e-midias",
          handle: {
            crumb: "Arquivos e mídias",
          } as Handle,
          element: <MediaFilesList />,
        },
        {
          path: "classificados",
          handle: {
            crumb: "Classificados",
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "diagnostico",
          handle: {
            crumb: "Diagnóstico",
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "servicos",
          handle: {
            crumb: "Serviços",
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "comentarios",
          handle: {
            crumb: "Comentários",
          } as Handle,
          element: <CommentList />,
        },
        {
          path: "agendamentos",
          handle: {
            crumb: "Agendamentos",
          } as Handle,
          element: <AppointmentList />,
        },
        {
          path: "videoconferencia",
          handle: {
            crumb: "Videoconferência",
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "historico",
          handle: {
            crumb: "Histórico",
          } as Handle,
          element: <HistoryList />,
        },
        {
          path: "avaliacoes",
          handle: {
            crumb: "Avaliações",
          } as Handle,
          element: <RatingList />,
        },
        {
          path: "assinaturas",
          handle: {
            crumb: "Assinaturas",
          } as Handle,
          element: <SignatureList />,
        },
      ],
    },
  ],
};
