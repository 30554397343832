import { PreventiveCommonSchema } from "@/shared/modules/system/modules/detail/modules/subsystem/modules/create/schemas";
import { z } from "@/shared/utils";

export const PreventiveCreateSchema = z.object({
  ...PreventiveCommonSchema.shape,
  subsistemaId: z.number().int().nullish(),
  produtoId: z.number().int().nullish(),
});

export type PreventiveCreate = z.infer<typeof PreventiveCommonSchema>;
