import {
  ActivityCard,
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
  StepDot,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteHistory } from "@/shared/hooks/api";

import { HistoryCardContent } from "../components";

export function HistoryList() {
  const { serviceId } = useRequiredParams();

  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteHistory({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
      <span className="text-base font-bold text-black-2">Histórico</span>
      <Separator />
      <div className="flex flex-col pb-6">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((history) => (
            <StepDot
              key={history.id}
              ref={history.id === flatData.length - 1 ? ref : null}
            >
              <ActivityCard style={{ background: "#2F80ED33" }}>
                <HistoryCardContent
                  author={history.usuario}
                  status={history.status}
                  date={history.criadoEm}
                />
              </ActivityCard>
            </StepDot>
          ))}
        </InfiniteQueryHandler>
      </div>
    </AnimatedContainer>
  );
}
