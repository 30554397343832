import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormSelectWorkerTypeInput,
  FormTextInput,
  Modal,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useMutationWorkerCreate } from "@/shared/hooks/api";
import { handleApiError } from "@/shared/utils";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { WorkerCreate, WorkerCreateSchema } from "../schemas";

export interface WorkerCreateModalProps
  extends Omit<ModalOverlayProps, "children"> {
  companyId: number;
}

export function WorkerCreateModal({
  companyId,
  ...props
}: WorkerCreateModalProps) {
  const workerCreateMutation = useMutationWorkerCreate({
    companyId,
    onSuccess: () => {
      toastQueue.add(
        {
          type: "success",
          message:
            "Sucesso ao adicionar um usuário administrativo da construtora",
        },
        {
          timeout: 5000,
        },
      );
      props.onOpenChange?.(false);
    },
    onError: (e) => {
      handleApiError({ error: e });
    },
  });

  const { formState, handleSubmit, control, reset } = useForm<WorkerCreate>({
    resolver: zodResolver(WorkerCreateSchema),
    defaultValues: { construtoraId: companyId },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar usuário administrativo"
        data-cy="worker-modal"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              showSpinner={workerCreateMutation.isPending}
              onPress={() =>
                handleSubmit((values) =>
                  workerCreateMutation.mutateAsync(values),
                )()
              }
            >
              Adicionar
            </Button>
          </>
        )}
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 p-1">
          <FormSelectWorkerTypeInput control={control} name="papelId" />
          <FormTextInput control={control} label="Nome" name="nome" />
          <FormTextInput
            control={control}
            label="Email"
            name="email"
            type="email"
          />
          <FormTextInput
            control={control}
            mask={cpfMask}
            label="CPF"
            name="cpf"
          />
          <FormTextInput
            control={control}
            mask={phoneMask}
            label="Telefone"
            name="telefone"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
