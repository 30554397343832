import { Card, Skeleton } from "@/shared/components";

export function FileListLoading() {
  return (
    <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
      <Skeleton className="mb-3 h-5 w-24" />
      <div className="flex gap-2">
        <Skeleton className="h-56 w-72 rounded-2xl" />
        <Skeleton className="h-56 w-72 rounded-2xl" />
        <Skeleton className="h-56 w-72 rounded-2xl" />
      </div>
    </Card>
  );
}
