import { Handle, RouteObject } from "react-router";

import { createClientAction } from "@/modules/clients/actions";
import { ClientCreate } from "@/modules/clients/modules/create/containers";
import { ClientData } from "@/modules/clients/modules/detail/containers";
import { ClientLayout } from "@/modules/clients/modules/detail/layout";
import { clientEditAction } from "@/modules/clients/modules/edit/actions";
import { ClientEdit } from "@/modules/clients/modules/edit/containers";
import { manualRouter } from "@/modules/manual/router";
import { contactCreateAction } from "@/shared/modules/contact/actions";
import {
  ContactCreate,
  ContactDetail,
  ContactList,
  EditContact,
} from "@/shared/modules/contact/containers";
import { editContactAction } from "@/shared/modules/contact/modules/edit/actions";

import {
  apartmentBlockBulkCreateAction,
  blockCreateAction,
  createDevelopmentAction,
  editDevelopmentAction,
  houseBlockCreateAction,
} from "./actions";
import {
  BlockList,
  ClientsList,
  DevelopmentAppearance,
  DevelopmentBlockBulkCreate,
  DevelopmentBlockCreate,
  DevelopmentCreate,
  DevelopmentData,
  DevelopmentHouseBulkCreate,
  DevelopmentStepCreate,
  DevelopmentStepEdit,
  EditDevelopment,
  StepList,
} from "./containers";
import { DevelopmentDetailLayout } from "./layout";
import { UnitsList } from "./modules/detail/modules/block/modules/detail/containers";
import { unitDetailRouter } from "./modules/detail/modules/block/modules/detail/modules/unit/router";
import { blockDetailRouter } from "./modules/detail/modules/block/modules/detail/router";
import {
  developmentStepCreateAction,
  developmentStepEditAction,
} from "./modules/detail/modules/step/actions";

export const developmentRouter: RouteObject = {
  path: "empreendimentos",
  handle: {
    crumb: "Lista de empreendimentos",
  } as Handle,
  children: [
    {
      path: "cadastrar",
      action: createDevelopmentAction,
      element: <DevelopmentCreate />,
      handle: {
        crumb: "Cadastrar empreendimento",
        action: "create.development",
      } as Handle,
    },
    {
      path: "detalhes/:developmentId",
      handle: {
        crumb: "Detalhes do empreendimento",
        tabBaseUrl: "development",
        action: "get.development",
      } as Handle,
      children: [
        {
          element: <DevelopmentDetailLayout />,
          children: [
            {
              index: true,
              element: <DevelopmentData />,
            },
            {
              path: "aparencia",
              handle: {
                crumb: "Aparência do empreendimento",
                action: "get.development",
              } as Handle,
              element: <DevelopmentAppearance />,
            },
            {
              path: "etapa",
              handle: {
                crumb: "Lista de etapas",
                action: "list.step",
              } as Handle,
              element: <StepList />,
            },
            {
              path: "bloco",
              handle: {
                crumb: "Lista de blocos",
                action: "list.block",
              } as Handle,
              element: <BlockList />,
            },
            {
              path: "cliente",
              handle: {
                crumb: "Lista de clientes",
                action: "list.client",
              } as Handle,
              element: <ClientsList />,
            },
            {
              path: "contatos",
              handle: {
                crumb: "Lista de contatos",
                action: "list.contact",
              } as Handle,
              element: <ContactList />,
            },
          ],
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar empreendimento",
            action: "update.development",
          } as Handle,
          action: editDevelopmentAction,
          element: <EditDevelopment />,
        },
        {
          path: "cliente",
          handle: {
            crumb: "Lista de clientes",
            action: "list.client",
          } as Handle,
          children: [
            {
              path: "detalhes/:clientId",
              handle: {
                crumb: "Detalhes do cliente",
                tabBaseUrl: "client",
                action: "get.client",
              } as Handle,
              children: [
                {
                  element: <ClientLayout />,
                  children: [
                    {
                      index: true,
                      element: <ClientData />,
                    },
                    {
                      path: "unidade",
                      handle: {
                        crumb: "Lista de unidades",
                        action: "list.unit",
                      } as Handle,
                      element: <UnitsList />,
                    },
                  ],
                },
                {
                  path: "editar",
                  handle: {
                    crumb: "Editar cliente",
                    action: "update.client",
                  } as Handle,
                  action: clientEditAction,
                  element: <ClientEdit />,
                },
                {
                  path: "unidade",
                  children: [unitDetailRouter],
                },
              ],
            },
            {
              path: "cadastrar",
              handle: {
                crumb: "Cadastrar cliente",
                action: "create.client",
              } as Handle,
              action: createClientAction,
              element: <ClientCreate />,
            },
          ],
        },
        {
          path: "etapa",
          handle: {
            crumb: "Lista de etapas",
            action: "list.step",
          } as Handle,
          children: [
            {
              path: "cadastrar",
              handle: {
                crumb: "Cadastrar etapa",
                action: "create.step",
              } as Handle,
              action: developmentStepCreateAction,
              element: <DevelopmentStepCreate />,
            },
            {
              path: "editar/:developmentStepId",
              handle: {
                crumb: "Editar etapa",
                action: "update.step",
              } as Handle,
              action: developmentStepEditAction,
              element: <DevelopmentStepEdit />,
            },
          ],
        },
        {
          path: "bloco",
          handle: {
            crumb: "Lista de blocos",
            action: "list.block",
          } as Handle,
          children: [
            {
              path: "cadastrar",
              handle: {
                crumb: "Cadastrar bloco",
                action: "create.block",
              } as Handle,
              action: blockCreateAction,
              element: <DevelopmentBlockCreate />,
            },
            {
              path: "lote/cadastrar/apartamento",
              handle: {
                crumb: "Cadastrar blocos e unidades de apartamentos",
                action: "bulk.create.blockApartment",
              } as Handle,
              action: apartmentBlockBulkCreateAction,
              element: <DevelopmentBlockBulkCreate />,
            },
            {
              path: "lote/cadastrar/casa",
              handle: {
                crumb: "Cadastrar blocos e unidades de casas",
                action: "bulk.create.blockHome",
              } as Handle,
              action: houseBlockCreateAction,
              element: <DevelopmentHouseBulkCreate />,
            },
            blockDetailRouter,
          ],
        },
        {
          path: "contatos",
          handle: {
            crumb: "Lista de contatos",
            action: "list.contact",
          } as Handle,
          children: [
            {
              path: "detalhes/:contactId",
              handle: {
                crumb: "Detalhes do contato",
                action: "get.contact",
              } as Handle,
              children: [
                {
                  index: true,
                  element: <ContactDetail />,
                },
                {
                  path: "editar",
                  handle: {
                    crumb: "Editar contato",
                    action: "update.contact",
                  } as Handle,
                  action: editContactAction,
                  element: <EditContact />,
                },
              ],
            },
            {
              path: "cadastrar",
              handle: {
                crumb: "Cadastrar contato",
                action: "create.contact",
              } as Handle,
              action: contactCreateAction,
              element: <ContactCreate />,
            },
          ],
        },
        manualRouter,
      ],
    },
  ],
};
