import { Navigate } from "react-router";

import { useProfile } from "../hooks/api";

export function RedirectUser() {
  const { data } = useProfile();

  if (data?.papel.tipo === "SeuManual") {
    return <Navigate to="/app/construtoras/" />;
  } else if (data?.papel.tipo === "Construtora") {
    return <Navigate to="/app/construtora/" />;
  }

  return null;
}
