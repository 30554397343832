import { Cell } from "react-aria-components";
import { useParams } from "react-router";

import {
  Am,
  AnimatedContainer,
  Can,
  DetailsCell,
  FilterAndCreate,
  FilterSelectInput,
  PaginationButtons,
  Table,
  TableRow,
} from "@/shared/components";
import { useIsCompanyRole, useSwitch } from "@/shared/hooks";
import { useSystems } from "@/shared/hooks/api";

import { SystemModalAssociate } from "../components";

const clientTypes = [
  { id: "all", label: "Todos", value: undefined },
  { id: "true", label: "Proprietário", value: 1 },
  { id: "false", label: "Síndico", value: 2 },
];

export function SystemList() {
  const { manualId } = useParams();

  const isCompanyRole = useIsCompanyRole();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useSystems({
      manualId: manualId ? +manualId : 0,
      text: "",
      status: isCompanyRole ? true : undefined,
    });
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <Am I="SeuManual">
      {(isSuperUser) => (
        <AnimatedContainer className="space-y-4">
          <FilterAndCreate
            resourceName="sistema"
            onSearchChange={handleSearchChange}
            createOnPress={manualId && isSuperUser ? turnOn : undefined}
            createAction="create.system"
          >
            <FilterSelectInput
              data-cy="filter-select-input"
              label="Tipo do cliente"
              defaultSelectedKey="all"
              options={clientTypes}
              onSelectionChange={(_, tipoId) =>
                updateFilters({
                  tipoId,
                })
              }
            />
          </FilterAndCreate>
          <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
            <div className="overflow-y-auto">
              <Can I="get.system">
                {(allowed) => (
                  <Table
                    aria-label="Lista sistemas"
                    headers={["Nome", "Tipo do cliente"]}
                    rowsData={data?.content}
                    isLoading={isLoading}
                    emptyProps={{
                      resource: "sistema",
                      search: filters.text ?? undefined,
                    }}
                    removeDetailsColumn={!allowed}
                    renderRow={({ id, nome, tipoCliente }) => (
                      <TableRow
                        key={id}
                        href={allowed ? `detalhes/${id}/` : undefined}
                      >
                        <Cell>{nome}</Cell>
                        <Cell>{tipoCliente?.nome}</Cell>
                        {allowed && <DetailsCell />}
                      </TableRow>
                    )}
                  />
                )}
              </Can>
            </div>
            <PaginationButtons
              currentPage={filters.page ?? 1}
              pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
              onPageChange={(page) => updateFilters({ page })}
            />
          </div>

          {manualId && (
            <SystemModalAssociate
              manualId={+manualId}
              isOpen={isOn}
              onOpenChange={setOpen}
              onSuccess={turnOff}
            />
          )}
        </AnimatedContainer>
      )}
    </Am>
  );
}
