import { Building, Video } from "iconsax-react";
import { forwardRef } from "react";

import { ResourceProfileImage, Separator } from "@/shared/components";
import { Vistoria } from "@/shared/services";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

interface AppointmentCardProps {
  appointment: Vistoria;
}

export const AppointmentCard = forwardRef<HTMLDivElement, AppointmentCardProps>(
  ({ appointment }, ref) => {
    return (
      <div
        className="flex flex-col rounded-lg border border-white-3 px-6 py-5"
        ref={ref}
      >
        <span className="text-2xl font-semibold text-gray-10">
          Protocolo - n° {appointment.id}
        </span>
        <Separator className="my-5 border-gray-1" />
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4 text-base font-semibold text-gray-10">
            <span className="text-base font-medium text-info">
              {new Date(appointment.data).toLocaleString("pt-BR", {
                dateStyle: "long",
                timeStyle: "short",
              })}
            </span>
            <span>|</span>
            {appointment.seRemoto ? (
              <div className="flex items-center gap-2">
                <Video variant="Bold" size={20} />
                <span>Online</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Building variant="Bold" size={20} />
                <span>Presencial</span>
              </div>
            )}
          </div>
          <span className="text-sm font-normal text-gray-10">
            {appointment.observacoes}
          </span>
          {appointment.responsavel && (
            <div className="flex flex-col gap-3">
              <span className="text-sm font-semibold text-primary">
                Técnico responsável
              </span>
              <div className="flex gap-4 rounded border border-white-2 p-3">
                <ResourceProfileImage
                  file={appointment?.responsavel.foto}
                  className="size-12 rounded-lg"
                />
                <div className="flex flex-col gap-2">
                  <span className="text-base font-semibold text-gray-12">
                    {appointment.responsavel.nome}
                  </span>
                  <div className="flex gap-4">
                    <span className="text-xs font-medium text-gray-7">
                      E-mail: {""}
                      <span className="font-light">
                        {nullishStringMask(appointment.responsavel.email)}
                      </span>
                    </span>
                    <span className="text-xs font-medium text-gray-7">
                      Telefone: {""}
                      <span className="font-light">
                        {nullishStringMask(
                          phoneMask(
                            appointment.responsavel.telefone ?? undefined,
                          ),
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
