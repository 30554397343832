import { ModalOverlayProps } from "react-aria-components";

import { Dialog, Modal } from "@/shared/components";
import { Faq } from "@/shared/services";
import { sanitize } from "@/shared/utils";

interface FaqModalProps extends ModalOverlayProps {
  faq: Faq;
}

export function FaqModal({ faq, ...props }: FaqModalProps) {
  return (
    <Modal isDismissable {...props}>
      <Dialog className="w-screen max-w-[60vw]" title="FAQ">
        <div className="flex flex-col">
          <span className="mb-4 text-sm font-semibold text-typography-1">
            {faq.nome}
          </span>
          <div
            className="text-sm font-normal text-typography-2"
            dangerouslySetInnerHTML={{
              __html: sanitize(faq.descricao),
            }}
          />
        </div>
      </Dialog>
    </Modal>
  );
}
