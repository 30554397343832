import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormComboBoxUnitTypeInput,
  FormRootError,
  Modal,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationDocumentCreate } from "@/shared/hooks/api";

import {
  DocumentCreateManyFiles,
  DocumentCreateManyFilesSchema,
} from "../schemas";
import { FormDocumentList } from "./form-document-list";

export interface DocumentCreateManyModalProps
  extends Omit<ModalOverlayProps, "children"> {
  manualId?: number;
  unitId?: number;
  paiId?: number;
}

export function DocumentCreateManyModal({
  manualId,
  unitId,
  paiId,
  ...props
}: DocumentCreateManyModalProps) {
  const { developmentId } = useRequiredParams();
  const { formState, handleSubmit, control, reset } =
    useForm<DocumentCreateManyFiles>({
      resolver: zodResolver(DocumentCreateManyFilesSchema),
      values: {
        documentCreationType: "file",
        manualId,
        unidadeId: unitId,
        paiId,
        nome: "",
        link: [],
      },
    });

  const documentCreateMutation = useMutationDocumentCreate({
    onSuccess: () => {
      props.onOpenChange?.(false);
    },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const onSubmit: SubmitHandler<DocumentCreateManyFiles> = async ({
    manualId,
    ...values
  }) => {
    const basePayload = {
      ...values,
      manualId: values.unidadeId ? undefined : manualId,
    };

    let errorCount = 0;

    await Promise.all(
      values.link.map((fileLink) => {
        try {
          documentCreateMutation.mutateAsync({
            ...basePayload,
            nome: fileLink.fileName,
            link: fileLink.key,
          });
        } catch (error) {
          errorCount++;
        }
      }),
    );

    if (errorCount) {
      toastQueue.add(
        {
          type: "error",
          message: `Não foi possível enviar ${errorCount} documentos `,
        },
        { timeout: 2000 },
      );
    }
    toastQueue.add(
      {
        type: "success",
        message: "Sucesso ao adicionar documentos",
      },
      { timeout: 2000 },
    );
  };

  return (
    <Modal {...props}>
      <Dialog
        className="w-[600px]"
        role="alertdialog"
        title="Adicionar novos arquivos"
        onClose={reset}
        renderButtons={({ close }) => (
          <div className="flex justify-between">
            <FormRootError />
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              onPress={() => handleSubmit(onSubmit)()}
              showSpinner={documentCreateMutation.isPending}
            >
              Adicionar
            </Button>
          </div>
        )}
      >
        <form className="flex min-w-96 flex-col gap-4">
          {!unitId && !paiId && (
            <FormComboBoxUnitTypeInput
              control={control}
              name="unidadeId"
              filters={{
                empreendimentoId: +developmentId,
                status: true,
              }}
            />
          )}
          <FormDocumentList control={control} />
        </form>
      </Dialog>
    </Modal>
  );
}
