import {
  AnimatedContainer,
  Breadcrumbs,
  PageTitle,
  QueryHandler,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useSystem } from "@/shared/hooks/api";

import { PreventiveCreateForm } from "../components";

export function PreventiveCreate() {
  const { subsystemId, productId, systemId } = useRequiredParams();

  const systemQuery = useSystem(systemId);

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar manutenção</PageTitle>
        <QueryHandler {...systemQuery}>
          {(data) =>
            data && (
              <PreventiveCreateForm
                redirectTo="../"
                useFormProps={
                  productId
                    ? {
                        defaultValues: {
                          produtoId: +productId,
                          tipoClienteId: data?.tipoId ?? undefined,
                        },
                      }
                    : {
                        defaultValues: {
                          subsistemaId: +subsystemId,
                          tipoClienteId: data?.tipoId ?? undefined,
                        },
                      }
                }
              />
            )
          }
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
