import { Add, ArrowRight2 } from "iconsax-react";
import { DialogTrigger, Link } from "react-aria-components";
import { useParams } from "react-router";

import { DevelopmentManuals } from "@/modules/companies/modules/developments/components";
import { ManualFormModal } from "@/modules/manual/components";
import {
  Am,
  Button,
  Can,
  Card,
  ResourceProfileImage,
  UnauthorizedModal,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useMutationManualCreate } from "@/shared/hooks/api";
import { EmpreendimentoWithManuais } from "@/shared/services";

interface DevelopmentCardProps {
  development: EmpreendimentoWithManuais;
}

export function DevelopmentCard({ development }: DevelopmentCardProps) {
  const { companyId } = useParams();
  const manualCreateMutation = useMutationManualCreate({
    companyId: companyId ? +companyId : undefined,
    onSuccess: () => {
      toastQueue.add(
        {
          type: "success",
          message: "Sucesso ao adicionar manual",
        },
        { timeout: 5000 },
      );
    },
  });

  const develomentDetailLink = companyId
    ? `/app/construtoras/detalhes/${companyId}/empreendimentos/detalhes/${development.id}/`
    : `/app/empreendimentos/detalhes/${development.id}/`;

  return (
    <Card className="space-y-6 px-8 py-6 shadow-header">
      <div className="flex justify-between">
        <Can I="get.development">
          {(allowed) => (
            <Link
              className="group flex items-center gap-4 text-black-2"
              href={allowed ? develomentDetailLink : undefined}
            >
              <ResourceProfileImage size="small" file={development.foto} />
              <h4 className="text-h4 font-semibold">{development.nome}</h4>
              {allowed && (
                <ArrowRight2 className="transition-all group-hover:translate-x-1" />
              )}
            </Link>
          )}
        </Can>
        <Can I="create.manual">
          <DialogTrigger>
            <Button className="flex gap-4" styleType="default">
              <Add />
              Adicionar manual
            </Button>
            <Am I="SeuManual">
              <ManualFormModal
                useFormProps={{
                  defaultValues: { empreendimentoId: development.id },
                }}
                onSubmit={async (values, close) => {
                  manualCreateMutation.mutateAsync(
                    {
                      ...values,
                      link: values.link ? values.link[0].key : null,
                    },
                    {
                      onSuccess: close,
                    },
                  );
                }}
              />
            </Am>
            <Am I="Construtora">
              <UnauthorizedModal />
            </Am>
          </DialogTrigger>
        </Can>
      </div>
      <Can I="list.manual">
        <DevelopmentManuals
          from="list"
          manuals={development.manuais}
          developmentId={development.id.toString()}
        />
      </Can>
    </Card>
  );
}
