import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormFileInput,
  FormProps,
  FormRichTextInput,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { megabyte, z } from "@/shared/utils";

import { FormTargetAudienceInput } from ".";

interface FaqCreateFormProps
  extends Omit<
    FormProps<typeof FaqCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export const FaqCreateSchema = z.object({
  publicoAlvo: z.enum(["cliente", "construtora"]),
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().min(1, "Obrigatório"),
  videoUrl: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
});

export function FaqCreateForm({
  redirectTo,
  ...formProps
}: FaqCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={FaqCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={redirectTo} />
            <div className="space-y-4">
              <FormTargetAudienceInput control={control} name="publicoAlvo" />
              <FormTextInput
                control={control}
                label="Enunciado da pergunta"
                name="nome"
              />
              <FormRichTextInput
                control={control}
                label="Resposta"
                name="descricao"
              />
              <FormFileInput
                control={control}
                name="videoUrl"
                label="Vídeo"
                acceptedFileTypes={["video/mp4"]}
                maxFileSize={100 * megabyte}
              />
            </div>
            <Separator />
            <div className="flex justify-end">
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
