import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormNumberInput,
  FormSelectTimeUnitInput,
  FormTextInput,
  Modal,
} from "@/shared/components";
import {
  WarrantyFailureCommon,
  WarrantyFailureCommonSchema,
} from "@/shared/modules/warranty/containers";

interface AddWarrantyFailureModalProps
  extends Omit<ModalOverlayProps, "children"> {
  onAdd: (value: WarrantyFailureCommon) => void;
  useFormProps?: UseFormProps<WarrantyFailureCommon>;
}

export function AddWarrantyFailureModal({
  onAdd,
  useFormProps,
  ...props
}: AddWarrantyFailureModalProps) {
  const form = useForm<WarrantyFailureCommon>({
    resolver: zodResolver(WarrantyFailureCommonSchema),
    ...useFormProps,
    defaultValues: useFormProps?.defaultValues ?? {},
  });
  const { formState, handleSubmit, control, reset } = form;
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar falha"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              onPress={() => handleSubmit(onAdd)()}
              data-cy="failure-modal-submit"
            >
              Adicionar
            </Button>
          </>
        )}
        data-cy="failure-modal"
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 px-1 pb-4">
          <FormTextInput
            control={control}
            className="col-span-2"
            label="Título"
            name="nome"
          />
          <FormNumberInput
            control={control}
            label="Prazo"
            name="prazo"
            minValue={0}
          />
          <FormSelectTimeUnitInput control={control} name="unidadeTempoId" />
        </form>
      </Dialog>
    </Modal>
  );
}
