import { Separator } from "../separator";

interface KanbanCardFooterProps extends React.PropsWithChildren {
  color: string;
}
export function KanbanCardFooter({ color, children }: KanbanCardFooterProps) {
  return (
    <>
      {children && (
        <Separator style={{ borderColor: color }} className="my-1.5" />
      )}
      <div className="flex gap-2">{children}</div>
    </>
  );
}
