import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormFileInput,
  FormRichTextInput,
  FormTextInput,
  Modal,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";

import { FormPreventiveList, FormWarrantyList } from ".";
import { ProductCreate, ProductCreateSchema } from "../schemas";

interface AddProductModalProps extends Omit<ModalOverlayProps, "children"> {
  onAdd: (value: ProductCreate) => void;
  useFormProps?: UseFormProps<ProductCreate>;
}

export function AddProductModal({
  onAdd,
  useFormProps,
  ...props
}: AddProductModalProps) {
  const { formState, handleSubmit, control, reset } = useForm<ProductCreate>({
    resolver: zodResolver(ProductCreateSchema),
    ...useFormProps,
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar produto"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button className="px-7 py-3" onPress={() => handleSubmit(onAdd)()}>
              Adicionar
            </Button>
          </>
        )}
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 px-1 pb-4">
          <FormTextInput
            control={control}
            label="Marca ou Fabricante"
            name="marca"
          />
          <FormTextInput control={control} label="Nome" name="nome" />
          <FormTextInput
            control={control}
            className="col-span-2"
            label="Modelo"
            name="modelo"
          />
          <FormTextInput
            control={control}
            className="col-span-2"
            label="Link externo para manual"
            name="linkManualExterno"
          />
          <FormFileInput
            control={control}
            className="col-span-2"
            name="notaFiscal"
            label="Nota fiscal"
            acceptedFileTypes={["application/pdf"]}
            maxFileSize={50 * megabyte}
          />
          <FormRichTextInput
            control={control}
            className="col-span-2"
            label="Descrição"
            name="descricao"
          />
          <FormRichTextInput
            control={control}
            className="col-span-2"
            label="Cuidados de uso"
            name="cuidados"
          />
          <FormRichTextInput
            control={control}
            className="col-span-2"
            label="Perdas de garantia"
            name="perdas"
          />
          <FormWarrantyList control={control} className="col-span-2" />
          <FormPreventiveList control={control} className="col-span-2" />
        </form>
      </Dialog>
    </Modal>
  );
}
