import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { SubsystemCreate } from "../schemas";

export async function subsystemCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<SubsystemCreate & { redirectTo: string }>(
    request,
    async ({
      redirectTo,
      faqs,
      garantias,
      manutencoes,
      produtos,
      memoriais,
      ...subsistema
    }) => {
      await apiProvider.services.AcopladosService.postSubsistemaCompletoAcopladosSubsistemaPost(
        {
          requestBody: {
            subsistema: {
              ...subsistema,
              memorialIds: memoriais.map((memorial) => memorial.id),
            },
            faqs: faqs.map((faq) => ({
              ...faq,
              seCliente: true,
              seConstrutora: false,
            })),
            garantias: garantias.map(({ falhas, ...garantia }) => ({
              garantia,
              falhas,
            })),
            preventivos: manutencoes,
            produtos: produtos.map(
              ({ manutencoes, garantias, ...produto }) => ({
                produto: {
                  ...produto,
                  notaFiscal: produto.notaFiscal[0]?.key,
                },
                garantias: garantias.map(({ falhas, ...garantia }) => ({
                  garantia,
                  falhas,
                })),
                preventivos: manutencoes,
              }),
            ),
          },
        },
      );

      toastQueue.add(
        { type: "success", message: "Subsistema criado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
