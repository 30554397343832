import {
  AnimatedContainer,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationWarrantyDelete,
  useMutationWarrantyUpdate,
  useWarranty,
} from "@/shared/hooks/api";

import { WarrantyDetailError, WarrantyDetailLoading } from "../components";

export function WarrantyDetail() {
  const { warrantyId } = useRequiredParams();
  const warrantyQuery = useWarranty(warrantyId);
  const mutationWarrantyUpdate = useMutationWarrantyUpdate();
  const mutationWarrantyDelete = useMutationWarrantyDelete();

  return (
    <AnimatedContainer>
      <div className="space-y-4">
        <QueryHandler
          {...warrantyQuery}
          renderLoading={WarrantyDetailLoading}
          renderError={(error) => <WarrantyDetailError error={error} />}
        >
          {(data) => (
            <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
              <div className="flex justify-between gap-2">
                <div className="flex items-center gap-2">
                  <PageTitle primary>{data?.nome}</PageTitle>
                  <Can I="update.preventive">
                    <EditLink />
                  </Can>
                  <Can I="delete.warranty">
                    <DeleteResourceButton
                      resource="garantia"
                      subject={data?.nome ?? ""}
                      action={() =>
                        mutationWarrantyDelete.mutateAsync({
                          id: +warrantyId,
                        })
                      }
                      showSpinner={mutationWarrantyDelete.isPending}
                    />
                  </Can>
                </div>
                <Can I="update.preventive">
                  <SwitchStatusButton
                    isDisabled={mutationWarrantyUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationWarrantyUpdate.mutateAsync({
                        id: +warrantyId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              </div>
            </Card>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
