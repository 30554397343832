import { PlayCircle } from "iconsax-react";
import { useState } from "react";

import {
  S3Image,
  Slider,
  SliderContent,
  VideoPlayer,
} from "@/shared/components";
import { ManutencaoArquivo } from "@/shared/services";

interface FileListContainerProps {
  files: ManutencaoArquivo[];
}
export function FileListContainer({ files }: FileListContainerProps) {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  return (
    <div className="grid grid-cols-4 gap-3">
      {files.map((file, index) =>
        file.seImagem ? (
          <div
            key={file.id}
            onClick={() => {
              setCarouselOpen(true);
              setCarouselIndex(index);
            }}
          >
            <S3Image
              file={file.arquivo}
              className="h-56 w-72 cursor-pointer rounded-md"
              emptyIcon="default"
            />
          </div>
        ) : (
          <div
            key={file.id}
            className="relative h-56 w-72 cursor-pointer justify-center rounded-md bg-primary-light-1 text-primary"
            onClick={() => {
              setCarouselOpen(true);
              setCarouselIndex(index);
            }}
          >
            <VideoPlayer
              className="h-full w-full rounded-lg"
              url={file.arquivo}
              controls={false}
            />
            <PlayCircle
              variant="Bold"
              size={32}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white-0"
            />
          </div>
        ),
      )}
      <Slider
        total={files.length}
        isOpen={carouselOpen}
        onClose={() => setCarouselOpen(false)}
        selectedIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
      >
        {files.map((file) => (
          <SliderContent file={file} key={file.id} />
        ))}
      </Slider>
    </div>
  );
}
