import { Separator } from "react-aria-components";

import { Skeleton } from "@/shared/components";

export function UnitDetailLoading() {
  return (
    <>
      <Skeleton className="my-4 h-5 w-1/2" />
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <div className="flex justify-center">
            <Skeleton className="mr-2 h-4 w-24" />
            <Skeleton className="mr-2 h-4 w-24" />
            <div className="flex gap-6 text-xs text-primary">
              <Skeleton className="h-4 w-24" />
            </div>
          </div>
        </div>
      </div>
      <Separator className="text-white-3" />
      <Skeleton className="mb-3 h-5 w-24" />
      <Skeleton className="h-20 w-full rounded-2xl" />
    </>
  );
}
