import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { deserializeDisponibilidade } from "@/shared/components/form/availability/serialization";
import { useCompany, useProfile } from "@/shared/hooks/api";

import { AssistanceConfigAvailabilityForm } from "../components/assistance-config-availability-form";

export function AssistanceConfigAvailability() {
  const profileQuery = useProfile({ refetchOnMount: false });
  const companyQuery = useCompany(profileQuery.data?.construtoraId?.toString());

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Configurar disponibilidade de agenda</PageTitle>
      </div>
      <QueryHandler
        {...companyQuery}
        renderLoading={() => <Spinner />}
        renderError={() => <ErrorContainer />}
      >
        {(data) =>
          profileQuery.data?.construtoraId ? (
            <AssistanceConfigAvailabilityForm
              redirectTo="../"
              useFormProps={{
                defaultValues: {
                  construtoraId: profileQuery.data?.construtoraId,
                  duracaoMinutosReuniao:
                    data?.company.duracaoMinutosReuniao ?? 60,
                  disponibilidade: deserializeDisponibilidade(
                    data?.company.disponibilidade,
                  ),
                },
              }}
            />
          ) : (
            <ErrorContainer />
          )
        }
      </QueryHandler>
    </AnimatedContainer>
  );
}
