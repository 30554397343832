import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { DevelopmentDocumentsInfoForm } from "./development-contract-form";
import { DevelopmentDataForm } from "./development-data-form";
import { DevelopmentModuleDataForm } from "./development-module-data-form";
import { DevelopmentStyleForm } from "./development-style-form";

export interface DevelopmentFormWizardProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
> extends Pick<
    FormWizardProps<TSchema, TSubmitSchema>,
    "useFormProps" | "formSchema" | "submitSchema" | "onSuccessRedirect"
  > {}

export const DevelopmentCreateFormSteps = [
  DevelopmentDataForm,
  DevelopmentModuleDataForm,
  DevelopmentStyleForm,
  DevelopmentDocumentsInfoForm,
] as const;

export function DevelopmentFormWizard<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
>(props: DevelopmentFormWizardProps<TSchema, TSubmitSchema>) {
  return (
    <FormWizard
      {...props}
      steps={[
        {
          heading: {
            head: "Dados do empreendimento",
          },
          element: <DevelopmentDataForm />,
        },
        {
          heading: {
            head: "Dados dos módulos",
          },
          element: <DevelopmentModuleDataForm />,
        },
        {
          heading: {
            head: "Conf. de Identidade Visual",
          },
          element: <DevelopmentStyleForm />,
        },
        {
          heading: {
            head: "Arquivos dos documentos",
          },
          element: <DevelopmentDocumentsInfoForm />,
        },
      ]}
    />
  );
}
