import { GallerySlash } from "iconsax-react";

import { Skeleton } from ".";
import { useFile } from "../hooks/api";

export type S3VideoClassNameTypes = "spinner" | "video" | "empty";

export type S3VideoClassName =
  | ((type: S3VideoClassNameTypes) => string | undefined)
  | string;

export type S3VideoStyle = (
  type: S3VideoClassNameTypes,
) => React.CSSProperties | undefined;

export interface S3VideoProps {
  className?: S3VideoClassName;
  file?: string | null;
  emptyIcon?: "default";
  style?: S3VideoStyle;
  renderLoading?: () => React.ReactElement;
}

const computedClassName = (
  type: S3VideoClassNameTypes,
  className?: S3VideoClassName,
) => {
  return typeof className === "string" ? className : className?.(type);
};

const icons = {
  default: GallerySlash,
} as const;

export function S3Video({
  file,
  className,
  emptyIcon,
  renderLoading,
  style,
}: S3VideoProps) {
  const videoQuery = useFile(file);

  if (videoQuery.isFetching && !renderLoading) {
    return (
      <Skeleton
        className={computedClassName("spinner", className)}
        style={style?.("spinner")}
      />
    );
  }

  if (videoQuery.isFetching && renderLoading) {
    return renderLoading();
  }

  if (videoQuery.data?.url) {
    return (
      <video
        className={computedClassName("video", className)}
        style={style?.("video")}
        src={videoQuery.data.url}
        controls
      />
    );
  }

  const Icon = icons[emptyIcon ?? "default"];
  return (
    <Icon
      className={computedClassName("empty", className)}
      style={style?.("empty")}
      variant="Bold"
    />
  );
}
