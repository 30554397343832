import {
  Breadcrumbs,
  Can,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  S3Image,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationPostDelete,
  useMutationUpdateManualPost,
  usePost,
} from "@/shared/hooks/api";
import { cn, sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { PostDetailError, PostDetailLoading } from "../components";

export function PostDetail() {
  const { postId } = useRequiredParams();
  const postQuery = usePost(postId);
  const mutationPostUpdate = useMutationUpdateManualPost();
  const mutationPostDelete = useMutationPostDelete();

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <QueryHandler
        {...postQuery}
        renderLoading={PostDetailLoading}
        renderError={(error) => <PostDetailError error={error} />}
      >
        {(data) => (
          <>
            <div className="space-y-4">
              <Breadcrumbs />
            </div>
            <div className="flex flex-col">
              <div className="flex items-center justify-between gap-2">
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <PageTitle primary className="break-all">
                      {data?.titulo}
                    </PageTitle>
                    <Can I="update.post">
                      <EditLink />
                    </Can>
                    <Can I="delete.post">
                      <DeleteResourceButton
                        resource="post"
                        subject={data?.titulo ?? ""}
                        action={() =>
                          mutationPostDelete.mutateAsync({
                            id: +postId,
                          })
                        }
                        showSpinner={mutationPostDelete.isPending}
                      />
                    </Can>
                  </div>
                </div>
                <Can I="update.post">
                  <SwitchStatusButton
                    className="self-start"
                    isDisabled={mutationPostUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationPostUpdate.mutateAsync({
                        id: +postId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              </div>
              <p className="text-xs text-primary">
                Categoria:{" "}
                <span className="font-semibold">
                  {nullishStringMask(data?.categoria)}
                </span>
              </p>
            </div>
            <div className="space-y-4">
              {data?.capa ? (
                <S3Image
                  className={(type) =>
                    cn("max-w-100 max-h-56 rounded-lg", {
                      "object-cover": type === "img",
                    })
                  }
                  file={data.capa}
                />
              ) : (
                <img
                  className="max-w-100 max-h-56 object-cover"
                  src="/default-manual-post-cover.png"
                />
              )}
              <div
                className="tiptap text-black-0"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data?.texto ?? ""),
                }}
              />
            </div>
          </>
        )}
      </QueryHandler>
    </div>
  );
}
