import { Link as LinkIcon } from "iconsax-react";
import { Link } from "react-aria-components";

import {
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  QueryHandler,
  ResourceProfileImage,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useCompany,
  useMutationCompanyDelete,
  useMutationCompanyUpdate,
} from "@/shared/hooks/api";
import { pluralize } from "@/shared/utils";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  nullishStringMask,
  phoneMask,
} from "@/shared/utils/masks";

import { CompanyDetailDataError } from "./company-detail-data-error";
import { CompanyDetailDataLoading } from "./company-detail-data-loading";
import { CompanyDetailUsers } from "./company-detail-users";

export interface CompanyDetailDataProps {
  id?: string;
}

export function CompanyDetailData({ id }: CompanyDetailDataProps) {
  const { companyId: paramCompanyId } = useRequiredParams();
  const companyId = id ?? paramCompanyId;

  const companyQuery = useCompany(companyId);

  const mutationCompanyUpdate = useMutationCompanyUpdate();
  const mutationCompanyDelete = useMutationCompanyDelete();

  return (
    <QueryHandler
      {...companyQuery}
      renderLoading={CompanyDetailDataLoading}
      renderError={(error) => <CompanyDetailDataError error={error} />}
    >
      {(data) => (
        <>
          <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
            <div className="flex items-center gap-4">
              <div>
                <ResourceProfileImage file={data?.company.foto} />
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-2">
                      <p className="black-2 break-all font-bold">
                        {data?.company.nome}
                      </p>
                      <Can I="update.company">
                        <EditLink />
                      </Can>
                      <Can I="delete.company">
                        <DeleteResourceButton
                          resource="construtora"
                          subject={data?.company.nome ?? ""}
                          action={() =>
                            mutationCompanyDelete.mutateAsync({
                              id: +companyId,
                            })
                          }
                          showSpinner={mutationCompanyDelete.isPending}
                        />
                      </Can>
                    </div>
                  </div>
                  <Can I="update.company">
                    <SwitchStatusButton
                      isDisabled={mutationCompanyUpdate.isPending}
                      isSelected={data?.company.flagAtivo}
                      onChange={(status) =>
                        mutationCompanyUpdate.mutateAsync({
                          id: +companyId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                </div>
                <div className="flex flex-wrap gap-6 text-xs text-primary">
                  <p className="flex gap-1">
                    CNPJ:
                    <span className="font-semibold">
                      {cnpjMask(data?.company.cnpj)}
                    </span>
                  </p>
                  <p className="flex gap-1">
                    E-mail:
                    <span className="font-semibold">
                      {nullishStringMask(data?.company.email)}
                    </span>
                  </p>

                  <p className="flex gap-1">
                    Telefone:
                    <span className="font-semibold">
                      {nullishStringMask(
                        phoneMask(data?.company.telefone ?? undefined),
                      )}
                    </span>
                  </p>
                  <p className="flex gap-1">
                    Site:
                    <Link
                      className="flex items-center gap-1 font-bold text-info"
                      href={data?.company.site ?? undefined}
                      target="_blank"
                    >
                      {data?.company.site && <LinkIcon size="1rem" />}
                      {nullishStringMask(data?.company.site)}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <Separator />
            <p className="mb-3 text-sm text-black-2">Responsável</p>
            <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
              <div>
                <p>Nome</p>
                <p className="font-bold text-primary">
                  {data?.company.responsavel?.nome}
                </p>
              </div>
              <div>
                <p>Telefone</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(
                    phoneMask(data?.company.responsavel?.telefone ?? undefined),
                  )}
                </p>
              </div>
              <div>
                <p>CPF</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(
                    cpfMask(data?.company.responsavel?.cpf ?? undefined),
                  )}
                </p>
              </div>
              <div>
                <p>Número de empreendimentos</p>
                <p className="font-bold text-primary">
                  {`${data?.developmentCount} ${pluralize("empreendimento", data?.developmentCount ?? 0, "s")}`}
                </p>
              </div>
            </div>
            <Separator />
            <p className="mb-3 text-sm text-black-2">Localização</p>
            <div className="flex items-center justify-between rounded-2xl border border-white-2 px-8 py-6">
              <div className="text-sm text-gray-6">
                <p>Endereço</p>
                <p className="font-semibold text-gray-12">
                  {nullishStringMask(
                    [
                      data?.company.rua,
                      data?.company.numero?.toString(),
                      cepMask(data?.company.cep ?? undefined),
                      data?.company.bairro,
                      [data?.company.cidade, data?.company.estado]
                        .filter(Boolean)
                        .join(" - "),
                    ]
                      .filter(Boolean)
                      .join(", "),
                  )}
                </p>
              </div>
            </div>
          </Card>
          <Can I="list.worker">
            <CompanyDetailUsers companyId={companyId} />
          </Can>
        </>
      )}
    </QueryHandler>
  );
}
