import { Handle, RouteObject } from "react-router";

import { DocumentList } from "@/shared/modules/document/containers";
import {
  memorialCreateAction,
  memorialEditAction,
} from "@/shared/modules/memorial/actions";
import {
  MemorialCreate,
  MemorialDetail,
  MemorialEdit,
  MemorialList,
} from "@/shared/modules/memorial/containers";
import {
  postCreateAction,
  postEditAction,
} from "@/shared/modules/post/actions";
import {
  PostCreate,
  PostDetail,
  PostEdit,
  PostList,
} from "@/shared/modules/post/containers";
import { systemCreateAction } from "@/shared/modules/system/actions";
import { SystemCreate, SystemList } from "@/shared/modules/system/containers";
import { systemDetailRouter } from "@/shared/modules/system/modules/detail/router";

import { editTopicAction, topicCreateAction } from "./actions";
import {
  ManualData,
  ManualDetailLayout,
  ManualLink,
  ManualTopicCreate,
  ManualTopicDetail,
  ManualTopicEdit,
  ManualTopicList,
} from "./containers";

export const manualRouter: RouteObject = {
  path: "manuais/:manualId",
  handle: {
    tabBaseUrl: "manual",
    action: "get.manual",
  } as Handle,
  children: [
    {
      element: <ManualDetailLayout />,
      children: [
        {
          index: true,
          handle: {
            crumb: "Detalhes do manual",
          } as Handle,
          element: <ManualData />,
        },
        {
          path: "post",
          handle: {
            crumb: "Lista de posts",
            action: "list.post",
          } as Handle,
          element: <PostList />,
        },
        {
          path: "ambiente",
          handle: {
            crumb: "Lista de ambientes",
            action: "list.memorial",
          } as Handle,
          element: <MemorialList />,
        },
        {
          path: "arquivos",
          handle: {
            crumb: "Lista de arquivos",
            action: "list.document",
          } as Handle,
          element: <DocumentList />,
        },
        {
          path: "topico",
          handle: {
            crumb: "Lista de tópicos",
            action: "list.topic",
          } as Handle,
          element: <ManualTopicList />,
        },
        {
          path: "sistema",
          handle: {
            crumb: "Lista de sistemas",
            action: "list.system",
          } as Handle,
          element: <SystemList />,
        },
        {
          path: "link",
          handle: {
            crumb: "Link público do manual",
            action: "get.manual",
          } as Handle,
          element: <ManualLink />,
        },
      ],
    },
    {
      path: "sistema",
      handle: {
        crumb: "Lista de sistemas",
        action: "list.system",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar sistema",
            action: "create.system",
          } as Handle,
          action: systemCreateAction,
          element: <SystemCreate />,
        },
        systemDetailRouter,
      ],
    },
    {
      path: "ambiente",
      handle: {
        crumb: "Lista de ambientes",
        action: "list.memorial",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar ambiente",
            action: "create.memorial",
          } as Handle,
          action: memorialCreateAction,
          element: <MemorialCreate />,
        },
        {
          path: "detalhes/:memorialId",
          handle: {
            crumb: "Detalhes do ambiente",
            actions: "get.memorial",
          } as Handle,
          children: [
            {
              index: true,
              element: <MemorialDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar ambiente",
                action: "update.memorial",
              } as Handle,
              action: memorialEditAction,
              element: <MemorialEdit />,
            },
          ],
        },
      ],
    },
    {
      path: "post",
      handle: {
        crumb: "Lista de posts",
        action: "list.post",
      } as Handle,
      children: [
        {
          path: "detalhes/:postId",
          handle: {
            crumb: "Detalhes do post",
            action: "get.post",
          } as Handle,
          children: [
            {
              index: true,
              element: <PostDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar post",
                action: "update.post",
              } as Handle,
              action: postEditAction,
              element: <PostEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar post",
            action: "create.post",
          } as Handle,
          action: postCreateAction,
          element: <PostCreate />,
        },
      ],
    },
    {
      path: "topico",
      handle: {
        crumb: "Lista de tópicos",
        action: "list.topic",
      } as Handle,
      children: [
        {
          path: "detalhes/:topicId",
          handle: {
            crumb: "Detalhes do tópico",
            action: "get.topic",
          } as Handle,
          children: [
            {
              index: true,
              element: <ManualTopicDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar tópico",
                action: "update.topic",
              } as Handle,
              action: editTopicAction,
              element: <ManualTopicEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar tópico",
            action: "create.topic",
          } as Handle,
          action: topicCreateAction,
          element: <ManualTopicCreate />,
        },
      ],
    },
  ],
};
