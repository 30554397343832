import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { ContactCreate } from "../../create/schemas";

export async function editContactAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<Partial<ContactCreate> & { redirectTo: string }>(
    request,
    async ({ redirectTo, logo, tipo, ...payload }) => {
      const empreendimentoId = params.developmentId
        ? +params.developmentId
        : undefined;
      await apiProvider.services.ContatosService.updateObjContatosIdPut({
        id: +params.contactId!,
        requestBody: {
          logo: logo ? logo[0].key : null,
          tipoContatoId: tipo,
          ...payload,
          empreendimentoId,
        },
      });

      toastQueue.add(
        { type: "success", message: "Contato atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
