import { CloseCircle } from "iconsax-react";
import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Modal, S3Image } from "@/shared/components";

interface SignatureFullScreenModalProps
  extends Omit<ModalOverlayProps, "children"> {
  assinaturaUrl: string;
  onClose: () => void;
}

export function SignatureFullScreenModal({
  assinaturaUrl,
  onClose,
  ...props
}: SignatureFullScreenModalProps) {
  return (
    <Modal isDismissable {...props} styleType="fullscreen">
      <AriaButton
        onPress={onClose}
        data-cy="close-modal"
        className="fixed right-12 top-4"
      >
        <CloseCircle className="text-gray-3" />
      </AriaButton>

      <TransformWrapper initialScale={1}>
        <TransformComponent>
          <S3Image
            file={assinaturaUrl}
            className="max-h-[calc(100vh-1rem)] max-w-[calc(100vw-1rem)]"
          />
        </TransformComponent>
      </TransformWrapper>
    </Modal>
  );
}
