import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { MemorialCreate, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function memorialCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<MemorialCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      await apiProvider.services.MemoriaisService.createObjMemorialPost({
        requestBody,
      });

      toastQueue.add(
        { type: "success", message: "Ambiente criado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
