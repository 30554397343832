import { Time } from "@internationalized/date";

import { z } from "@/shared/utils";

export const intervalSchema = z.object({
  start: z.instanceof(Time),
  end: z.instanceof(Time),
});

export type Interval = z.infer<typeof intervalSchema>;

export const intervalsSchema = z.array(intervalSchema);

export const weekIntervalSchema = z.array(intervalsSchema.nullable()).length(7);
