import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { useObjReducer } from "@/shared/hooks";
import { apiProvider } from "@/shared/services";

export function useInfiniteProducts() {
  const trigger = useIntersectionObserver({
    threshold: 0.5,
  });

  const [filters, updateFilters] = useObjReducer<{ text?: string }>({});

  const query = useInfiniteQuery({
    queryKey: ["productsInfinite", filters],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => {
      return apiProvider.services.ProdutosService.getObjsProdutoGet({
        page: pageParam,
        subsistemaId: 0,
        elementsPerPage: PAGE_COUNT,
        ...filters,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      const apiPages = Math.ceil(lastPage.total / lastPage.elementPerPage);
      return apiPages > pages.length ? pages.length : undefined;
    },
  });

  const { fetchNextPage } = query;
  useEffect(() => {
    if (trigger.isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, trigger.isIntersecting]);

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  const flatData = useMemo(
    () => query.data?.pages.flatMap((p) => p.content),
    [query.data?.pages],
  );

  return [
    { flatData, ...query },
    { filters, updateFilters, handleSearchChange },
    trigger,
  ] as const;
}
