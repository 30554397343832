import { parseDate } from "@internationalized/date";

import {
  Am,
  Can,
  DatePickerInput,
  FilterSelectInput,
  Link,
  PageTitle,
  SearchInput,
  StatusInput,
} from "@/shared/components";
import { useFaqs, useProfile } from "@/shared/hooks/api";

import { FaqTable } from "../components";

const faqTypes = [
  { id: "all", label: "Todos", value: undefined },
  { id: "true", label: "Construtora", value: true },
  { id: "false", label: "Cliente", value: false },
];

export function FaqList() {
  const { data: profileData } = useProfile();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useFaqs(
      profileData?.construtoraId
        ? { seConstrutora: true, text: "", subsistemaId: 0, status: true }
        : {
            status: true,
            text: "",
            subsistemaId: 0,
          },
    );

  return (
    <div className="flex h-full flex-col p-8 animate-in fade-in slide-in-from-left-2">
      <div className="mb-6 flex justify-between">
        <PageTitle primary>FAQ</PageTitle>
        <Am I="SeuManual">
          <Can I="create.faq">
            <div className="flex items-center">
              <Link className="w-auto px-8 py-3" href="cadastrar/">
                Cadastrar pergunta
              </Link>
            </div>
          </Can>
        </Am>
      </div>
      <div className="mb-4 flex flex-wrap gap-3 lg:flex-nowrap">
        <SearchInput className="w-full" onDebounceChange={handleSearchChange} />
        <Am I="SeuManual">
          <FilterSelectInput
            data-cy="filter-select-input"
            label="Público"
            className="flex-grow lg:w-1/4"
            defaultSelectedKey="all"
            options={faqTypes}
            onSelectionChange={(_, seConstrutora) =>
              updateFilters({
                seConstrutora,
              })
            }
          />
          <StatusInput
            className="flex-grow lg:w-1/5"
            onSelectionChange={(status) => updateFilters({ status })}
          />
          <DatePickerInput
            className="flex-grow lg:w-1/5"
            onChange={(data) => updateFilters({ data })}
            onReset={() => updateFilters({ data: undefined })}
            value={filters.data ? parseDate(filters.data) : null}
          />
        </Am>
      </div>
      <FaqTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </div>
  );
}
