import {
  AnimatedContainer,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  Spinner,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useBlock,
  useDevelopment,
  useMutationUnitDelete,
  useMutationUnitUpdate,
  useUnit,
} from "@/shared/hooks/api";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

import { UnitDetailError, UnitDetailLoading } from "../components";

export function UnitDetail() {
  const { unitId } = useRequiredParams();

  const unitQuery = useUnit(unitId);
  const blockQuery = useBlock(unitQuery.data?.blocoId.toString());
  const developmentQuery = useDevelopment(
    unitQuery.data?.empreendimentoId?.toString(),
  );
  const mutationUnitUpdate = useMutationUnitUpdate();
  const mutationUnitDelete = useMutationUnitDelete();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler
          {...unitQuery}
          renderLoading={UnitDetailLoading}
          renderError={(error) => <UnitDetailError error={error} />}
        >
          {(data) => (
            <>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex items-center gap-2">
                    <PageTitle primary>{data?.nome}</PageTitle>
                    <Can I="update.unit">
                      <EditLink />
                    </Can>
                    <Can I="delete.unit">
                      <DeleteResourceButton
                        resource="unidade"
                        subject={data?.nome ?? ""}
                        action={() =>
                          mutationUnitDelete.mutateAsync({
                            id: +unitId,
                          })
                        }
                        showSpinner={mutationUnitDelete.isPending}
                      />
                    </Can>
                  </div>
                  <QueryHandler
                    {...blockQuery}
                    renderLoading={() => <Spinner />}
                  >
                    {(blockData) => (
                      <div className="flex gap-6 text-xs text-primary">
                        <p className="text-xs text-primary">
                          Bloco:{" "}
                          <span className="font-semibold">
                            {nullishStringMask(blockData?.nome)}
                          </span>
                        </p>

                        <QueryHandler
                          {...developmentQuery}
                          renderLoading={() => <Spinner />}
                        >
                          {(developmentData) => (
                            <p>
                              Empreendimento:{" "}
                              <span className="font-semibold">
                                {nullishStringMask(developmentData?.nome)}
                              </span>
                            </p>
                          )}
                        </QueryHandler>
                        <p>
                          Data da entrega:{" "}
                          <span className="font-semibold">
                            {nullishStringMask(
                              data?.dataEntrega
                                ? new Date(
                                    data?.dataEntrega,
                                  ).toLocaleDateString()
                                : undefined,
                            )}
                          </span>
                        </p>
                        <p>
                          Tipo do cliente:{" "}
                          <span className="font-semibold">
                            {nullishStringMask(data?.tipoCliente?.nome)}
                          </span>
                        </p>
                      </div>
                    )}
                  </QueryHandler>
                </div>
                {data && (
                  <Can I="update.unit">
                    <SwitchStatusButton
                      className="self-start"
                      isDisabled={mutationUnitUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationUnitUpdate.mutateAsync({
                          id: +unitId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                )}
              </div>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Dono</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                {data?.dono ? (
                  <>
                    <div>
                      <p>Nome</p>
                      <p className="font-bold text-primary">
                        {data?.dono?.nome}
                      </p>
                    </div>
                    <div>
                      <p>Telefone</p>
                      <p className="font-bold text-primary">
                        {nullishStringMask(
                          phoneMask(data?.dono?.telefone ?? undefined),
                        )}
                      </p>
                    </div>
                    <div>
                      <p>Email</p>
                      <p className="font-bold text-primary">
                        {nullishStringMask(data?.dono?.email ?? undefined)}
                      </p>
                    </div>
                  </>
                ) : (
                  <span>Vazio</span>
                )}
              </div>
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
