import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const SignatureCreatePointsSchema = z.object({
  // HACK: for some reason, z.instanceof(SVGSVGElement) is causing tsc to run forever
  points: z.unknown(),
});

export const SignatureNoticeSchema = z.object({});

export const SignatureCreateSchema = z.object({
  ...SignatureCreatePointsSchema.shape,
  ...SignatureNoticeSchema.shape,
});

export const SignatureNoticeStepSchema = generateStepSchema(
  SignatureNoticeSchema,
  0,
);

export const SignaturePointsStepSchema = generateStepSchema(
  SignatureCreateSchema,
  1,
);

export const SignatureCreateFormSchema = generateMultiStepSchema([
  SignatureNoticeStepSchema,
  SignaturePointsStepSchema,
]);

export type SignaturePoints = z.infer<typeof SignatureCreatePointsSchema>;
export type SignatureNotice = z.infer<typeof SignatureNoticeSchema>;
export type SignatureCreate = z.infer<typeof SignatureCreateSchema>;
