import { Devices, Profile2User } from "iconsax-react";
import { Radio, RadioGroup } from "react-aria-components";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { FieldError } from "@/shared/components";

export interface FormAppointmentTypeInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function FormAppointmentTypeInput<T extends FieldValues>({
  control,
  name,
}: FormAppointmentTypeInputProps<T>) {
  const {
    field: { onBlur, disabled, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  return (
    <RadioGroup
      {...inputProps}
      className="grid grid-cols-2 gap-4"
      aria-label="Tipo de agendamento"
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
    >
      <Radio
        className="flex w-60 flex-col items-center justify-center gap-4 rounded-lg bg-white-3 px-4 py-7 font-bold text-primary transition-all rac-selected:bg-primary-light-2"
        value="in-person"
        onBlur={onBlur}
      >
        <Profile2User variant="Bold" size="2rem" />
        Presencial
      </Radio>
      <Radio
        className="flex w-60 flex-col items-center justify-center gap-4 rounded-lg bg-white-3 px-4 py-7 text-center font-bold text-primary transition-all rac-selected:bg-primary-light-2 rac-disabled:bg-gray-1 rac-disabled:text-gray-5"
        value="remote"
        onBlur={onBlur}
        isDisabled
      >
        <Devices variant="Bold" size="2rem" />
        Vistoria online
        <br />
        (em breve)
      </Radio>
      <FieldError>{error?.message}</FieldError>
    </RadioGroup>
  );
}
