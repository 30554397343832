import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormComboBoxBlockInput,
  FormComboBoxUnitTypeInput,
  FormDateField,
  FormProps,
  FormRootError,
  FormSelectClientTypeInput,
  FormTextInput,
  Separator,
  SetFieldValue,
  TabPanel,
  Tabs,
} from "@/shared/components";
import { useAuthorizedTabs, useSubmitting } from "@/shared/hooks";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { CreateClientSchema } from "../schemas";

interface ClientCreateFormProps
  extends Omit<
    FormProps<typeof CreateClientSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
  empreendimentoId?: number;
}

export function ClientCreateForm({
  redirectTo,
  empreendimentoId,
  ...formProps
}: ClientCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  const tabs = useAuthorizedTabs([
    {
      id: "new-unit",
      label: "Nova Unidade",
      action: "bulk.create.unitWithOwner",
    },
    {
      id: "registered-unit",
      label: "Unidade já cadastrada",
      action: "list.unit",
    },
  ]);

  return (
    <Card className="shadow-header">
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={CreateClientSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-4">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormTextInput
                control={control}
                label="Nome"
                name="cliente.nome"
              />
              <FormTextInput
                control={control}
                label="E-mail"
                name="cliente.email"
              />
              <FormTextInput
                control={control}
                mask={phoneMask}
                label="Telefone"
                name="cliente.telefone"
              />
              <FormTextInput
                control={control}
                mask={cpfMask}
                label="CPF"
                name="cliente.cpf"
              />
              {empreendimentoId && (
                <div className="col-span-2">
                  <Tabs className="bg-transparent px-0 shadow-none" tabs={tabs}>
                    <TabPanel id="new-unit" className="grid grid-cols-2 gap-4">
                      <SetFieldValue
                        control={control}
                        name="unitCreationType"
                        value="new-unit"
                      />
                      <FormTextInput
                        control={control}
                        label="Nome"
                        name="unidade.nome"
                      />
                      <FormDateField
                        control={control}
                        label="Data de entrega"
                        name="unidade.dataEntrega"
                        granularity="day"
                      />
                      <FormComboBoxBlockInput
                        control={control}
                        filters={{
                          empreendimentoId,
                        }}
                        name="unidade.blocoId"
                      />
                      <FormSelectClientTypeInput
                        control={control}
                        name="unidade.tipoClientId"
                      />
                    </TabPanel>
                    <TabPanel id="registered-unit">
                      <SetFieldValue
                        control={control}
                        name="unitCreationType"
                        value="registered-unit"
                      />
                      <FormComboBoxUnitTypeInput
                        control={control}
                        className="col-span-2"
                        name="cliente.unidadeId"
                        filters={{
                          empreendimentoId,
                        }}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              )}
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
