import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Am,
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormComboBoxClientInput,
  FormDateField,
  FormProps,
  FormRootError,
  FormSelectClientTypeInput,
  FormTextInput,
  QueryHandler,
  Separator,
  SetFieldValue,
  TabPanel,
  Tabs,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { useDevelopment } from "@/shared/hooks/api";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { createUnitSchemaFactory } from "../schemas";

interface UnitCreateFormProps
  extends Omit<
    FormProps<ReturnType<typeof createUnitSchemaFactory>>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
  empreendimentoId: number;
}

const tabs = [
  { id: "new-client", label: "Novo cliente" },
  {
    id: "registered-client",
    label: "Cliente já cadastrado",
  },
  { id: "no-client", label: "Sem cliente" },
];

export function UnitCreateForm({
  redirectTo,
  empreendimentoId,
  ...props
}: UnitCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();
  const defaultValues = props.useFormProps?.defaultValues;

  const developmentQuery = useDevelopment(empreendimentoId.toString());

  return (
    <QueryHandler {...developmentQuery}>
      {(data) => (
        <Card className="shadow-header">
          <FieldSetTitle>Dados gerais</FieldSetTitle>
          <Form
            schema={createUnitSchemaFactory(
              data?.dataEntrega
                ? new Date(data?.dataEntrega)
                : new Date("1999-01-01"),
            )}
            validationErrors={formErrors}
            {...props}
          >
            {({ control }) => (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <input type="hidden" name="redirectTo" value={redirectTo} />
                  <FormTextInput
                    control={control}
                    className="col-span-2"
                    label="Nome"
                    name="unidade.nome"
                  />
                  <FormDateField
                    control={control}
                    label="Data de entrega"
                    name="unidade.dataEntrega"
                    granularity="day"
                  />
                  <FormSelectClientTypeInput
                    control={control}
                    name="unidade.tipoClientId"
                  />
                  <div className="col-span-2">
                    <Tabs
                      className="bg-transparent px-0 shadow-none"
                      tabs={tabs}
                      defaultSelectedKey={
                        !(defaultValues instanceof Function)
                          ? defaultValues?.clientCreationType
                          : undefined
                      }
                    >
                      <TabPanel
                        id="new-client"
                        className="grid grid-cols-2 gap-4"
                      >
                        <SetFieldValue
                          control={control}
                          name="clientCreationType"
                          value="new-client"
                        />
                        <FormTextInput
                          control={control}
                          label="Nome"
                          name="responsavel.nome"
                        />
                        <FormTextInput
                          control={control}
                          mask={cpfMask}
                          label="CPF"
                          name="responsavel.cpf"
                        />
                        <FormTextInput
                          control={control}
                          label="E-mail"
                          name="responsavel.email"
                        />
                        <FormTextInput
                          control={control}
                          mask={phoneMask}
                          label="Telefone"
                          name="responsavel.telefone"
                        />
                      </TabPanel>
                      <TabPanel id="registered-client">
                        <SetFieldValue
                          control={control}
                          name="clientCreationType"
                          value="registered-client"
                        />
                        <Am I="SeuManual">
                          {(isSuperUser) => (
                            <FormComboBoxClientInput
                              control={control}
                              className="col-span-2"
                              name="unidade.donoId"
                              filters={
                                isSuperUser
                                  ? {}
                                  : {
                                      empreendimentoId,
                                    }
                              }
                            />
                          )}
                        </Am>
                      </TabPanel>
                      <TabPanel id="no-client">
                        <SetFieldValue
                          control={control}
                          name="clientCreationType"
                          value="no-client"
                        />
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between">
                  <FormRootError />
                  <Button
                    className="px-10 py-3"
                    type="submit"
                    showSpinner={isSubmitting}
                  >
                    Salvar
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Card>
      )}
    </QueryHandler>
  );
}
