import { tv } from "tailwind-variants";

export const cellButtonStyles = tv({
  base: "cell mb-1 flex min-w-32 cursor-default justify-center rounded-md border py-2 font-bold text-gray-10",
  variants: {
    isSelected: {
      false: "border-white-3",
      true: "border-primary bg-primary-light-2 text-primary",
    },
    isDisabled: {
      true: "",
    },
    isUnavailable: {
      true: "",
    },
  },
});
