import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { type RedefinirSenhaEmail, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function forgotPasswordAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<RedefinirSenhaEmail>(request, async (payload) => {
    const { message } =
      await apiProvider.services.RedefinirSenhaService.enviarEmailRedefinirSenhaPost(
        {
          requestBody: payload,
        },
      );

    toastQueue.add(
      {
        type: "success",
        message,
      },
      {
        timeout: 5000,
      },
    );
    return redirect("/login");
  });
}
