import { isEmpty, omitBy } from "lodash";

interface PaginatedQuery {
  page?: number | null;
  elementsPerPage?: number | null;
}

export async function handleListService<
  TQuery extends PaginatedQuery,
  TResponse,
>(rawQuery: TQuery, serviceFunction: (query: TQuery) => Promise<TResponse>) {
  const query = omitBy(
    {
      elementsPerPage: PAGE_COUNT,
      ...rawQuery,
      page: (rawQuery.page ?? 1) - 1,
    },
    (v) => typeof v === "string" && isEmpty(v),
  ) as TQuery;

  return await serviceFunction(query);
}
