import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormComboBoxCompanyWorkerInput,
  FormComboBoxDevelopmentInput,
  FormComboBoxFailureInput,
  FormComboBoxManualInput,
  FormComboBoxMemorialInput,
  FormComboBoxProductInput,
  FormComboBoxSubsystemInput,
  FormComboBoxSystemInput,
  FormComboBoxUnitTypeInput,
  FormComboBoxWarrantyInput,
  FormTextAreaInput,
  Modal,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useMutationMaintenanceCreate } from "@/shared/hooks/api";

import { MaintenanceCreate, MaintenanceCreateSchema } from "../schemas";

export interface MaintenanceCreateModalProps
  extends Omit<ModalOverlayProps, "children"> {
  tipoManutencaoId: number;
  construtoraId: number;
}

export function MaintenanceCreateModal({
  tipoManutencaoId,
  construtoraId,
  ...props
}: MaintenanceCreateModalProps) {
  const callCreateMutation = useMutationMaintenanceCreate({
    onSuccess: () => {
      toastQueue.add(
        {
          type: "success",
          message: "Sucesso ao adicionar chamado",
        },
        {
          timeout: 5000,
        },
      );
      props.onOpenChange?.(false);
    },
  });

  const { formState, control, handleSubmit, watch, reset, resetField } =
    useForm<MaintenanceCreate>({
      resolver: zodResolver(MaintenanceCreateSchema),
      defaultValues: {
        tipoManutencaoId,
        construtoraId,
      },
    });

  const [
    empreendimentoId,
    manualId,
    sistemaId,
    subsistemaId,
    produtoId,
    garantiaId,
  ] = watch([
    "empreendimentoId",
    "manualId",
    "sistemaId",
    "subsistemaId",
    "produtoId",
    "garantiaId",
  ]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar novo atendimento"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              showSpinner={callCreateMutation.isPending}
              onPress={() =>
                handleSubmit((values) =>
                  callCreateMutation.mutateAsync(values),
                )()
              }
            >
              Adicionar
            </Button>
          </>
        )}
      >
        <form>
          <div className="grid grid-cols-4 gap-x-4 gap-y-6 p-1">
            <FormComboBoxDevelopmentInput
              name="empreendimentoId"
              control={control}
              filters={{
                construtoraId,
                assistenciaTecnica: true,
              }}
              onSelectionChange={() => {
                resetField("manualId");
                resetField("unidadeId");
              }}
            />
            <FormComboBoxManualInput
              control={control}
              isDisabled={!empreendimentoId}
              name="manualId"
              filters={{
                empreendimentoId,
              }}
            />

            <FormComboBoxUnitTypeInput
              control={control}
              name="unidadeId"
              isDisabled={!empreendimentoId}
              filters={{
                empreendimentoId: empreendimentoId,
              }}
            />
            <FormComboBoxCompanyWorkerInput
              name="colaboradorResponsavelId"
              control={control}
              label="Responsável"
              construtoraId={construtoraId}
            />
          </div>
          <div className="grid grid-cols-3 gap-x-4 gap-y-6 p-1">
            <FormTextAreaInput
              control={control}
              className="col-span-3"
              label="Solicitação do cliente"
              name="solicitacao"
            />
            <FormComboBoxMemorialInput
              control={control}
              name="memorialId"
              filters={{
                manualId,
              }}
            />
            <FormComboBoxSystemInput
              control={control}
              name="sistemaId"
              filters={{
                manualId,
              }}
              onSelectionChange={() => {
                resetField("subsistemaId");
                resetField("produtoId");
                resetField("garantiaId");
                resetField("falhaId");
              }}
            />
            <FormComboBoxSubsystemInput
              control={control}
              name="subsistemaId"
              isDisabled={!sistemaId}
              filters={{ sistemaId }}
              onSelectionChange={() => {
                resetField("produtoId");
                resetField("garantiaId");
                resetField("falhaId");
              }}
            />
            <FormComboBoxProductInput
              control={control}
              name="produtoId"
              isDisabled={!subsistemaId}
              filters={{ subsistemaId }}
              onSelectionChange={() => {
                resetField("garantiaId");
                resetField("falhaId");
              }}
            />
            <FormComboBoxWarrantyInput
              control={control}
              name="garantiaId"
              isDisabled={!subsistemaId}
              filters={produtoId ? { produtoId } : { subsistemaId }}
              onSelectionChange={() => {
                resetField("falhaId");
              }}
            />
            <FormComboBoxFailureInput
              control={control}
              name="falhaId"
              isDisabled={!garantiaId}
              filters={{ garantiaId }}
            />
          </div>
        </form>
      </Dialog>
    </Modal>
  );
}
