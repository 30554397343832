import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";
import { ZodTypeAny } from "zod";

import {
  Button,
  Dialog,
  FormComboBoxFailureInput,
  FormComboBoxMemorialInput,
  FormComboBoxProductInput,
  FormComboBoxSubsystemInput,
  FormComboBoxSystemInput,
  FormComboBoxWarrantyInput,
  FormTextAreaInput,
  Modal,
} from "@/shared/components";
import { useMutatioMaintenanceUpdate } from "@/shared/hooks/api";
import { z } from "@/shared/utils";

import { MaintenanceEdit, MaintenanceEditSchema } from "../schemas";

export interface MaintenanceEditModalProps<TSchema extends ZodTypeAny>
  extends Omit<ModalOverlayProps, "children"> {
  maintenanceId: number;
  useFormProps?: UseFormProps<z.infer<TSchema>>;
}

export function MaintenanceEditModal<TSchema extends ZodTypeAny>({
  useFormProps,
  maintenanceId,
  ...props
}: MaintenanceEditModalProps<TSchema>) {
  const callUpdateMutation = useMutatioMaintenanceUpdate();

  const { control, handleSubmit, watch, reset, resetField } =
    useForm<MaintenanceEdit>({
      resolver: zodResolver(MaintenanceEditSchema),
      ...useFormProps,
    });

  const [manualId, sistemaId, subsistemaId, produtoId, garantiaId] = watch([
    "manualId",
    "sistemaId",
    "subsistemaId",
    "produtoId",
    "garantiaId",
  ]);

  useEffect(() => {
    (async () => {
      if (useFormProps?.defaultValues) {
        reset(
          useFormProps.defaultValues instanceof Function
            ? await useFormProps.defaultValues()
            : useFormProps.defaultValues,
        );
      }
    })();
  }, [reset, useFormProps]);

  const onSubmit = (values: MaintenanceEdit, close: () => void) => {
    return callUpdateMutation.mutateAsync(
      {
        id: maintenanceId,
        requestBody: values,
      },
      {
        onSuccess: close,
      },
    );
  };

  return (
    <Modal {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Editar atendimento"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              showSpinner={callUpdateMutation.isPending}
              onPress={() => {
                handleSubmit((values) => onSubmit(values, close))();
              }}
            >
              Salvar
            </Button>
          </>
        )}
      >
        <form>
          <div className="grid grid-cols-3 gap-x-4 gap-y-6 p-1">
            <FormTextAreaInput
              control={control}
              className="col-span-3"
              label="Descrição"
              name="resumo"
            />
            <FormComboBoxMemorialInput
              control={control}
              name="memorialId"
              filters={{
                manualId,
              }}
            />
            <FormComboBoxSystemInput
              control={control}
              name="sistemaId"
              filters={{
                manualId,
              }}
              onSelectionChange={() => {
                resetField("subsistemaId", {
                  defaultValue: null as unknown as undefined,
                });
                resetField("produtoId", { defaultValue: null });
                resetField("garantiaId", { defaultValue: null });
                resetField("falhaId", { defaultValue: null });
              }}
            />
            <FormComboBoxSubsystemInput
              control={control}
              name="subsistemaId"
              isReadOnly={!sistemaId}
              filters={{ sistemaId }}
              onSelectionChange={() => {
                resetField("produtoId", { defaultValue: null });
                resetField("garantiaId", { defaultValue: null });
                resetField("falhaId", { defaultValue: null });
              }}
            />
            <FormComboBoxProductInput
              control={control}
              name="produtoId"
              isReadOnly={!subsistemaId}
              filters={{ subsistemaId }}
              onSelectionChange={() => {
                resetField("garantiaId", { defaultValue: null });
                resetField("falhaId", { defaultValue: null });
              }}
            />
            <FormComboBoxWarrantyInput
              control={control}
              name="garantiaId"
              isReadOnly={!subsistemaId}
              filters={produtoId ? { produtoId } : { subsistemaId }}
              onSelectionChange={() => {
                resetField("falhaId", { defaultValue: null });
              }}
            />
            <FormComboBoxFailureInput
              control={control}
              name="falhaId"
              isReadOnly={!garantiaId}
              filters={{ garantiaId }}
            />
          </div>
        </form>
      </Dialog>
    </Modal>
  );
}
