import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";

export function useMutationPostDelete() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: apiProvider.services.PostsService.deleteObjPostIdDelete,
    onSuccess: async () => {
      navigate(-1);

      toastQueue.add(
        {
          type: "success",
          message: "Post deletado com sucesso",
        },
        {
          timeout: 5000,
        },
      );
    },
    onError: (e) =>
      toastQueue.add(
        {
          type: "error",
          message: `Falha ao deletar post: ${e.message}`,
        },
        {
          timeout: 5000,
        },
      ),
  });
}
