import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient, z } from "@/shared/utils";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { ProfileUpdateSchema } from "../schemas";

export async function profileUpdateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<
    z.infer<typeof ProfileUpdateSchema> & { redirectTo: string }
  >(request, async ({ redirectTo, foto, ...payload }) => {
    await apiProvider.services.UsuariosService.updateSelfUsuariosProfilePut({
      requestBody: {
        ...payload,
        foto: foto ? foto[0].key : null,
      },
    });

    queryClient.invalidateQueries({ queryKey: ["profile"] });
    toastQueue.add(
      { type: "success", message: "Perfil atualizado com sucesso" },
      {
        timeout: 5000,
      },
    );
    return redirect(redirectTo);
  });
}
