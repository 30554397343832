import { useState } from "react";
import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import {
  WarrantyFailureCommon,
  WarrantyFailureCommonSchema,
} from "@/shared/modules/warranty/containers";
import { z } from "@/shared/utils";

import { AddWarrantyFailureModal } from ".";

const WarrantyFailureContainerSchema = z.object({
  falhas: z.array(WarrantyFailureCommonSchema).default([]),
});

type WarrantyFailureContainer = z.infer<typeof WarrantyFailureContainerSchema>;

interface FormWarrantyFailureListProps<T extends WarrantyFailureContainer> {
  className?: string;
  control: Control<T>;
}

export function FormWarrantyFailureList<T extends WarrantyFailureContainer>({
  control,
  ...props
}: FormWarrantyFailureListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<WarrantyFailureContainer>;
  const [currentFailure, setCurrentFailure] = useState<WarrantyFailureCommon>();

  return (
    <FormListInput
      {...props}
      control={_control}
      name="falhas"
      onAddPress={() => {
        setCurrentFailure(undefined);
        turnOn();
      }}
      resourceOptions={{
        word: "falha",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
                onEdit={() => {
                  setCurrentFailure(e);
                  turnOn();
                }}
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          {isOn && (
            <AddWarrantyFailureModal
              isOpen={isOn}
              onOpenChange={setOpen}
              useFormProps={{ defaultValues: currentFailure }}
              onAdd={(value) => {
                if (currentFailure) {
                  const updatedFailure = values.map((failure) =>
                    failure === currentFailure ? value : failure,
                  );
                  update(updatedFailure);
                  return turnOff();
                }
                update([...values, value]);
                return turnOff();
              }}
            />
          )}
        </>
      )}
    </FormListInput>
  );
}
