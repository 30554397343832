import {
  AnimatedContainer,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationSubsystemDelete,
  useMutationSubsystemUpdate,
  useSubsystem,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function SubsystemData() {
  const { subsystemId } = useRequiredParams();
  const subsystemQuery = useSubsystem(subsystemId);
  const mutationSubsystemUpdate = useMutationSubsystemUpdate();
  const mutationSubsystemDelete = useMutationSubsystemDelete();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler {...subsystemQuery}>
          {(data) => (
            <>
              <div className="flex flex-col">
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <PageTitle primary className="break-all">
                        {data?.nome}
                      </PageTitle>
                      <Can I="update.subsystem">
                        <EditLink />
                      </Can>
                      <Can I="delete.subsystem">
                        <DeleteResourceButton
                          resource="subsistema"
                          subject={data?.nome ?? ""}
                          action={() =>
                            mutationSubsystemDelete.mutateAsync({
                              id: +subsystemId,
                            })
                          }
                          showSpinner={mutationSubsystemDelete.isPending}
                        />
                      </Can>
                    </div>
                  </div>
                  {data && (
                    <Can I="update.subsystem">
                      <SwitchStatusButton
                        isDisabled={mutationSubsystemUpdate.isPending}
                        isSelected={data?.flagAtivo}
                        onChange={(status) =>
                          mutationSubsystemUpdate.mutateAsync({
                            id: +subsystemId,
                            requestBody: { flagAtivo: status },
                          })
                        }
                      />
                    </Can>
                  )}
                </div>
                <div className="flex gap-2 text-xs text-primary">
                  Ambientes:{" "}
                  {data?.memoriais?.map((memorial) => (
                    <span className="font-semibold">{memorial.nome}</span>
                  ))}
                </div>
              </div>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Descrição</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap text-black-0"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.descricao ?? "Vazio..."),
                  }}
                />
              </div>
              <p className="mb-3 text-sm text-black-2">Cuidados de uso</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap text-black-0"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.cuidados ?? "Vazio..."),
                  }}
                />
              </div>
              <p className="mb-3 text-sm text-black-2">Perdas de garantia</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap text-black-0 [&>p]:min-h-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.perdas ?? "Vazio..."),
                  }}
                />
              </div>
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
