import { UseQueryResult } from "@tanstack/react-query";

import { QueryHandler, SubtitleSkeleton } from "@/shared/components";

interface SubtitleHandlerProps<T> {
  query: UseQueryResult<T | undefined>;
  label: string;
}

export function SubtitleHandler<T extends { nome?: string }>({
  query,
  label,
}: SubtitleHandlerProps<T>) {
  return (
    <QueryHandler {...query} renderLoading={SubtitleSkeleton}>
      {(data) => (
        <span className="mt-0 text-xs text-primary">
          {label}: <span className="break-all font-semibold">{data?.nome}</span>
        </span>
      )}
    </QueryHandler>
  );
}
