import { ArrowRight2 } from "iconsax-react";
import { get } from "lodash";
import {
  Breadcrumbs as AriaBreadcrumbs,
  Breadcrumb,
  Link,
} from "react-aria-components";
import { Handle, UIMatch, useMatches } from "react-router";

import { useAuthorization } from "../contexts";
import { cn, ensureSlashed, permissions } from "../utils";

export interface BreadCrumbsProps {
  className?: string;
}

export function Breadcrumbs({ className }: BreadCrumbsProps) {
  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const { ability } = useAuthorization();

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .filter(
      (match) =>
        match.handle.action === undefined ||
        ability.can(get(permissions, match.handle.action)),
    )
    .map((match) => ({
      id: match.id,
      pathname: ensureSlashed(match.pathname),
      label: match.handle.crumb,
    }));

  const visibleCrumbs = crumbs.slice(-5);
  const showEllipsis = crumbs.length > 5;

  return (
    <AriaBreadcrumbs
      className={cn("flex items-center gap-3 text-sm text-gray-8", className)}
    >
      {visibleCrumbs.map(({ id, pathname, label }, i) => (
        <Breadcrumb key={id} className="flex items-center gap-3">
          {showEllipsis && i === 0 && (
            <>
              <span className="text-gray-8">...</span>
              <ArrowRight2 className="text-gray-8" size={10} />
            </>
          )}
          <Link
            className="last:cursor-default last:font-bold last:text-primary"
            href={pathname}
          >
            {label}
          </Link>
          {i !== visibleCrumbs.length - 1 && (
            <ArrowRight2 className="text-gray-8" size={10} />
          )}
        </Breadcrumb>
      ))}
    </AriaBreadcrumbs>
  );
}
