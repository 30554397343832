import {
  AnimatedContainer,
  Card,
  Separator,
  Skeleton,
} from "@/shared/components";

export function MaintenanceDetailLoading() {
  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Skeleton className="h-4 w-60" />
        <Skeleton className="h-6 w-32" />
      </div>

      <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <Skeleton className="size-10 rounded-full" />
            <div className="flex flex-col justify-center">
              <Skeleton className="my-2 h-4 w-24" />
              <div className="flex gap-6 text-xs text-primary">
                <Skeleton className="h-4 w-24" />
              </div>
            </div>
          </div>
          <Skeleton className="h-11 w-36" />
        </div>
        <Separator />
        <div className="flex items-center justify-between">
          <div className="flex gap-11 py-4 text-xs">
            <Skeleton className="h-5 w-24" />
            <Skeleton className="h-5 w-24" />
            <Skeleton className="h-5 w-24" />
            <Skeleton className="h-5 w-24" />
          </div>
          <Skeleton className="h-6 w-32" />
        </div>
        <Skeleton className="h-20 w-full rounded-2xl" />
      </Card>

      <AnimatedContainer className="mt-0">
        <Card className="flex flex-col shadow-header">
          <Skeleton className="h-6 w-40" />
          <Separator />
          <Skeleton className="h-4 w-40" />
          <div className="mt-2 flex-col gap-2">
            <div className="flex gap-2">
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
            </div>
            <div className="mt-2 flex gap-2">
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
            </div>
          </div>
          <Separator />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-5 w-40" />
            <Skeleton className="h-4 w-32" />
          </div>
        </Card>
      </AnimatedContainer>
    </AnimatedContainer>
  );
}
