import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";

import { Card, FieldLabel, FieldSetTitle } from "@/shared/components";
import { useUnits } from "@/shared/hooks/api";

import { FormTargetAssociateSystemInput, FormUnitSystemList } from ".";
import { SystemAssociateData } from "../schemas";

export function SystemUnitAssociationForm() {
  const { manualId, developmentId } = useParams();
  const { control, watch, setValue } = useFormContext<SystemAssociateData>();
  const watchedField = watch("tipoUnidade");

  const [{ data }] = useUnits({
    status: true,
    tipoId: control._formValues.tipoId,
    empreendimentoId: developmentId ? Number(developmentId) : undefined,
    elementsPerPage: undefined,
  });

  useEffect(() => {
    if (watchedField === "geral" && data?.content && manualId) {
      setValue(
        "unidades",
        data.content.map((unit) => ({
          id: unit.id,
          nome: `[${unit.bloco.nome}] ${unit.nome}`,
        })),
      );
    }
  }, [watchedField, data, setValue, manualId]);

  return (
    <Card className="mb-4 p-6">
      <FieldSetTitle>Associar sistema</FieldSetTitle>
      {!manualId && (
        <p className="my-5 rounded border-l-4 border-danger bg-danger-20 p-2 text-sm font-semibold italic text-gray-12">
          Este módulo só está disponível dentro da aba de manual.
        </p>
      )}
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormTargetAssociateSystemInput
          control={control}
          name="tipoUnidade"
          isReadOnly={!manualId}
        />
        {watchedField === "personalizada" && (
          <div className="col-span-2 flex flex-col gap-2">
            <FieldLabel>Lista de unidades</FieldLabel>
            <FormUnitSystemList control={control} />
          </div>
        )}
      </div>
    </Card>
  );
}
