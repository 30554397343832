import { Link } from "react-router";

import Logo from "@/assets/new-logo.svg?react";

import NotificationButton from "./notifications";
import { ProfileButton } from "./profile-button";

export default function PrivateHeader() {
  return (
    <header className="z-10 flex items-center justify-between bg-white-0 px-16 py-4 shadow-header">
      <Link to="/app">
        <Logo />
      </Link>
      <div className="flex items-center">
        <NotificationButton />
        <div className="ml-4 self-stretch border-l border-solid border-gray-1" />
        <ProfileButton />
      </div>
    </header>
  );
}
