export interface ExpirationProps {
  receivedDate: string;
  prazo: number;
  timeUnit: string;
}

export function calculateExpirationDate({
  receivedDate,
  prazo,
  timeUnit,
}: ExpirationProps): Date {
  const date = new Date(receivedDate);

  if (timeUnit === "ano") {
    date.setFullYear(date.getFullYear() + prazo);
  } else if (timeUnit === "mês") {
    date.setMonth(date.getMonth() + prazo);
  } else if (timeUnit === "semana") {
    date.setDate(date.getDate() + prazo * 7);
  } else if (timeUnit === "dia") {
    date.setDate(date.getDate() + prazo);
  }

  return date;
}
