import { FieldValues } from "react-hook-form";

import { apiProvider } from "@/shared/services";

import {
  FormComboBoxApiResourceInput,
  FormComboBoxApiResourceInputProps,
} from ".";

type ServiceFunction =
  typeof apiProvider.services.UnidadesService.getObjsUnidadesGet;

export type FormComboBoxAparmentInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormComboBoxApiResourceInputProps<
    T,
    TFieldValues,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;

export function FormComboBoxUnitTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormComboBoxAparmentInputProps<T, TFieldValues>) {
  return (
    <FormComboBoxApiResourceInput
      {...props}
      label="Unidade"
      queryKey={["unidade"]}
      service={async ({ pageParam: page, inputText: text }, extraFilters) =>
        await apiProvider.services.UnidadesService.getObjsUnidadesGet({
          page,
          text: text.replace(/\[[^\]]*\]/, "").trim(), // remove the e.bloco.nome
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({
        id: e.id,
        text: `[${e.bloco.nome}] ${e.nome}`,
      })}
    />
  );
}
