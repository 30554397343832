import { useFormContext } from "react-hook-form";

import { Card, FieldSetTitle, FormRichTextInput } from "@/shared/components";
import {
  FormPreventiveList,
  FormWarrantyList,
} from "@/shared/modules/system/modules/detail/modules/subsystem/modules/create/components";

import { ProductTechnicalData } from "../schemas";

export interface ProductTechnicalDataFormProps {
  isEditing?: boolean;
}

export function ProductTechnicalDataForm({
  isEditing,
}: ProductTechnicalDataFormProps) {
  const { control } = useFormContext<ProductTechnicalData>();
  return (
    <>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Cuidados de uso</FieldSetTitle>
        <FormRichTextInput
          control={control}
          className="col-span-2"
          name="cuidados"
        />
      </Card>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Perdas de garantia</FieldSetTitle>
        <FormRichTextInput
          control={control}
          className="col-span-2"
          name="perdas"
        />
      </Card>
      {!isEditing && (
        <>
          <Card className="mb-4 p-6">
            <FieldSetTitle>Garantia</FieldSetTitle>
            <FormWarrantyList control={control} className="col-span-2" />
          </Card>
          <Card className="mb-4 p-6">
            <FieldSetTitle>Manutenção preventiva</FieldSetTitle>
            <FormPreventiveList control={control} className="col-span-2" />
          </Card>
        </>
      )}
    </>
  );
}
