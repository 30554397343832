import { S3Image } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { Assinatura } from "@/shared/services";

import { SignatureFullScreenModal } from ".";

interface SignatureCardContentProps {
  signature: Assinatura;
}
export function SignatureCardContent({ signature }: SignatureCardContentProps) {
  const { isOn, turnOn, turnOff } = useSwitch(false);

  return (
    <div className="flex flex-col rounded bg-primary/5 p-3">
      <p className="mb-2 font-bold">{signature.usuario?.nome}</p>
      <div onClick={turnOn} className="cursor-pointer">
        <S3Image
          file={signature.assinaturaUrl}
          className="h-24 w-full object-contain"
        />
      </div>
      <SignatureFullScreenModal
        assinaturaUrl={signature.assinaturaUrl}
        isOpen={isOn}
        onClose={turnOff}
      />
    </div>
  );
}
