import { TickCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { FormProps } from "react-aria-components";
import { useActionData, useNavigate, useSearchParams } from "react-router";

import CheckBackground from "@/assets/check-background.svg?react";
import Logo from "@/assets/new-logo.svg?react";
import PasswordGirl from "@/assets/password-girl.svg?react";
import {
  Button,
  Dialog,
  Form,
  FormRootError,
  Modal,
  PublicFormTextInput,
} from "@/shared/components";
import { useSubmitting, useSwitch } from "@/shared/hooks";
import { z } from "@/shared/utils";

const RecoverPasswordSchema = z
  .object({
    nova_senha: z.string().min(6),
    confirmPassword: z.string().min(6),
  })
  .refine((data) => data.nova_senha === data.confirmPassword, {
    message: "Senhas não coincidem",
    path: ["confirmPassword"],
  });

function RecoverPasswordPage() {
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  const [token, _setToken] = useState(searchParams.get("token"));
  const { isOn, turnOn } = useSwitch(false);

  useEffect(() => {
    if (formErrors === null) {
      turnOn();
    }
  }, [formErrors, turnOn]);

  useEffect(() => {
    if (!token || formErrors?.[""] === "Token inválido") {
      navigate("/login");
    }
  }, [formErrors, navigate, token]);

  return (
    <div className="align-center flex h-screen w-screen flex-col items-center bg-white-1">
      <div className="mb-8 flex h-16 w-full items-center justify-center shadow-md drop-shadow-md">
        <Logo />
      </div>
      <div className="inline-flex gap-6 rounded-2xl bg-white-0 p-20">
        <div className="flex flex-[2] flex-col justify-center">
          <h4 className="mb-5 text-2xl font-bold">Redefinir senha</h4>
          <p className="mb-4 text-sm text-gray-6">
            Após confirmar o seu e-mail insira a sua nova senha e confirme para
            poder entrar no sistema.
          </p>
          <Form
            schema={RecoverPasswordSchema}
            autoComplete="on"
            validationErrors={formErrors}
            useFormProps={{
              defaultValues: {
                nova_senha: "",
                confirmPassword: "",
              },
            }}
          >
            {({ control }) => (
              <>
                <PublicFormTextInput
                  control={control}
                  label="Senha"
                  name="nova_senha"
                  type="password"
                  autoComplete="new-password"
                />
                <PublicFormTextInput
                  control={control}
                  label="Confirmar senha"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                />
                <input
                  className="hidden"
                  name="token"
                  defaultValue={token ?? ""}
                />
                <Button
                  className="w-full"
                  type="submit"
                  showSpinner={isSubmitting}
                >
                  Salvar
                </Button>
                <FormRootError />
              </>
            )}
          </Form>
        </div>
        <PasswordGirl className="flex-[3]" />
      </div>
      <Modal isOpen={isOn}>
        <Dialog
          className="w-screen max-w-md"
          title="Senha salva"
          role="alertdialog"
        >
          <div className="flex flex-col items-center pt-6">
            <div className="relative mb-4 flex w-64 items-center justify-center">
              <TickCircle
                variant="Bold"
                className="absolute z-20 size-14 text-success duration-1000 animate-in fade-in spin-in"
              />
              <CheckBackground className="rotate-12 duration-1000 ease-in-out animate-in direction-alternate repeat-infinite" />
            </div>
            <p className="mb-5 font-medium">Sua nova senha foi salva</p>
            <Button className="w-full" onPress={() => navigate("/login")}>
              Voltar para login
            </Button>
          </div>
        </Dialog>
      </Modal>
    </div>
  );
}

export default RecoverPasswordPage;
