import {
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
  RadioGroupProps as RACRadioGroupProps,
  RadioProps,
  ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { cn } from "@/shared/utils";

import { FieldError, FieldLabel } from "..";

export interface RadioGroupProps extends Omit<RACRadioGroupProps, "children"> {
  label?: string;
  children?: React.ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function RadioGroup(props: RadioGroupProps) {
  return (
    <RACRadioGroup
      {...props}
      className={cn(props.className, "group flex flex-col gap-2")}
    >
      <FieldLabel>{props.label}</FieldLabel>
      <div className="group-orientation-vertical:flex-col group-orientation-horizontal:gap-4 flex gap-2">
        {props.children}
      </div>
      <FieldError>{props.errorMessage}</FieldError>
    </RACRadioGroup>
  );
}

const styles = tv({
  base: "h-5 w-5 rounded-full border-2 bg-white-0 transition-all",
  variants: {
    isSelected: {
      false: "border-gray-5 group-rac-pressed:border-gray-6",
      true: "border-[7px] border-primary group-rac-pressed:border-gray-8 forced-colors:!border-[Highlight]",
    },
    isInvalid: {
      true: "border-error-80 group-rac-pressed:border-error forced-colors:!border-[Mark]",
    },
    isDisabled: {
      true: "border-gray-3 forced-colors:!border-[GrayText]",
    },
    isReadOnly: {
      true: "border-gray-3 forced-colors:!border-[GrayText]",
    },
  },
});

export function Radio(props: RadioProps) {
  return (
    <RACRadio
      {...props}
      className={cn(
        props.className,
        "text-gray-800 group flex items-center gap-2 text-sm transition disabled:border-gray-4 forced-colors:disabled:text-[GrayText]",
      )}
      data-cy={`radio-${props.value}`}
    >
      {(renderProps) => (
        <>
          <div className={styles(renderProps)} />
          {props.children}
        </>
      )}
    </RACRadio>
  );
}
