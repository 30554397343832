import { defineAbility } from "@casl/ability";
import { get } from "lodash";
import { useMemo } from "react";
import { Handle, UIMatch, useLocation, useMatches } from "react-router";

import { apiProvider } from "@/shared/services";
import { permissions } from "@/shared/utils";

import { AppAbility, AuthorizationContext } from ".";

export function AuthorizationProvider({ children }: React.PropsWithChildren) {
  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const ability = useMemo(
    () =>
      defineAbility<AppAbility>((can) => {
        apiProvider.user?.rotasPermissoes.forEach((permission) => {
          can(permission);
        });
      }),
    [],
  );

  const { pathname } = useLocation();

  const authorized: boolean | null = useMemo(() => {
    const rule = matches.find((match) => {
      return match.pathname === pathname;
    }, []);

    if (rule?.handle?.action === undefined) {
      return null;
    }

    return ability.can(get(permissions, rule.handle.action));
  }, [ability, matches, pathname]);

  return (
    <AuthorizationContext.Provider
      value={{
        ability,
        authorized,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}
