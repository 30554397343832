import { Time } from "@internationalized/date";

export class TimeInterval {
  start: Time;
  end: Time;

  constructor(start: Time = new Time(0, 0), end: Time = new Time(0, 0)) {
    this.start = start;
    this.end = end;
  }

  clone() {
    return new TimeInterval(this.start, this.end);
  }
}
