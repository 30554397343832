import { dateTimeMask, nullishStringMask } from "@/shared/utils/masks";

interface CommentCardContentProps {
  author: string;
  date: string;
  comment?: string | null;
}
export function CommentCardContent({
  author,
  date,
  comment,
}: CommentCardContentProps) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2.5">
        <span className="text-sm font-bold text-black-2">Comentário</span>
        <div className="flex justify-between">
          <div className="flex gap-1">
            <span className="text-xs font-medium text-gray-8">{author}</span>
            <span className="text-xs font-light text-gray-6">comentou</span>
          </div>
          <span className="text-xs font-light text-gray-6">
            {dateTimeMask(date)}
          </span>
        </div>
      </div>
      <span className="text-xs font-light text-gray-6">
        {nullishStringMask(comment)}
      </span>
    </div>
  );
}
