import { WarrantyCommonSchema } from "@/shared/modules/warranty/modules/create/schemas";
import { z } from "@/shared/utils";

export const PreventiveCommonSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  descricao: z.string().trim().nullish(),
  frequencia: z.number().int(),
  unidadeTempoId: z.number().int(),
  nbrId: z.number().int().nullish(),
  tipoClienteId: z.number().int(),
});

export type PreventiveCommon = z.infer<typeof PreventiveCommonSchema>;

export const FaqCreateSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  descricao: z.string().min(1, "Obrigatório").trim(),
});

export type FaqCreate = z.infer<typeof FaqCreateSchema>;

export const ProductCreateSchema = z.object({
  marca: z.string().trim().min(1, "Obrigatório"),
  nome: z.string().trim().min(1, "Obrigatório"),
  modelo: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  linkManualExterno: z
    .string()
    .startsWith("https://", "Precisa começar com https://")
    .url()
    .trim()
    .optional(),
  cuidados: z.string().trim().nullish(),
  perdas: z.string().trim().nullish(),
  notaFiscal: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .default([]),
  garantias: z.array(WarrantyCommonSchema).default([]),
  manutencoes: z.array(PreventiveCommonSchema).default([]),
});

export type ProductCreate = z.infer<typeof ProductCreateSchema>;

export const MemorialCreateSchema = z.object({
  id: z.number(),
  nome: z.string().min(1, "Obrigatório").trim(),
  categoria: z.string().trim().nullish(),
});

export type MemorialCreate = z.infer<typeof MemorialCreateSchema>;

export const SubsystemCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  cuidados: z.string().trim().nullish(),
  perdas: z.string().trim().nullish(),
  sistemaId: z.number().int(),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  memoriais: z.array(MemorialCreateSchema).default([]),
  produtos: z.array(ProductCreateSchema).default([]),
  garantias: z.array(WarrantyCommonSchema).default([]),
  manutencoes: z.array(PreventiveCommonSchema).default([]),
  faqs: z.array(FaqCreateSchema).default([]),
});

export type SubsystemCreate = z.infer<typeof SubsystemCreateSchema>;
