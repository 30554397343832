import { useFile } from "../hooks/api";

export interface VideoPlayerProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  url?: string | null;
}
export function VideoPlayer({
  url,
  controls = true,
  ...props
}: VideoPlayerProps) {
  const fileQuery = useFile(url);

  if (fileQuery.data) {
    return (
      <video controls={controls} {...props}>
        <source src={fileQuery.data.url} type="video/mp4" />
      </video>
    );
  }
}
