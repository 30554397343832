import { FieldValues } from "react-hook-form";

import {
  FormSelectInput,
  FormSelectInputProps,
  InputItem,
} from "@/shared/components";

export interface FormSelectPostTypeInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends FormSelectInputProps<T, TFieldValues> {}

export function FormSelectPostTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>({ ...props }: FormSelectPostTypeInputProps<T, TFieldValues>) {
  return (
    <FormSelectInput {...props}>
      <InputItem id="Informações gerais">Informações gerais</InputItem>
      <InputItem id="Notícias">Notícias</InputItem>
    </FormSelectInput>
  );
}
