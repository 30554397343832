import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { ContactCreateFormSchema, ContactCreateSchema } from "../schemas";
import { ContactAddressForm } from "./contact-address-form";
import { ContactGeneralDataForm } from "./contact-general-data-form";

export interface ContactCreateFormWizardProps<TSchema extends ZodTypeAny>
  extends Pick<FormWizardProps<TSchema>, "useFormProps"> {}

export function ContactCreateFormWizard<TSchema extends ZodTypeAny>(
  props: ContactCreateFormWizardProps<TSchema>,
) {
  return (
    <FormWizard
      {...props}
      onSuccessRedirect="../"
      formSchema={ContactCreateFormSchema}
      submitSchema={ContactCreateSchema}
      steps={[
        {
          heading: {
            head: "Dados gerais",
          },
          element: <ContactGeneralDataForm />,
        },
        {
          heading: {
            head: "Endereço",
          },
          element: <ContactAddressForm />,
        },
      ]}
    />
  );
}
