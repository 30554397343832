import { Card, Separator, Skeleton } from "@/shared/components";

export function ClientDetailLoading() {
  return (
    <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <div className="flex flex-col justify-center">
            <Skeleton className="my-2 h-4 w-24" />
            <div className="flex gap-6 text-xs text-primary">
              <Skeleton className="h-4 w-24" />
            </div>
          </div>
        </div>
      </div>
      <Separator />
      <Skeleton className="mb-3 h-5 w-24" />
      <Skeleton className="h-20 w-full rounded-2xl" />
    </Card>
  );
}
