import { DialogBody, DialogButtons, DialogHeader } from ".";
import { DialogCore, DialogCoreProps } from "./dialog-core";

export interface DialogProps extends DialogCoreProps {
  title?: string;
  renderButtons?: DialogCoreProps["children"];
  onClose?: () => void;
}

export function Dialog({
  title,
  children,
  renderButtons,
  onClose,
  ...props
}: DialogProps) {
  return (
    <DialogCore {...props}>
      {({ close }) => (
        <>
          {title && (
            <DialogHeader
              onClose={() => {
                close();
                onClose?.();
              }}
            >
              {title}
            </DialogHeader>
          )}
          {children && (
            <DialogBody>
              {typeof children === "function" ? children({ close }) : children}
            </DialogBody>
          )}
          {renderButtons && (
            <DialogButtons>
              {typeof renderButtons === "function"
                ? renderButtons({ close })
                : renderButtons}
            </DialogButtons>
          )}
        </>
      )}
    </DialogCore>
  );
}
