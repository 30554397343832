import { useParams } from "react-router";

import {
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { usePost } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { PostCreateForm } from "../../create/components";

export function PostEdit() {
  const { manualId } = useParams();
  const { postId } = useRequiredParams();
  const postQuery = usePost(postId);

  const getDefaultValues = (data: typeof postQuery.data) => {
    if (!data) {
      return undefined;
    }
    const { capa, nbr, texto, ...values } = data;
    return {
      defaultValues: {
        ...values,
        manualId: manualId ? +manualId : undefined,
        nbrId: nbr?.id,
        texto: texto ?? undefined,
        capa: capa
          ? [
              {
                fileName: capa,
                key: capa,
              },
            ]
          : undefined,
      },
    };
  };

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar Post</PageTitle>
      </div>

      <QueryHandler
        {...postQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Post não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <PostCreateForm
            redirectTo="../"
            useFormProps={getDefaultValues(data)}
          />
        )}
      </QueryHandler>
    </div>
  );
}
