import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Card,
  FormTextAreaInput,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useMutationCommentCreate, useProfile } from "@/shared/hooks/api";

import { CommentCreate, CommentCreateSchema } from "../schemas";

export interface CommentCreateFormProps {
  maintenanceId: number;
}

export function CommentCreateForm({ maintenanceId }: CommentCreateFormProps) {
  const profileQuery = useProfile();
  const commentCreateMutation = useMutationCommentCreate();

  const { formState, control, handleSubmit, reset } = useForm<CommentCreate>({
    resolver: zodResolver(CommentCreateSchema),
    defaultValues: { manutencaoId: maintenanceId },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <QueryHandler {...profileQuery} renderLoading={() => <Spinner />}>
      {(data) =>
        data?.id ? (
          <Card className="shadow-header">
            <span className="text-base font-semibold text-gray-12">
              Faça um comentário
            </span>
            <form className="space-y-3 pt-1">
              <FormTextAreaInput control={control} name="texto" />
              <div className="flex justify-end">
                <Button
                  className="px-9 py-3 text-xs font-semibold"
                  styleType="secondary"
                  showSpinner={commentCreateMutation.isPending}
                  onPress={() =>
                    handleSubmit((values) =>
                      commentCreateMutation.mutateAsync(values),
                    )()
                  }
                >
                  Publicar
                </Button>
              </div>
            </form>
          </Card>
        ) : (
          <p>Falha ao buscar informações do perfil</p>
        )
      }
    </QueryHandler>
  );
}
