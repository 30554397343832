import { Warning2 } from "iconsax-react";
import { ModalOverlayProps } from "react-aria-components";
import { VariantProps } from "tailwind-variants";

import { Button, ButtonProps, Dialog, Modal } from "@/shared/components";
import { cn } from "@/shared/utils";

import { modal } from "./style";

export interface DeleteModalProps
  extends ModalOverlayProps,
    VariantProps<typeof modal>,
    Pick<ButtonProps, "showSpinner"> {
  action?: () => void;
  subject: string;
  resource: string;
  isResourceFeminine?: boolean;
}

export function DeleteModal({
  className,
  subject,
  resource,
  isResourceFeminine,
  action,
  showSpinner,
  ...props
}: DeleteModalProps) {
  return (
    <Modal isDismissable {...props}>
      <Dialog
        className={cn(modal(props), className)}
        title={`Deletar ${resource}`}
        data-cy="delete-modal"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Não, mantenha
            </Button>
            <Button
              className="bg-error px-7 py-3 text-white-0"
              onPress={() => {
                action?.();
                close();
              }}
              showSpinner={showSpinner}
            >
              Sim, deletar
            </Button>
          </>
        )}
      >
        <div className="flex flex-col items-center gap-4">
          <Warning2 className="size-16 text-error" variant="Bold" />
          <span className="text-center text-gray-8">
            <p className="font-medium">Essa ação é irreversível!</p>
            <p>
              Tem certeza que deseja deletar {isResourceFeminine ? "a" : "o"}{" "}
              {resource}:
            </p>
            <span className="font-bold">{subject}</span>
          </span>
        </div>
      </Dialog>
    </Modal>
  );
}
