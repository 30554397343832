import { FieldValues } from "react-hook-form";

import { apiProvider } from "@/shared/services";

import {
  FormComboBoxApiResourceInput,
  FormComboBoxApiResourceInputProps,
} from ".";

type ServiceFunction =
  typeof apiProvider.services.FalhasService.getObjsFalhaGet;

export type FormComboBoxFailureInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormComboBoxApiResourceInputProps<
    T,
    TFieldValues,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;

export function FormComboBoxFailureInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormComboBoxFailureInputProps<T, TFieldValues>) {
  return (
    <FormComboBoxApiResourceInput
      {...props}
      label="Falha"
      queryKey={["failure"]}
      service={async ({ pageParam: page, inputText: text }, extraFilters) =>
        await apiProvider.services.FalhasService.getObjsFalhaGet({
          page,
          text,
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({ id: e.id, text: e.nome })}
    />
  );
}
