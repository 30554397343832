import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { FormProps as AriaFormProps } from "react-aria-components";
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { useActionData, useSubmit } from "react-router";
import { ZodTypeAny, z } from "zod";

import { FormCore, FormCoreProps } from "./form-core";

export interface FormWizardCoreProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
> extends Omit<
    FormCoreProps<z.infer<TSchema>>,
    "onSubmit" | "form" | "validationErrors" | "children"
  > {
  onSuccessRedirect?: string;
  formSchema: TSchema;
  submitSchema?: TSubmitSchema;
  steps: {
    heading: {
      head: string;
    };
    element: React.ReactNode;
  }[];
  useFormProps?: UseFormProps<z.infer<TSchema>>;
  page?: number;
  onPageChange?: (page: number) => void;
  onSubmit?: SubmitHandler<
    TSubmitSchema extends undefined
      ? z.infer<TSchema>
      : z.infer<TSubmitSchema extends ZodTypeAny ? TSubmitSchema : never>
  >;
  children?: (value: {
    step: React.ReactNode;
    form: UseFormReturn<z.infer<TSchema>>;
    submitHandler: ReturnType<UseFormHandleSubmit<z.infer<TSchema>>>;
  }) => React.ReactNode;
  resetOnSuccess?: boolean;
}

export function FormWizardCore<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined,
>({
  steps,
  formSchema,
  submitSchema,
  onSuccessRedirect,
  useFormProps: { defaultValues, ...useFormProps } = {},
  page = 1,
  onPageChange,
  onSubmit,
  children,
  resetOnSuccess,
}: FormWizardCoreProps<TSchema, TSubmitSchema>) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { _formStep: 0, ...defaultValues },
    ...useFormProps,
  });
  const { handleSubmit, getValues, setValue, reset } = form;
  const submit = useSubmit();

  const currentStep = useMemo(() => steps[page - 1], [page, steps]);

  useEffect(() => {
    setValue("_formStep", page - 1);
  }, [setValue, page]);

  const handleSub = handleSubmit(async () => {
    if (page < steps.length) {
      onPageChange?.(page + 1);
      return;
    }

    const { _formStep, ...formValues } = getValues();
    let values = formValues;

    if (submitSchema) {
      values = submitSchema.parse(values);
    }

    if (onSubmit) {
      await onSubmit(values as never);
      if (resetOnSuccess) {
        reset();
        onPageChange?.(1);
      }
      return;
    }

    submit(
      { ...values, redirectTo: onSuccessRedirect ?? null },
      {
        method: "post",
        encType: "application/json",
        action: location.pathname,
      },
    );
  });

  return (
    <FormCore form={form} onSubmit={handleSub} validationErrors={formErrors}>
      {children
        ? children({
            form,
            step: currentStep.element,
            submitHandler: handleSub,
          })
        : currentStep.element}
    </FormCore>
  );
}
