import { Handle, RouteObject } from "react-router";

import { AssistanceConfigAvailability } from "./containers";
import { updateCompanyAvailabilityAction } from "./modules/availability/actions";

export const configRouter: RouteObject = {
  path: "configuracoes",
  handle: {
    crumb: "Configurações",
    /* TODO: Add pending action */
  } as Handle,
  children: [
    {
      path: "disponibilidade",
      handle: {
        crumb: "Disponibilidade",
      } as Handle,
      action: updateCompanyAvailabilityAction,
      element: <AssistanceConfigAvailability />,
    },
  ],
};
