import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { Button } from "react-aria-components";

import { cn, generatePageNumbers } from "../utils";

export interface PaginationButtonsProps {
  className?: string;
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

export function PaginationButtons({
  className,
  currentPage,
  pageCount,
  onPageChange,
}: PaginationButtonsProps) {
  const isPreviousPageValid = currentPage <= 1;
  const isNextPageAvailable = currentPage >= pageCount;

  return (
    <div className={cn("flex justify-end px-16 py-6", className)}>
      <div className="flex items-center gap-2 text-sm text-primary">
        <Button
          className={cn(
            "flex size-8 items-center justify-center rounded-md",
            "rac-hover:bg-black-0/5",
            "rac-disabled:bg-none rac-disabled:text-gray-3",
          )}
          onPress={() => onPageChange(currentPage - 1)}
          isDisabled={isPreviousPageValid}
        >
          <ArrowLeft2 />
        </Button>
        <div className="flex items-center *:flex *:size-8 *:items-center *:justify-center *:rounded-md">
          {generatePageNumbers(currentPage, pageCount).map((i) => (
            <Button
              key={i}
              className={cn("rac-hover:bg-black-0/5", {
                "bg-primary/10": currentPage === i,
              })}
              onPress={() => onPageChange(i)}
            >
              {i}
            </Button>
          ))}
        </div>
        <Button
          className={cn(
            "flex size-8 items-center justify-center rounded-md",
            "rac-hover:bg-black-0/5",
            "rac-disabled:bg-none rac-disabled:text-gray-3",
          )}
          onPress={() => onPageChange(currentPage + 1)}
          isDisabled={isNextPageAvailable}
        >
          <ArrowRight2 />
        </Button>
      </div>
    </div>
  );
}
