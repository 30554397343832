import {
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteRating } from "@/shared/hooks/api";

import { RatingCard } from "../components";

export function RatingList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteRating({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
      <div className="flex gap-4">
        <span className="text-base font-bold text-black-2">Avaliações</span>
      </div>
      <Separator />
      <div className="flex flex-col gap-4 overflow-auto pb-6">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((rating) => (
            <RatingCard
              key={rating.id}
              ref={rating.id === flatData.length - 1 ? ref : null}
              rating={rating}
            />
          ))}
        </InfiniteQueryHandler>
      </div>
    </AnimatedContainer>
  );
}
