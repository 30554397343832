import { QueryHandler } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMaintenance,
  useProduct,
  useSubsystem,
  useSystem,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../../../components";

export function MissingWarranty() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  const systemQuery = useSystem(maintenanceQuery.data?.sistemaId?.toString());
  const subsystemQuery = useSubsystem(
    maintenanceQuery.data?.subsistemaId?.toString(),
  );
  const productQuery = useProduct(maintenanceQuery.data?.produtoId?.toString());

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-typography-2">Sistema:</p>
      <QueryHandler
        {...systemQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
      >
        {(system) => (
          <div
            className="tiptap text-typography-2 [&>p]:min-h-6"
            dangerouslySetInnerHTML={{
              __html: sanitize(nullishStringMask(system?.perdas)),
            }}
          />
        )}
      </QueryHandler>
      <p className="font-bold text-typography-2">Subsistema:</p>
      <QueryHandler
        {...subsystemQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
      >
        {(subsystem) => (
          <div
            className="tiptap text-typography-2 [&>p]:min-h-6"
            dangerouslySetInnerHTML={{
              __html: sanitize(nullishStringMask(subsystem?.perdas)),
            }}
          />
        )}
      </QueryHandler>
      {maintenanceQuery.data?.produtoId && (
        <>
          <p className="font-bold text-typography-2">Produto:</p>
          <QueryHandler
            {...productQuery}
            renderLoading={MaintenanceDetailLoading}
            renderError={(error) => <MaintenanceDetailError error={error} />}
          >
            {(product) => (
              <div
                className="tiptap text-typography-2 [&>p]:min-h-6"
                dangerouslySetInnerHTML={{
                  __html: sanitize(nullishStringMask(product?.perdas)),
                }}
              />
            )}
          </QueryHandler>
        </>
      )}
    </div>
  );
}
