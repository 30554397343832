import { z } from "@/shared/utils";

export const CostCreateSchema = z.object({
  manutencaoId: z.number().int(),
  tipoCustoId: z.number().int(),
  valorCentavos: z.string(),
  notaFiscal: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
});
export type CostCreate = z.infer<typeof CostCreateSchema>;
