import {
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
} from "react-aria-components";

import { cn } from "@/shared/utils";

export interface DialogCoreProps extends AriaDialogProps {}

export function DialogCore({ className, children, ...props }: DialogCoreProps) {
  return (
    <AriaDialog
      className={cn(
        "flex max-h-[calc(100vh-5rem)] flex-col outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </AriaDialog>
  );
}
