import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";

import {
  Button,
  Dialog,
  Link,
  Modal,
  SearchInput,
  Spinner,
} from "@/shared/components";
import {
  useInfiniteMemorials,
  useMutationAddManualMemorial,
} from "@/shared/hooks/api";

interface MemorialModalAssociateProps
  extends Omit<ModalOverlayProps, "children"> {
  manualId: number;
  onSuccess?: () => void;
}

export function MemorialModalAssociate({
  manualId,
  onSuccess,
  ...props
}: MemorialModalAssociateProps) {
  const [{ flatData, isFetching }, { handleSearchChange }, { ref }] =
    useInfiniteMemorials();

  const mutationAddManualMemorial = useMutationAddManualMemorial({ onSuccess });

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar ambiente"
        renderButtons={({ close }) =>
          mutationAddManualMemorial.isPending ? (
            <div className="flex items-center justify-center p-4">
              <Spinner />
            </div>
          ) : (
            <>
              <Button className="px-7 py-3" styleType="default" onPress={close}>
                Cancelar
              </Button>
              <Link
                className="px-7 py-3"
                styleType="default"
                href="/app/cadastros/ambiente/cadastrar/"
              >
                Cadastrar novo ambiente globalmente
              </Link>
              <Link className="px-7 py-3" styleType="default" href="cadastrar/">
                Cadastrar novo ambiente
              </Link>
            </>
          )
        }
      >
        <div className="space-y-4">
          <SearchInput onDebounceChange={handleSearchChange} />
          <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll p-1">
            {flatData?.map((e, i) => (
              <AriaButton
                key={e.id}
                ref={i === flatData.length - 1 ? ref : null}
                className="mr-4 rounded-lg border border-white-3 p-3 text-left"
                onPress={async () => {
                  await mutationAddManualMemorial.mutateAsync({
                    manualId: +manualId,
                    memorialId: e.id,
                  });
                }}
                isDisabled={mutationAddManualMemorial.isPending}
              >
                {e.id} · {e.nome}
              </AriaButton>
            ))}
            {isFetching && <Spinner className="mx-auto" />}
          </div>
        </div>
      </Dialog>
    </Modal>
  );
}
