import { useEffect, useState } from "react";
import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { z } from "@/shared/utils";

import { SystemUnit, SystemUnitSchema } from "../schemas";
import { UnitSystemListModal } from "./unit-system-modal";

const UnitSystemContainerSchema = z.object({
  unidades: z.array(SystemUnitSchema).default([]),
});

type UnitSystemContainer = z.infer<typeof UnitSystemContainerSchema>;

interface FormUnitSystemProps<T extends UnitSystemContainer> {
  className?: string;
  control: Control<T>;
}

export function FormUnitSystemList<T extends UnitSystemContainer>({
  control,
  ...props
}: FormUnitSystemProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<UnitSystemContainer>;
  const [selected, setSelected] = useState<SystemUnit[]>([]);

  useEffect(() => {
    if (_control._formValues.unidades) {
      setSelected(_control._formValues.unidades);
    }
  }, [_control]);

  return (
    <FormListInput
      {...props}
      control={_control}
      name="unidades"
      onAddPress={turnOn}
      resourceOptions={{
        word: "unidade",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() => {
                  update(values.filter((_, index) => index !== i));
                  setSelected(values.filter((_, index) => index !== i));
                }}
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}

          <UnitSystemListModal
            isOpen={isOn}
            onOpenChange={setOpen}
            selected={selected}
            setSelected={setSelected}
            tipoClienteId={_control._formValues.tipoId}
            onAdd={() => {
              update(selected);
              turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
