import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useManual(id?: string) {
  return useQuery({
    queryKey: ["manual", id],
    queryFn: async () => {
      if (!id) {
        return;
      }
      return apiProvider.services.ManuaisService.getOneObjManualIdGet({
        id: +id,
      });
    },
    enabled: !!id,
  });
}
