import { forwardRef } from "react";

import { cn } from "../utils";

interface StepDotProps extends React.PropsWithChildren {
  center?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

export const StepDot = forwardRef<HTMLDivElement, StepDotProps>(
  ({ children, center, isFirst, isLast }, ref) => {
    return (
      <div className="flex gap-3" ref={ref}>
        <div className={cn("flex flex-col items-center", { "-mt-2": center })}>
          <div
            className={cn(
              "h-full w-[3px]",
              isFirst ? "bg-transparent" : "bg-gray-1",
              { hidden: !center },
            )}
          />
          <div className="flex h-3 w-3 flex-shrink-0 rounded-full bg-gray-4" />
          <div
            className={cn(
              "h-full w-[3px]",
              isLast ? "bg-transparent" : "bg-gray-1",
            )}
          />
        </div>
        <div className="w-full pb-2">{children}</div>
      </div>
    );
  },
);
