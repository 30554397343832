import { UseQueryResult } from "@tanstack/react-query";

export type QueriesHandlerProps<TQueries extends UseQueryResult[]> = {
  renderLoading?: () => React.ReactElement;
  renderError?: (error: Error) => React.ReactElement;
  renderEmpty?: () => React.ReactElement;
  children?: (queries: { [K in keyof TQueries]: TQueries[K]["data"] }) =>
    | React.ReactNode
    | undefined;
  queries: TQueries;
};

export function QueriesHandler<const TQueries extends UseQueryResult[]>({
  renderLoading,
  renderError,
  renderEmpty,
  children,
  queries,
}: QueriesHandlerProps<TQueries>) {
  if (queries.some((query) => query.isLoading) && renderLoading !== undefined) {
    return renderLoading();
  }

  if (queries.some((query) => query.isError) && renderError) {
    const firstError = queries.find((query) => query.isError)?.error;
    if (firstError) {
      return renderError(firstError);
    }
  }

  if (queries.every((query) => query.data === undefined) && renderEmpty) {
    return renderEmpty();
  }

  return children?.(
    queries.map((query) => query.data) as {
      [K in keyof TQueries]: TQueries[K]["data"];
    },
  );
}
