import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { SubsystemFaqCreate } from "../schemas";

export async function subsystemFaqCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<SubsystemFaqCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      await apiProvider.services.FaqsService.createObjFaqPost({
        requestBody: {
          ...requestBody,
          seCliente: true,
          seConstrutora: false,
        },
      });

      toastQueue.add(
        {
          type: "success",
          message: "Faq adicionado no subsistema com sucesso",
        },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
