import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { CompanyCostTermsForm } from "./company-cost-terms-form";
import { CompanyInfoForm } from "./company-info-form";
import { CompanyLocationForm } from "./company-location-form";

export interface CompanyCreateFormWizardProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
> extends Pick<
    FormWizardProps<TSchema, TSubmitSchema>,
    "useFormProps" | "formSchema" | "submitSchema"
  > {
  isEditing?: boolean;
}

export function CompanyFormWizard<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
>({
  isEditing,
  ...props
}: CompanyCreateFormWizardProps<TSchema, TSubmitSchema>) {
  return (
    <FormWizard
      {...props}
      onSuccessRedirect="../"
      steps={[
        {
          heading: {
            head: "Dados da construtora",
          },
          element: <CompanyInfoForm isEditing={isEditing} />,
        },
        {
          heading: {
            head: "Localização",
          },
          element: <CompanyLocationForm />,
        },
        {
          heading: {
            head: "Termos de custo",
          },
          element: <CompanyCostTermsForm />,
        },
      ]}
    />
  );
}
