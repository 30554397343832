import { Building4, Buildings2 } from "iconsax-react";
import { MenuTrigger } from "react-aria-components";

import {
  AnimatedContainer,
  Button,
  Can,
  Menu,
  MenuItem,
  SearchInput,
} from "@/shared/components";
import { useIsCompanyRole, useRequiredParams } from "@/shared/hooks";
import { useBlocks } from "@/shared/hooks/api";

import { BlockTable } from "../components";

export function BlockList() {
  const { developmentId } = useRequiredParams();
  const isCompanyRole = useIsCompanyRole();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useBlocks({
      empreendimentoId: +developmentId,
      text: "",
      status: isCompanyRole ? true : undefined,
    });

  return (
    <AnimatedContainer className="space-y-4">
      <div className="flex gap-2">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <Can I="create.block">
          {(allowedOne) => (
            <Can I="bulk.create.blockApartment">
              {(allowedBulkApartment) => (
                <Can I="bulk.create.blockHome">
                  {(allowedBulkHome) =>
                    (allowedOne || allowedBulkApartment || allowedBulkHome) && (
                      <MenuTrigger>
                        <Button className="cursor-pointer px-16 py-3 font-medium">
                          Adicionar bloco
                          {(allowedBulkApartment || allowedBulkHome) && "(s)"}
                        </Button>
                        <Menu>
                          {allowedOne && (
                            <MenuItem id="single-create" href="cadastrar/">
                              <Building4 />
                              Criar um
                            </MenuItem>
                          )}
                          {allowedBulkApartment && (
                            <MenuItem
                              id="bulk-create"
                              href="lote/cadastrar/apartamento"
                              className="pr-4"
                            >
                              <Buildings2 />
                              (Apartamento) criar vários blocos
                            </MenuItem>
                          )}
                          {allowedBulkHome && (
                            <MenuItem
                              id="house-create"
                              href="lote/cadastrar/casa"
                            >
                              <Buildings2 />
                              (Casa) criar vários blocos
                            </MenuItem>
                          )}
                        </Menu>
                      </MenuTrigger>
                    )
                  }
                </Can>
              )}
            </Can>
          )}
        </Can>
      </div>
      <BlockTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
