import { Outlet, useLocation } from "react-router";

import { Breadcrumbs, PageTitle, TabPanel, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function DevelopmentDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("development");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do empreendimento",
      action: "get.development",
    },
    {
      id: `${baseUrl}aparencia/`,
      href: `${baseUrl}aparencia/`,
      label: "Aparência",
      action: "get.development",
    },
    {
      id: `${baseUrl}etapa/`,
      href: `${baseUrl}etapa/`,
      label: "Etapas da obra",
      action: "list.step",
    },
    {
      id: `${baseUrl}bloco/`,
      href: `${baseUrl}bloco/`,
      label: "Blocos",
      action: "list.block",
    },
    {
      id: `${baseUrl}cliente/`,
      href: `${baseUrl}cliente/`,
      label: "Clientes",
      action: "list.client",
    },
    {
      id: `${baseUrl}contatos/`,
      href: `${baseUrl}contatos/`,
      label: "Contatos",
      action: "list.contact",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do empreendimento</PageTitle>
      </div>
      <Tabs
        aria-label="Módulos da construtora"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
