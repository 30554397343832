import { CalendarDate, getDayOfWeek, parseDate } from "@internationalized/date";
import { useContext, useRef } from "react";
import { useCalendarCell } from "react-aria";
import { CalendarStateContext } from "react-aria-components";

import { Interval } from "@/shared/components/form/availability/schema";
import { parseInterval } from "@/shared/components/form/availability/serialization";
import { Manutencao } from "@/shared/services";

import CellButton from "./cell-button";

interface CalendarCellProps {
  date: CalendarDate;
  datesIntervals: NonNullable<Manutencao["disponibilidade"]>;
  onIntervalSelected?: (date: CalendarDate, interval: Interval) => void;
}

export function CalendarCell({
  date,
  datesIntervals,
  onIntervalSelected,
}: CalendarCellProps) {
  const ref = useRef(null);
  const state = useContext(CalendarStateContext)!;
  const { cellProps } = useCalendarCell({ date }, state, ref);
  const weekDay = getDayOfWeek(parseDate(date.toString()), "en-US");
  const dateIntervals = datesIntervals[weekDay];

  return (
    <td {...cellProps} className="p-1 align-top">
      {dateIntervals?.map((interval, i) => {
        const parsedInterval = parseInterval(interval);

        return (
          <CellButton
            key={i}
            date={date}
            isSelected
            interval={parsedInterval}
            onClick={() => onIntervalSelected?.(date, parsedInterval)}
          />
        );
      })}
      {!dateIntervals?.length && <CellButton date={date} isSelected={false} />}
    </td>
  );
}
