import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { SystemDataForm, SystemUnitAssociationForm } from ".";

export interface SystemCreateFormWizardProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
> extends Pick<
    FormWizardProps<TSchema, TSubmitSchema>,
    "useFormProps" | "formSchema" | "submitSchema"
  > {
  isEditing?: boolean;
}

export function SystemFormWizard<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
>({ ...props }: SystemCreateFormWizardProps<TSchema, TSubmitSchema>) {
  return (
    <FormWizard
      {...props}
      onSuccessRedirect="../"
      steps={[
        {
          heading: {
            head: "Dados gerais",
          },
          element: <SystemDataForm />,
        },
        {
          heading: {
            head: "Associar sistema",
          },
          element: <SystemUnitAssociationForm />,
        },
      ]}
    />
  );
}
