import { tv } from "tailwind-variants";

export const modal = tv({
  base: "w-screen",
  variants: {
    size: {
      compact: "max-w-[30vw]",
      normal: "max-w-[60vw]",
    },
  },
  defaultVariants: {
    size: "normal",
  },
});
