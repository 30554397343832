import { S3Image, S3Video } from "@/shared/components";
import { ManutencaoArquivo } from "@/shared/services";

interface SliderComponentProps {
  file?: ManutencaoArquivo;
}
export function SliderContent({ file }: SliderComponentProps) {
  return (
    <div className="flex h-[calc(100vh-8rem)] flex-1 items-center justify-center">
      {file?.seImagem ? (
        <S3Image
          file={file.arquivo}
          className="h-full rounded-xl object-contain"
          emptyIcon="default"
        />
      ) : (
        <S3Video file={file?.arquivo} className="h-full object-contain" />
      )}
    </div>
  );
}
