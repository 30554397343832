import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { useCallback } from "react";

import {
  Card,
  InfiniteQueryHandler,
  QueryHandler,
  SearchInput,
  StatusInput,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteFailure, useMaintenance } from "@/shared/hooks/api";
import { ExpirationProps, calculateExpirationDate } from "@/shared/utils";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../../../components";

export function WarrantyData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);

  const [
    { flatData, isFetching, error },
    { handleSearchChange, updateFilters },
    { ref },
  ] = useInfiniteFailure({
    garantiaId: maintenanceQuery.data?.garantiaId,
    text: "",
    status: true,
  });

  const renderExpirationDate = useCallback(
    ({ receivedDate, prazo, timeUnit }: ExpirationProps) => {
      const expirationDate = calculateExpirationDate({
        receivedDate,
        prazo,
        timeUnit,
      });

      const parsedExpirationDate = new CalendarDate(
        expirationDate.getFullYear(),
        expirationDate.getMonth(),
        expirationDate.getDate(),
      );

      const currentDate = today(getLocalTimeZone()).add({ months: 1 });
      if (parsedExpirationDate.compare(currentDate) > 0) {
        return (
          <span className="rounded bg-primary-light-1 px-2 py-1 text-xs font-semibold text-primary">
            Válido até: {expirationDate.toLocaleDateString("pt-BR")}
          </span>
        );
      }

      return (
        <span className="rounded bg-error-20 px-2 py-1 text-xs font-semibold text-error">
          Vencido em: {expirationDate.toLocaleDateString("pt-BR")}
        </span>
      );
    },
    [],
  );

  return (
    <>
      <div className="mb-4 flex gap-3">
        <SearchInput className="w-4/5" onDebounceChange={handleSearchChange} />
        <StatusInput
          className="w-1/5"
          onSelectionChange={(status) => updateFilters({ status })}
        />
      </div>
      <QueryHandler
        {...maintenanceQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
        renderEmpty={() => <></>}
      >
        {(maintenance) => (
          <div className="flex flex-col gap-2">
            <InfiniteQueryHandler
              isFetching={isFetching}
              error={error}
              isEmpty={!flatData?.length}
            >
              {flatData?.map((failure) => (
                <Card
                  key={failure.id}
                  ref={failure.id === flatData.length - 1 ? ref : null}
                  className="flex w-full justify-between"
                >
                  <p className="text-sm font-bold text-typography-1">
                    {failure?.nome}
                  </p>

                  {maintenance?.empreendimento?.dataEntrega && (
                    <>
                      {renderExpirationDate({
                        receivedDate: maintenance.empreendimento.dataEntrega,
                        prazo: failure.prazo,
                        timeUnit: failure.unidadeTempo.nome,
                      })}
                    </>
                  )}
                </Card>
              ))}
            </InfiniteQueryHandler>
          </div>
        )}
      </QueryHandler>
    </>
  );
}
