import { parseDate } from "@internationalized/date";
import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  Can,
  DatePickerInput,
  DetailsCell,
  Link,
  PageTitle,
  PaginationButtons,
  SearchInput,
  StatusInput,
  Table,
  TableRow,
} from "@/shared/components";
import { useCompanies } from "@/shared/hooks/api";
import { cnpjMask, nullishStringMask, phoneMask } from "@/shared/utils/masks";

export function ListConstructionCompanies() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useCompanies({ text: "", status: true });

  return (
    <div className="flex h-full flex-col p-8 animate-in fade-in slide-in-from-left-2">
      <div className="mb-6 flex justify-between">
        <PageTitle primary>Construtoras</PageTitle>
        <Can I="create.company">
          <div className="flex items-center">
            <Link className="w-auto px-8 py-3" href="cadastrar/">
              Cadastrar construtora
            </Link>
          </div>
        </Can>
      </div>
      <div className="mb-4 flex flex-wrap gap-3 lg:flex-nowrap">
        <SearchInput className="w-full" onDebounceChange={handleSearchChange} />
        <StatusInput
          className="flex-grow lg:w-1/5"
          onSelectionChange={(status) => updateFilters({ status })}
        />
        <DatePickerInput
          className="flex-grow lg:w-1/5"
          onChange={(data) => updateFilters({ data })}
          onReset={() => updateFilters({ data: undefined })}
          value={filters.data ? parseDate(filters.data) : null}
        />
      </div>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Can I="get.company">
            {(allowed) => (
              <Table
                aria-label="Construtoras"
                headers={[
                  "Construtora",
                  "CNPJ",
                  "E-mail",
                  "Telefone",
                  "Status",
                ]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "construtora",
                  isResourceFeminine: true,
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell className="font-bold text-gray-11">{data.nome}</Cell>
                    <Cell>{cnpjMask(data.cnpj)}</Cell>
                    <Cell>{nullishStringMask(data.email)}</Cell>
                    <Cell>
                      {nullishStringMask(
                        phoneMask(data.responsavel?.telefone ?? undefined),
                      )}
                    </Cell>
                    <ActiveFlagCell active={data.flagAtivo} />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
