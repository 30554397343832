import { ArrowLeft } from "iconsax-react";
import { FormProps } from "react-aria-components";
import { Link, useActionData } from "react-router";

import {
  Button,
  Form,
  FormRootError,
  PublicFormTextInput,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { z } from "@/shared/utils";

const ForgotPasswordSchema = z.object({
  email: z.string().email(),
});

function ForgotPasswordPage() {
  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <div className="w-96 animate-in fade-in slide-in-from-left-2">
      <Link
        className="mb-6 flex items-center text-sm text-gray-6"
        to="../login"
      >
        <ArrowLeft className="mr-2" />
        <span>Fazer login</span>
      </Link>
      <h4 className="mb-1 text-2xl font-semibold">Esqueceu sua senha?</h4>
      <p className="mb-5 text-sm text-gray-6">
        Para isso informe seu e-mail de acesso, nós vamos enviar o acesso para
        você redefinir sua senha
      </p>
      <Form
        schema={ForgotPasswordSchema}
        autoComplete="on"
        validationErrors={formErrors}
        useFormProps={{
          defaultValues: {
            email: "",
          },
        }}
      >
        {({ control }) => (
          <>
            <PublicFormTextInput
              control={control}
              label="E-mail"
              name="email"
              type="email"
              isRequired
              autoComplete="email"
            />
            <Button className="w-full" type="submit" showSpinner={isSubmitting}>
              Enviar link para e-mail
            </Button>
            <FormRootError />
          </>
        )}
      </Form>
    </div>
  );
}

export default ForgotPasswordPage;
