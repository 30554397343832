import { redirect } from "react-router";

import { apiProvider } from "@/shared/services";

export async function publicLoader() {
  if (apiProvider.isAuthenticated) {
    return redirect("/app");
  }

  return null;
}
