import { useParams } from "react-router";

import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useIsCompanyRole } from "@/shared/hooks";
import { useWarranties } from "@/shared/hooks/api";

import { WarrantyTable } from "../components";

export function WarrantyList() {
  const { subsystemId, productId } = useParams();
  const isCompanyRole = useIsCompanyRole();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useWarranties(
      productId
        ? { produtoId: +productId, status: isCompanyRole ? true : undefined }
        : {
            subsistemaId: +subsystemId!,
            status: isCompanyRole ? true : undefined,
          },
    );

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="garantia"
        onSearchChange={handleSearchChange}
        createAction="create.warranty"
      />
      <WarrantyTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
