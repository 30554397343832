import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormComboBoxCostTypeInput,
  FormFileInput,
  FormRootError,
  FormTextInput,
  Modal,
} from "@/shared/components";
import { useMutationCostCreate } from "@/shared/hooks/api";
import { megabyte } from "@/shared/utils";
import { currencyMask, currencyToNumber } from "@/shared/utils/masks";

import { CostCreate, CostCreateSchema } from "../schemas";

export interface CostCreateModalProps
  extends Omit<ModalOverlayProps, "children"> {
  manutencaoId: number;
}

export function CostCreateModal({
  manutencaoId,
  ...props
}: CostCreateModalProps) {
  const { formState, handleSubmit, control, reset } = useForm<CostCreate>({
    resolver: zodResolver(CostCreateSchema),
    defaultValues: {
      manutencaoId,
    },
  });

  const costCreateMutation = useMutationCostCreate();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const onSubmit = (
    {
      manutencaoId,
      notaFiscal,
      valorCentavos,
      tipoCustoId,
      ...values
    }: CostCreate,
    close: () => void,
  ) => {
    const basePayload = {
      ...values,
      manutencaoId,
      valorCentavos: currencyToNumber(valorCentavos),
      notaFiscal: notaFiscal ? notaFiscal[0].key : "",
      tipoCustoId: tipoCustoId,
    };

    costCreateMutation.mutateAsync(basePayload);
    close();
  };

  return (
    <Modal {...props}>
      <Dialog
        role="alertdialog"
        title="Adicionar custo"
        onClose={reset}
        renderButtons={({ close }) => (
          <div className="flex justify-end">
            <FormRootError />
            <Button
              className="px-24 py-3 font-medium"
              onPress={() =>
                handleSubmit((values) => onSubmit(values, close))()
              }
              showSpinner={costCreateMutation.isPending}
            >
              OK
            </Button>
          </div>
        )}
      >
        <form className="flex min-w-96 flex-col gap-3">
          <FormComboBoxCostTypeInput control={control} name="tipoCustoId" />
          <FormTextInput
            control={control}
            label="Valor"
            name="valorCentavos"
            mask={currencyMask}
          />
          <FormFileInput
            control={control}
            label="Nota fiscal"
            name="notaFiscal"
            maxFileSize={50 * megabyte}
            className="w-full"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
