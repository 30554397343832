import { useFormContext } from "react-hook-form";

import { AppointmentType } from "../schemas";
import { FormAppointmentTypeInput } from "./form-appointment-type-input";

export function AppointmentTypeForm() {
  const { control } = useFormContext<AppointmentType>();

  return (
    <div className="flex flex-col gap-5">
      <p className="text-sm font-medium text-black-2">
        Selecione o tipo de agendamento que deseja
      </p>
      <FormAppointmentTypeInput control={control} name="appointmentType" />
    </div>
  );
}
