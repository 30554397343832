import { wrapCreateBrowserRouterV7 } from "@sentry/react";
import { createBrowserRouter } from "react-router";

import privateRouter from "./private-router";
import publicRouter from "./public-router";

const sentryCreateBrowserRouter =
  wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([...privateRouter, ...publicRouter]);

export default router;
