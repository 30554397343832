import {
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useClient,
  useMutationClientDelete,
  useMutationClientUpdate,
} from "@/shared/hooks/api";
import { displayDateHourMin } from "@/shared/utils";

import { ClientDetailError, ClientDetailLoading } from "../components";

export function ClientData() {
  const { clientId } = useRequiredParams();
  const clientQuery = useClient(clientId);
  const mutationClientUpdate = useMutationClientUpdate();
  const mutationClientDelete = useMutationClientDelete();
  return (
    <QueryHandler
      {...clientQuery}
      renderLoading={ClientDetailLoading}
      renderError={(error) => <ClientDetailError error={error} />}
    >
      {(data) => (
        <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
          <div className="flex flex-col">
            <div className="flex justify-between gap-2">
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <PageTitle primary className="break-all">
                    {data?.nome}
                  </PageTitle>
                  <Can I="update.client">
                    <EditLink />
                  </Can>
                  <Can I="delete.client">
                    <DeleteResourceButton
                      resource="cliente"
                      subject={data?.nome ?? ""}
                      action={() =>
                        mutationClientDelete.mutateAsync({
                          id: +clientId,
                        })
                      }
                      showSpinner={mutationClientDelete.isPending}
                    />
                  </Can>
                </div>
              </div>
              {data && (
                <Can I="update.client">
                  <SwitchStatusButton
                    isDisabled={mutationClientUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationClientUpdate.mutateAsync({
                        id: +clientId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              )}
            </div>
            <div className="flex gap-6 text-xs text-primary">
              <p>
                Data de aceitação de termos de uso:{" "}
                <span className="font-semibold">
                  {displayDateHourMin(data?.aceitacao)}
                </span>
              </p>
            </div>
          </div>
          <Separator />
          <p className="mb-3 text-sm text-black-2">Contato</p>
          <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
            <div>
              <p>Nome</p>
              <p className="font-bold text-primary">{data?.nome}</p>
            </div>

            <div>
              <p>Email</p>
              <p className="font-bold text-primary">{data?.email}</p>
            </div>
          </div>
        </Card>
      )}
    </QueryHandler>
  );
}
