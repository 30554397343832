import { useEffect } from "react";
import { type FormProps } from "react-aria-components";
import { Link, useActionData } from "react-router";

import {
  Button,
  CheckboxInput,
  Form,
  FormRootError,
  PublicFormTextInput,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { queryClient, z } from "@/shared/utils";

const LoginSchema = z.object({
  username: z.string().email(),
  password: z.string().min(6),
});

function LoginPage() {
  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/";

  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  useEffect(() => {
    queryClient.clear();
  }, []);

  return (
    <div className="w-96 animate-in fade-in slide-in-from-left-2">
      <h1 className="mb-5 text-h4 font-semibold">Seja bem vindo!</h1>
      <Form
        schema={LoginSchema}
        autoComplete="on"
        validationErrors={formErrors}
        useFormProps={{
          defaultValues: {
            username: "",
            password: "",
          },
        }}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={from} />
            <PublicFormTextInput
              control={control}
              label="E-mail"
              name="username"
              type="email"
              autoComplete="email"
            />
            <PublicFormTextInput
              control={control}
              label="Senha"
              name="password"
              type="password"
              autoComplete="current-password"
            />
            <div className="mb-6 flex justify-between text-body-sm">
              <CheckboxInput name="keepAuthenticated" defaultSelected>
                Permanecer logado
              </CheckboxInput>
              <Link
                className={
                  "font-semibold text-primary underline decoration-primary"
                }
                to="/esqueci-a-senha"
              >
                Esqueci a senha
              </Link>
            </div>
            <Button className="w-full" type="submit" showSpinner={isSubmitting}>
              Entrar
            </Button>
            <FormRootError />
          </>
        )}
      </Form>
    </div>
  );
}

export default LoginPage;
