import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { BlockBulkCreateForm } from "../components";

export function DevelopmentBlockBulkCreate() {
  const { developmentId } = useRequiredParams();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar blocos e unidades</PageTitle>
      </div>
      <BlockBulkCreateForm
        redirectTo="../"
        useFormProps={{
          defaultValues: {
            empreendimentoId: +developmentId,
            zeros: 0,
            qtdBlocos: 1,
            qtdAndares: 1,
            qtdAptAndar: 1,
          },
        }}
      />
    </AnimatedContainer>
  );
}
