import { Handle, RouteObject } from "react-router";

import { editBlockAction } from "../edit/actions";
import { DevelopmentBlockEdit } from "../edit/containers";
import { unitCreateAction } from "./actions";
import { BlockData, UnitCreate, UnitsList } from "./containers";
import { BlockLayout } from "./layout";
import { unitDetailRouter } from "./modules/unit/router";

export const blockDetailRouter: RouteObject = {
  path: "detalhes/:blockId",
  handle: {
    crumb: "Detalhes do bloco",
    tabBaseUrl: "block",
    action: "get.block",
  } as Handle,
  children: [
    {
      element: <BlockLayout />,
      children: [
        {
          index: true,
          element: <BlockData />,
        },
        {
          path: "unidade",
          handle: {
            crumb: "Lista de unidades",
            action: "list.unit",
          } as Handle,
          element: <UnitsList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar bloco",
        action: "update.block",
      } as Handle,
      action: editBlockAction,
      element: <DevelopmentBlockEdit />,
    },
    {
      path: "unidade",
      handle: {
        crumb: "Lista de unidades",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar unidade",
            action: "create.unit",
          } as Handle,
          action: unitCreateAction,
          element: <UnitCreate />,
        },
        unitDetailRouter,
      ],
    },
  ],
};
