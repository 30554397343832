import { QueryHandler } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMaintenance, useProduct, useSubsystem } from "@/shared/hooks/api";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../../../components";
import { ContactsDataCard } from "./contacts-data-card";

export function ContactsData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);

  const subsystemQuery = useSubsystem(
    maintenanceQuery?.data?.subsistemaId?.toString() ?? "",
  );

  const productQuery = useProduct(
    maintenanceQuery?.data?.produtoId?.toString() ?? "",
  );

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-typography-2">Subsistema:</p>
      <QueryHandler
        {...subsystemQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
        renderEmpty={() => <></>}
      >
        {(subsystem) => (
          <>
            <ContactsDataCard
              type="Fornecedor"
              contact={subsystem?.fornecedor}
            />
            <ContactsDataCard
              type="Projetista"
              contact={subsystem?.projetista}
            />
          </>
        )}
      </QueryHandler>
      <QueryHandler
        {...productQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
        renderEmpty={() => <></>}
      >
        {(product) => (
          <>
            <p className="mt-4 font-bold text-typography-2">Produto:</p>
            <ContactsDataCard type="Fornecedor" contact={product?.fornecedor} />
            <ContactsDataCard type="Projetista" contact={product?.projetista} />
          </>
        )}
      </QueryHandler>
    </div>
  );
}
