import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  Comentario,
  ComentarioCreateWithoutUser,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationCreateCommentConfig
  extends Omit<
    UseMutationOptions<Comentario, Error, ComentarioCreateWithoutUser>,
    "mutationFn"
  > {}

export function useMutationCommentCreate(
  options?: UseMutationCreateCommentConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ComentarioCreateWithoutUser) => {
      return apiProvider.services.ComentariosService.createObjComentarioPost({
        requestBody,
      });
    },
    onSuccess: async (_, { manutencaoId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["commentsInfinite", { manutencaoId }],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao enviar comentário: ${e.message}`,
      }),
    ...options,
  });
}
