import { OverlayArrow, Popover } from "react-aria-components";

import { cn } from "@/shared/utils";

interface HeadPopoverProps {
  className?: string;
  children: React.ReactNode;
  translateX?: number;
}

export function HeadPopover({
  children,
  className,
  translateX,
}: HeadPopoverProps) {
  return (
    <Popover
      className={cn(
        `translate-y-10 transform rounded-lg bg-white-0 p-4 shadow-dialog rac-entering:animate-in rac-entering:fade-in rac-entering:slide-in-from-top-0 rac-exiting:animate-out rac-exiting:fade-out rac-exiting:slide-out-to-top-0`,
        className,
      )}
    >
      <OverlayArrow>
        <svg
          className={cn("size-8 rotate-180 fill-white-0", {
            [`transform translate-x-${translateX}`]: translateX,
          })}
          viewBox="0 0 12 12"
        >
          <path d="M0 0 L6 6 L12 0" />
        </svg>
      </OverlayArrow>
      {children}
    </Popover>
  );
}
