import { QueryHandler } from "@/shared/components";
import { AvailabilityCalendar } from "@/shared/components/availability-calendar/availability-calendar";
import { useRequiredParams } from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../../../components";

export function ClientSchedule() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={MaintenanceDetailLoading}
      renderError={(error) => <MaintenanceDetailError error={error} />}
    >
      {(maintenance) =>
        maintenance?.disponibilidade && (
          <AvailabilityCalendar datesIntervals={maintenance?.disponibilidade} />
        )
      }
    </QueryHandler>
  );
}
