import { Button } from ".";
import { useSubmitting } from "../hooks";

interface PageStepButtonsProps {
  isFirstPage?: boolean;
  isLastPage?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
}

export function PageStepButtons({
  isFirstPage,
  isLastPage,
  onPrevious,
  onNext,
  onSubmit,
}: PageStepButtonsProps) {
  const isSubmitting = useSubmitting();

  return (
    <div className="absolute bottom-0 left-0 z-10 flex w-full justify-end border-t border-t-gray-1 bg-white-0 p-3 shadow-form-footer-buttons animate-in slide-in-from-bottom-2">
      <div className="flex gap-6">
        {!isFirstPage && (
          <Button
            className="px-8 py-3.5"
            styleType="default"
            color="primary"
            onPress={onPrevious}
          >
            Voltar
          </Button>
        )}
        <Button
          className="min-w-44 px-8 py-3.5"
          styleType={isLastPage ? "contained" : "default"}
          onPress={isLastPage ? onSubmit : onNext}
          showSpinner={isSubmitting}
        >
          {isLastPage ? "Salvar" : "Próxima página"}
        </Button>
      </div>
    </div>
  );
}
