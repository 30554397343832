import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";

import { calculateExpirationDate } from "@/shared/utils";

interface WarrantyTagProps {
  receivedDate: string;
  prazo: number;
  timeUnit: string;
}

export function WarrantyTag({
  receivedDate,
  prazo,
  timeUnit,
}: WarrantyTagProps) {
  const renderStatusTag = () => {
    const expirationDate = calculateExpirationDate({
      receivedDate,
      prazo,
      timeUnit,
    });

    const parsedExpirationDate = new CalendarDate(
      expirationDate.getFullYear(),
      expirationDate.getMonth(),
      expirationDate.getDate(),
    );

    const currentDate = today(getLocalTimeZone()).add({ months: 1 });

    if (parsedExpirationDate.compare(currentDate) > 0) {
      return "Garantia em andamento";
    }

    return "Garantia com custos adicionais";
  };

  return (
    <span className="rounded bg-purple-10 px-[10px] py-[6px] text-xs text-purple">
      {renderStatusTag()}
    </span>
  );
}
