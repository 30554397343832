import { FormProps } from "react-aria-components";
import { ActionFunctionArgs } from "react-router";

import { handleApiError } from "./handle-api-error";

export type HandleActionReturnType = Promise<
  FormProps["validationErrors"] | Response | null
>;

export async function handleAction<T>(
  request: ActionFunctionArgs["request"],
  service: (payload: T) => HandleActionReturnType,
): HandleActionReturnType {
  const payload = await request.json();

  try {
    return await service(payload);
  } catch (error) {
    handleApiError({ error, requestUrl: request.url });
  }
}
