import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { z } from "@/shared/utils";

import { CompanyCategorySchema } from "../schemas";
import { CategoriesCompanyListModal } from "./category-company-list-modal";

const CategoryCompanyContainerSchema = z.object({
  categorias: z.array(CompanyCategorySchema).default([]),
});

type CategoryCompanyContainer = z.infer<typeof CategoryCompanyContainerSchema>;

interface FormWarrantyListProps<T extends CategoryCompanyContainer> {
  className?: string;
  control: Control<T>;
}

export function FormCategoryCompanyList<T extends CategoryCompanyContainer>({
  control,
  ...props
}: FormWarrantyListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<CategoryCompanyContainer>;

  return (
    <FormListInput
      {...props}
      control={_control}
      name="categorias"
      onAddPress={turnOn}
      resourceOptions={{
        word: "categoria",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}

          <CategoriesCompanyListModal
            isOpen={isOn}
            onOpenChange={setOpen}
            selectedCategories={values}
            onAdd={(value) => {
              update([...values, value]);
              return turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
