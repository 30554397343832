import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { CreateUnit } from "../../create/schemas";

export async function editUnitAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<CreateUnit & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      const unitId = +params.unitId!;

      if (requestBody.clientCreationType === "new-client") {
        await Promise.all([
          apiProvider.services.ClientesService.createClienteClientesPost({
            requestBody: {
              ...requestBody.responsavel,
              unidadeId: unitId,
            },
          }),
          apiProvider.services.UnidadesService.updateObjUnidadesIdPut({
            id: unitId,
            requestBody: {
              ...requestBody.unidade,
              nome: requestBody.unidade.nome,
              blocoId: requestBody.unidade.blocoId,
            },
          }),
        ]);
      } else {
        await apiProvider.services.UnidadesService.updateObjUnidadesIdPut({
          id: unitId,
          requestBody: {
            ...requestBody.unidade,
            donoId:
              requestBody.clientCreationType === "registered-client"
                ? requestBody.unidade.donoId
                : null,
          },
        });
      }

      toastQueue.add(
        { type: "success", message: "Unidade atualizada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
