import { useState } from "react";

import { dayAsString } from "@/shared/utils";

import { Button } from "../../button";
import { Checkbox, CheckboxGroup } from "../../inputs";
import { Dialog } from "../../modals";
import { Popover } from "../../popover";

export interface CopyToWeekDaysPopoverProps {
  onApply?: (days: number[]) => void;
  defaultSelectedDays?: number[];
  readOnlyDays?: number[];
}

export function CopyToWeekDaysPopover({
  onApply,
  defaultSelectedDays = [],
  readOnlyDays = [],
}: CopyToWeekDaysPopoverProps) {
  const [selectedDays, setSelectedDays] =
    useState<number[]>(defaultSelectedDays);

  return (
    <Popover>
      <Dialog
        renderButtons={({ close }) => (
          <>
            <Button size="small" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              size="small"
              onPress={() => {
                onApply?.(selectedDays);
                close();
              }}
            >
              Aplicar
            </Button>
          </>
        )}
      >
        <CheckboxGroup
          label="Copiar horários para:"
          value={selectedDays.map((day) => day.toString())}
          onChange={(newSelectedDays) =>
            setSelectedDays(newSelectedDays.map((day) => Number(day)))
          }
        >
          {Array.from({ length: 7 }, (_, i) => i).map((day) => (
            <Checkbox
              key={day}
              value={day.toString()}
              isReadOnly={readOnlyDays.includes(day)}
            >
              {dayAsString(day)}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Dialog>
    </Popover>
  );
}
