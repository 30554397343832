import { z } from "zod";

import { weekIntervalSchema } from "@/shared/components/form/availability/schema";

export const AssistanceAvailabilitySchema = z.object({
  construtoraId: z.number().int(),
  duracaoMinutosReuniao: z.number().int(),
  disponibilidade: weekIntervalSchema,
});

export type AssistanceAvailability = z.infer<
  typeof AssistanceAvailabilitySchema
>;
