import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import axios from "axios";

import { ToastPayload, toastQueue } from "../components/toast";
import router from "../router";
import { queryClient } from "../utils/query-client";
import * as services from "./services";
import {
  Body_login_for_access_token_auth_token__post,
  OpenAPI,
  UsuarioProfile,
} from "./seu-manual-api";

OpenAPI.BASE = import.meta.env.VITE_API_URL;
OpenAPI.TOKEN = async () => apiProvider.accessToken ?? "";
OpenAPI.interceptors.response.use(async (response) => {
  const originalRequest = response.config;

  if (response.status === 401 && apiProvider.isAuthenticated) {
    // get pathname to redirect after user login again
    const pathname = router.window?.location.pathname;
    const params = new URLSearchParams();
    pathname && params.set("from", pathname);

    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}/auth/refresh_token/`,
          {},
          { headers: { Authorization: `Bearer ${refreshToken}` } },
        );
        const newAccessToken = response.data.access_token;
        localStorage.setItem("accessToken", newAccessToken);
        apiProvider.accessToken = newAccessToken;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (error) {
        apiProvider.removeAuthToken();

        router.navigate({
          pathname: "/login",
          search: `?${params.toString()}`,
        });

        // dont show the same toast again if there's one already
        const toastPayload: ToastPayload = {
          type: "error",
          message: "Sessão expirada, autentique novamente...",
        };
        const sameToast = toastQueue.visibleToasts.find(
          (toast) => toast.content.message === toastPayload.message,
        );
        if (!sameToast) {
          toastQueue.add(toastPayload);
        }
      }
    }
  }

  return response;
});

export type SigninPayload = Body_login_for_access_token_auth_token__post;

interface ApiProvider {
  isAuthenticated: boolean;
  accessToken?: string;
  refreshToken?: string;
  user?: UsuarioProfile;
  init(): Promise<void>;
  refreshTokens(): Promise<void>;
  fetchUser(): Promise<void>;
  signin(payload: SigninPayload, keepAuthenticated?: boolean): Promise<void>;
  removeAuthToken(): void;
  signout(): Promise<void>;
  services: typeof services;
}

export const apiProvider: ApiProvider = {
  isAuthenticated: false,
  accessToken: localStorage.getItem("accessToken") ?? undefined,
  refreshToken: localStorage.getItem("refreshToken") ?? undefined,
  async init() {
    try {
      await apiProvider.refreshTokens();
      await apiProvider.fetchUser();
    } catch (e) {
      apiProvider.isAuthenticated = false;
      delete apiProvider.accessToken;
      delete apiProvider.user;
    }
  },
  async refreshTokens() {
    if (apiProvider.refreshToken) {
      OpenAPI.TOKEN = async () => apiProvider.refreshToken ?? "";
      const tokenResponse =
        await apiProvider.services.AuthService.refreshAccessTokenAuthRefreshTokenPost();
      OpenAPI.TOKEN = async () => apiProvider.accessToken ?? "";

      apiProvider.accessToken = tokenResponse.access_token;
    }
  },
  async fetchUser() {
    if (apiProvider.accessToken) {
      const profileResponse =
        await apiProvider.services.UsuariosService.getProfileUsuariosProfileGet();

      queryClient.setQueryData(["profile"], profileResponse);

      apiProvider.isAuthenticated = true;
      apiProvider.user = profileResponse;

      if (import.meta.env.VITE_INTERCOM_APP_ID) {
        Intercom({
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: apiProvider.user?.id.toString(),
          name: apiProvider.user?.nome,
          user_hash: apiProvider.user?.intercomHash,
          email: apiProvider.user?.email,
          created_at: apiProvider.user?.criadoEm
            ? Date.parse(apiProvider.user?.criadoEm)
            : undefined,
          platform: "Gerência",
        });
      }
    }
  },
  async signin(payload: SigninPayload, keepAuthenticated?: boolean) {
    const tokenResponse =
      await apiProvider.services.AuthService.loginForAccessTokenAuthTokenPost({
        formData: payload,
      });

    apiProvider.accessToken = tokenResponse.access_token;
    apiProvider.refreshToken = tokenResponse.refresh_token;

    const profileResponse =
      await apiProvider.services.UsuariosService.getProfileUsuariosProfileGet();

    if (profileResponse.papel.tipo === "Cliente") {
      apiProvider.signout();
      toastQueue.add({
        type: "error",
        message:
          "Acesso restrito. Usuários do tipo 'Cliente' não possuem permissão para acessar este sistema.",
      });
      return;
    }

    apiProvider.isAuthenticated = true;
    apiProvider.user = profileResponse;

    if (keepAuthenticated) {
      localStorage.setItem("accessToken", apiProvider.accessToken);
      localStorage.setItem("refreshToken", apiProvider.refreshToken);
    }

    if (import.meta.env.VITE_INTERCOM_APP_ID) {
      Intercom({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        user_id: apiProvider.user?.id.toString(),
        name: apiProvider.user?.nome,
        user_hash: apiProvider.user?.intercomHash,
        email: apiProvider.user?.email,
        created_at: apiProvider.user?.criadoEm
          ? Date.parse(apiProvider.user?.criadoEm)
          : undefined,
        platform: "Gerência",
      });
    }
  },
  async signout() {
    shutdown();
    await apiProvider.services.AuthService.logoutAuthLogoutPost();
    apiProvider.removeAuthToken();
  },
  removeAuthToken() {
    apiProvider.isAuthenticated = false;

    delete apiProvider.accessToken;
    delete apiProvider.refreshToken;
    delete apiProvider.user;

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },
  services,
};

await apiProvider.init();
