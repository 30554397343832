import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { SubsystemEdit } from "../schemas";

export async function subsystemEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<SubsystemEdit & { redirectTo: string }>(
    request,
    async ({ redirectTo, memoriais, ...requestBody }) => {
      const subsystemId = +params.subsystemId!;

      await apiProvider.services.SubsistemasService.updateObjSubsistemaIdPut({
        id: subsystemId,
        requestBody: {
          ...requestBody,
          memorialIds: memoriais.map((memorial) => memorial.id),
        },
      });

      toastQueue.add(
        { type: "success", message: "Subsistema atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
