import { Add } from "iconsax-react";

import {
  AnimatedContainer,
  Button,
  Card,
  QueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";

import {
  FileListContainer,
  FileListError,
  FileListLoading,
  MediaFilesCreateModal,
} from "../components";

export function MediaFilesList() {
  //TODO: change param to callId
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={FileListLoading}
      renderError={(error) => <FileListError error={error} />}
    >
      {(data) => (
        <AnimatedContainer className="mt-0">
          <Card className="shadow-header">
            <div className="flex items-center gap-4">
              <span className="text-base font-bold text-black-2">
                Arquivos e mídia
              </span>
              <Button
                styleType="default"
                className="flex gap-2 py-1 text-base font-bold"
                onPress={turnOn}
              >
                <Add size={24} /> Adicionar mídia
              </Button>
            </div>
            <Separator />
            {data?.manutencaoArquivo && (
              <FileListContainer files={data.manutencaoArquivo} />
            )}
            {data && (
              <MediaFilesCreateModal
                manutencaoId={data.id}
                isOpen={isOn}
                onOpenChange={setOpen}
              />
            )}
          </Card>
        </AnimatedContainer>
      )}
    </QueryHandler>
  );
}
