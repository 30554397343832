import { RouterProvider } from "react-aria-components";
import { Outlet, useHref, useNavigate } from "react-router";

import LogoWhite from "@/assets/new-logo-white.svg?react";
import WaveBottomRight from "@/assets/wave-bottom-right.svg?react";
import WaveTopLeft from "@/assets/wave-top-left.svg?react";

export function PublicLayout() {
  const navigate = useNavigate();

  return (
    <RouterProvider
      navigate={(path, opts) =>
        navigate(new URL(path, window.location.href).pathname, opts)
      }
      useHref={useHref}
    >
      <div className="flex h-screen">
        <div
          className={`relative flex flex-1 items-center justify-center rounded-r-3xl bg-gradient-to-b from-primary to-[#00C4A5]`}
        >
          <WaveTopLeft className="absolute left-0 top-0" />
          <WaveBottomRight className="absolute bottom-0 right-0" />
          <LogoWhite />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <Outlet />
        </div>
      </div>
    </RouterProvider>
  );
}
