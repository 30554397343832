import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { CompanyEdit } from "../schema";

export async function editCompanyAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<CompanyEdit & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      await apiProvider.services.ConstrutorasService.updateObjConstrutorasIdPut(
        {
          id: params.companyId ? +params.companyId : payload.construtoraId,
          requestBody: {
            ...payload,
            foto: payload.foto ? payload.foto[0].key : null,
            categoriaIds: payload.categorias.map((categoria) => categoria.id),
            categorias: undefined,
          },
        },
      );

      toastQueue.add(
        { type: "success", message: "Construtora atualizada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
