import { DocumentUpload, Layer } from "iconsax-react";
import { useState } from "react";
import { MenuTrigger } from "react-aria-components";
import { useParams } from "react-router";

import {
  AnimatedContainer,
  Button,
  Can,
  Menu,
  MenuItem,
  SearchInput,
} from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { useDocuments } from "@/shared/hooks/api";
import { Arquivo } from "@/shared/services";

import {
  DocumentCreateManyModal,
  DocumentCreateOneModal,
} from "../../create/components";
import { DocumentPath, DocumentsPaginatedList } from "../components";

export function DocumentList() {
  const { manualId, unitId } = useParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useDocuments({
      manualId: manualId ? +manualId : undefined,
      unidadeId: unitId ? +unitId : undefined,
      status: true,
      paiId: 0,
      text: "",
    });
  const [path, setPath] = useState<Array<Arquivo>>([]);

  const {
    isOn: createOneIsOn,
    turnOn: createOneTurnOn,
    setOpen: createOneSetOpen,
  } = useSwitch(false);
  const {
    isOn: createManyIsOn,
    turnOn: createManyTurnOn,
    setOpen: createManySetOpen,
  } = useSwitch(false);

  const moveInsideFolder = (folder: Arquivo) => {
    updateFilters({ paiId: folder.id });
    setPath((prev) => [...prev, folder]);
  };

  const changePath = (index: number) => {
    if (path.length === 0 || path.length === index) {
      return;
    }

    const newPath = path.slice(0, index);
    const current = newPath.at(-1);
    updateFilters({ paiId: current ? current.id : 0 });
    setPath(newPath);
  };

  return (
    <AnimatedContainer className="space-y-4">
      <div className="flex gap-2">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <Can I="create.document">
          {() => (
            <MenuTrigger>
              <Button className="cursor-pointer px-16 py-3 font-medium">
                Adicionar
              </Button>
              <Menu>
                <MenuItem id="single-create">
                  <div
                    className="flex items-center gap-2"
                    onClick={createOneTurnOn}
                  >
                    <DocumentUpload />
                    Adicionar um
                  </div>
                </MenuItem>
                <MenuItem id="bulk-create">
                  <div
                    className="flex items-center gap-2"
                    onClick={createManyTurnOn}
                  >
                    <Layer />
                    Adicionar vários
                  </div>
                </MenuItem>
              </Menu>
            </MenuTrigger>
          )}
        </Can>
      </div>
      <DocumentPath path={path} onPathChange={changePath} />
      <DocumentsPaginatedList
        data={data?.content}
        isLoading={isLoading}
        search={filters.text ?? undefined}
        currentPage={filters.page ?? 1}
        pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
        onPageChange={(page) => updateFilters({ page })}
        onFolderChange={moveInsideFolder}
      />
      <DocumentCreateOneModal
        isOpen={createOneIsOn}
        onOpenChange={createOneSetOpen}
        manualId={manualId ? +manualId : undefined}
        unitId={unitId ? +unitId : undefined}
        paiId={
          typeof filters.paiId === "number" && filters.paiId !== 0
            ? filters.paiId
            : undefined
        }
      />
      <DocumentCreateManyModal
        isOpen={createManyIsOn}
        onOpenChange={createManySetOpen}
        manualId={manualId ? +manualId : undefined}
        unitId={unitId ? +unitId : undefined}
        paiId={
          typeof filters.paiId === "number" && filters.paiId !== 0
            ? filters.paiId
            : undefined
        }
      />
    </AnimatedContainer>
  );
}
