import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormNumberInput,
  FormProps,
  FormRootError,
  FormSelectTimeUnitInput,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import { WarrantyFailureCreateSchema } from "../schemas";

interface FailureCreateFormProps
  extends Omit<
    FormProps<typeof WarrantyFailureCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function FailureCreateForm({
  redirectTo,
  ...formProps
}: FailureCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={WarrantyFailureCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={redirectTo} />
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 px-1 pb-4">
              <FormTextInput
                control={control}
                className="col-span-2"
                label="Título"
                name="nome"
              />
              <FormNumberInput
                control={control}
                label="Prazo"
                name="prazo"
                minValue={1}
              />
              <FormSelectTimeUnitInput
                control={control}
                name="unidadeTempoId"
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
