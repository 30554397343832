import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { BlockBulkCreate } from "../schemas";

export async function apartmentBlockBulkCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<BlockBulkCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      await apiProvider.services.AcopladosService.postUnidadesLoteApartamentoAcopladosUnidadesLoteApartamentoPost(
        {
          requestBody: payload,
        },
      );

      toastQueue.add(
        { type: "success", message: "Blocos e unidades criados com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
