import { ArrowDown2 } from "iconsax-react";
import {
  Button,
  ComboBox,
  ComboBoxProps,
  FieldError,
  Group,
  Input,
  Label,
  ListBox,
  Popover,
} from "react-aria-components";

import { cn } from "@/shared/utils";

import { ClearComboBoxButton } from "../clear-combo-box-button";
import { Spinner } from "../spinner";
import {
  InputStyleProps,
  container,
  input,
  label as labelStyle,
} from "./style/input";

export interface ComboBoxInputProps<T extends object>
  extends Omit<ComboBoxProps<T>, "children">,
    InputStyleProps {
  label: string;
  children?: React.ReactNode | ((item: T) => React.ReactNode);
  inputRef?: React.Ref<HTMLInputElement>;
  errorMessage?: string;
  isLoading?: boolean;
}

export function ComboBoxInput<T extends object>({
  className,
  label,
  children,
  inputRef,
  errorMessage,
  isLoading,
  ...props
}: ComboBoxInputProps<T>) {
  return (
    <ComboBox
      {...props}
      className={cn(container(props), className)}
      menuTrigger="focus"
    >
      <Label className={labelStyle(props)}>{label}</Label>
      <Group className="flex items-center gap-1">
        <ClearComboBoxButton />
        <div className="relative flex flex-1">
          <Input
            ref={inputRef}
            className={cn(input(props), "peer w-full placeholder:text-gray-4")}
            placeholder="Selecione"
          />
          {isLoading && (
            <Spinner className="absolute right-12 top-3 h-[20px] w-[20px]" />
          )}
          <Button className="absolute right-5 top-3">
            <ArrowDown2
              className="text-gray-9 transition-all peer-rac-hover:translate-y-1"
              size={20}
              variant="Bold"
            />
          </Button>
        </div>
      </Group>
      <FieldError className="text-xs font-semibold text-error">
        {errorMessage}
      </FieldError>
      <Popover className="!max-h-40 w-[--trigger-width] overflow-auto rounded-lg border border-white-3 bg-white-0 p-3 text-sm shadow-dialog">
        <ListBox className="*:rounded-lg *:p-2">{children}</ListBox>
      </Popover>
    </ComboBox>
  );
}
