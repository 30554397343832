import { useCallback, useEffect } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

import { TextAreaInput, TextAreaInputProps } from "..";

export interface FormTextAreaInputProps<T extends FieldValues>
  extends TextAreaInputProps {
  label?: string;
  control: Control<T>;
  name: Path<T>;
  value?: PathValue<T, Path<T>>;
}

export function FormTextAreaInput<T extends FieldValues>({
  control,
  name,
  onChange,
  mask,
  value,
  isDisabled,
  ...props
}: FormTextAreaInputProps<T>) {
  const {
    field: {
      ref,
      value: controlValue,
      onChange: controlOnChange,
      disabled,
      ...fieldProps
    },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: isDisabled,
  });

  const handleChange = useCallback(
    (v: string) => {
      if (v === "") {
        controlOnChange(null);
      } else {
        controlOnChange(v);
      }
      onChange?.(v);
    },
    [controlOnChange, onChange],
  );

  useEffect(() => {
    if (value) {
      handleChange(mask ? (mask(value) as string) : value);
    }
  }, [handleChange, mask, value]);

  return (
    <TextAreaInput
      {...props}
      {...fieldProps}
      onChange={handleChange}
      mask={mask}
      value={controlValue || ""}
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
      errorMessage={error?.message}
      ref={ref}
    />
  );
}
