import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { CreateUser } from "../../create/schemas";

export async function userEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<CreateUser & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      const userId = +params.userId!;
      await apiProvider.services.UsuariosService.updateObjUsuariosIdPut({
        id: userId,
        requestBody: payload,
      });

      toastQueue.add(
        { type: "success", message: "Usuário atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
