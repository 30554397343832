import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { UnidadeWithAll } from "@/shared/services";

export interface UnitTableProps
  extends Pick<TableProps<UnidadeWithAll>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function UnitTable({ pagination, search, ...props }: UnitTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.unit">
          {(allowed) => (
            <Table
              {...props}
              aria-label="Unidades"
              headers={["Código", "Número", "Bloco", "Status"]}
              emptyProps={{
                resource: "unidade",
                isResourceFeminine: true,
                search,
              }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, nome, bloco, flagAtivo }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{id}</Cell>
                  <Cell>{nome}</Cell>
                  <Cell>{bloco?.nome}</Cell>
                  <ActiveFlagCell active={flagAtivo} />
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
