import { dateTimeMask } from "@/shared/utils/masks";

interface HistoryCardContentProps {
  author?: string;
  date: string;
  status?: string;
}
export function HistoryCardContent({
  author,
  date,
  status,
}: HistoryCardContentProps) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="text-sm font-bold capitalize text-info">
            {status}
          </span>
          <span className="text-xs font-medium text-gray-6">
            {dateTimeMask(date)}
          </span>
        </div>
        <div className="flex gap-1">
          <span className="text-xs font-medium text-gray-8">{author}</span>
          <span className="text-xs font-light text-gray-6">
            realizou essa ação
          </span>
        </div>
      </div>
    </div>
  );
}
