// sort-imports-ignore
import "./instrument";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router/dom";

import { GlobalToastRegion } from "@/shared/components/toast";
import router from "@/shared/router";

import "./index.css";
import { queryClient } from "./shared/utils";
import { I18nProvider } from "react-aria";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <I18nProvider locale="pt-BR">
      <QueryClientProvider client={queryClient}>
        <GlobalToastRegion />
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </QueryClientProvider>
    </I18nProvider>
  </React.StrictMode>,
);
