import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsFalhaGetData, apiProvider } from "@/shared/services";

type UseInfiniteFailureFilters = Omit<
  GetObjsFalhaGetData,
  "page" | "elementsPerPage"
>;
export function useInfiniteFailure(initial?: UseInfiniteFailureFilters) {
  const trigger = useIntersectionObserver({
    threshold: 0.5,
  });

  const [filters, updateFilters] = useObjReducer<UseInfiniteFailureFilters>({
    ...initial,
  });

  const query = useInfiniteQuery({
    queryKey: ["failureInfinite", filters],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => {
      return apiProvider.services.FalhasService.getObjsFalhaGet({
        page: pageParam,
        elementsPerPage: PAGE_COUNT,
        ...filters,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      const apiPages = Math.ceil(lastPage.total / lastPage.elementPerPage);
      return apiPages > pages.length ? pages.length : undefined;
    },
  });

  const { hasNextPage, isFetching, fetchNextPage } = query;
  useEffect(() => {
    if (trigger.isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, fetchNextPage, trigger.isIntersecting]);

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  const flatData = useMemo(
    () => query.data?.pages.flatMap((p) => p.content),
    [query.data?.pages],
  );

  return [
    { flatData, ...query },
    { filters, updateFilters, handleSearchChange },
    trigger,
  ] as const;
}
