import { CloseSquare } from "iconsax-react";
import { Link } from "react-router";

import { PageTitle } from ".";

export interface DrawerlessLayoutProps extends React.PropsWithChildren {
  title: string;
}

export function DrawerlessLayout({ title, children }: DrawerlessLayoutProps) {
  return (
    <div className="overflow-y-auto p-8 px-16">
      <div className="rounded-t-lg bg-gradient-to-b from-primary/10 to-white-0 to-85% px-6">
        <div className="flex items-center justify-between py-8">
          <PageTitle primary>{title}</PageTitle>
          <Link
            className="flex flex-col items-end text-sm text-primary"
            to="/app"
          >
            <CloseSquare variant="Bulk" size="1.75rem" />
            <p>Voltar ao início</p>
          </Link>
        </div>
      </div>
      <div className="bg-white-0" />
      {children}
    </div>
  );
}
