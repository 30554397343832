import { useParams } from "react-router";

import { Am, AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useIsCompanyRole, useSwitch } from "@/shared/hooks";
import { useContacts } from "@/shared/hooks/api";

import { ContactModalAssociate, ContactTable } from "../components";

export function ContactList() {
  const { developmentId } = useParams();
  const isCompanyRole = useIsCompanyRole();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useContacts({
      empreendimentoId: developmentId ? +developmentId : 0,
      text: "",
      status: isCompanyRole ? true : undefined,
    });

  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <Am I="SeuManual">
      {(isSuperUser) => (
        <AnimatedContainer className="space-y-4">
          <FilterAndCreate
            resourceName="contato"
            onSearchChange={handleSearchChange}
            createOnPress={developmentId && isSuperUser ? turnOn : undefined}
            createAction="create.contact"
          />
          <ContactTable
            search={filters.text ?? undefined}
            rowsData={data?.content}
            isLoading={isLoading}
            pagination={{
              currentPage: filters.page ?? 1,
              pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
              onPageChange: (page) => updateFilters({ page }),
            }}
          />
          {developmentId && (
            <ContactModalAssociate
              developmentId={+developmentId}
              isOpen={isOn}
              onOpenChange={setOpen}
              onSuccess={turnOff}
            />
          )}
        </AnimatedContainer>
      )}
    </Am>
  );
}
