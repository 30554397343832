import { Handle, Navigate, RouteObject } from "react-router";

import { contactCreateAction } from "@/shared/modules/contact/actions";
import {
  ContactCreate,
  ContactDetail,
  ContactList,
  EditContact,
} from "@/shared/modules/contact/containers";
import { editContactAction } from "@/shared/modules/contact/modules/edit/actions";
import {
  memorialCreateAction,
  memorialEditAction,
} from "@/shared/modules/memorial/actions";
import {
  MemorialCreate,
  MemorialDetail,
  MemorialEdit,
  MemorialList,
} from "@/shared/modules/memorial/containers";
import {
  postCreateAction,
  postEditAction,
} from "@/shared/modules/post/actions";
import {
  PostCreate,
  PostDetail,
  PostEdit,
  PostList,
} from "@/shared/modules/post/containers";
import {
  preventiveCreateAction,
  preventiveEditAction,
} from "@/shared/modules/preventive/actions";
import {
  PreventiveCreate,
  PreventiveDetail,
  PreventiveEdit,
  PreventiveList,
} from "@/shared/modules/preventive/containers";
import {
  productCreateAction,
  productEditAction,
} from "@/shared/modules/product/actions";
import {
  ProductCreate,
  ProductDetail,
  ProductDetailLayout,
  ProductEdit,
  ProductList,
} from "@/shared/modules/product/containers";
import { systemCreateAction } from "@/shared/modules/system/actions";
import { SystemCreate, SystemList } from "@/shared/modules/system/containers";
import { systemDetailRouter } from "@/shared/modules/system/modules/detail/router";
import {
  failureCreateAction,
  failureEditAction,
  warrantyCreateAction,
  warrantyEditAction,
} from "@/shared/modules/warranty/actions";
import {
  FailureCreate,
  FailureDetail,
  FailureEdit,
  FailureList,
  WarrantyCreate,
  WarrantyDetail,
  WarrantyDetailLayout,
  WarrantyEdit,
  WarrantyList,
} from "@/shared/modules/warranty/containers";

import { nbrCreateAction, nbrEditAction } from "./actions";
import {
  RecordLayout,
  RecordNbrCreate,
  RecordNbrDetail,
  RecordNbrEdit,
  RecordNbrList,
} from "./containers";

export const recordRouter: RouteObject = {
  path: "cadastros",
  handle: {
    tabBaseUrl: "record",
  } as Handle,
  children: [
    { index: true, element: <Navigate to="contatos/" /> },
    {
      element: <RecordLayout />,
      children: [
        {
          path: "contatos",
          handle: {
            crumb: "Lista de contatos",
            action: "list.contact",
          } as Handle,
          element: <ContactList />,
        },
        {
          path: "sistema",
          handle: {
            crumb: "Lista de sistemas",
            action: "list.system",
          } as Handle,
          element: <SystemList />,
        },
        {
          path: "ambiente",
          handle: {
            crumb: "Lista de ambientes",
            action: "list.memorial",
          } as Handle,
          element: <MemorialList />,
        },
        {
          path: "post",
          handle: {
            crumb: "Lista de posts",
            action: "list.post",
          } as Handle,
          element: <PostList />,
        },
        {
          path: "produtos",
          handle: {
            crumb: "Lista de produtos",
            action: "list.product",
          } as Handle,
          element: <ProductList />,
        },
        {
          path: "nbr",
          handle: {
            crumb: "Lista de NBRs",
            action: "list.nbr",
          } as Handle,
          element: <RecordNbrList />,
        },
      ],
    },
    {
      path: "contatos",
      handle: {
        crumb: "Lista de contatos",
        action: "list.contact",
      } as Handle,
      children: [
        {
          path: "detalhes/:contactId",
          handle: {
            crumb: "Detalhes do contato",
            action: "get.contact",
          } as Handle,
          children: [
            {
              index: true,
              element: <ContactDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar contato",
                action: "update.contact",
              } as Handle,
              action: editContactAction,
              element: <EditContact />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar contato",
            action: "create.contact",
          } as Handle,
          action: contactCreateAction,
          element: <ContactCreate />,
        },
      ],
    },
    {
      path: "sistema",
      handle: {
        crumb: "Lista de sistemas",
        action: "list.system",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar sistema",
            action: "create.system",
          } as Handle,
          action: systemCreateAction,
          element: <SystemCreate />,
        },
        systemDetailRouter,
      ],
    },
    {
      path: "ambiente",
      handle: {
        crumb: "Lista de ambientes",
        action: "list.memorial",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar ambiente",
            action: "create.memorial",
          } as Handle,
          action: memorialCreateAction,
          element: <MemorialCreate />,
        },
        {
          path: "detalhes/:memorialId",
          handle: {
            crumb: "Detalhes do ambiente",
            actions: "get.memorial",
          } as Handle,
          children: [
            {
              index: true,
              element: <MemorialDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar ambiente",
                action: "update.memorial",
              } as Handle,
              action: memorialEditAction,
              element: <MemorialEdit />,
            },
          ],
        },
      ],
    },
    {
      path: "post",
      handle: {
        crumb: "Lista de posts",
        action: "list.post",
      } as Handle,
      children: [
        {
          path: "detalhes/:postId",
          handle: {
            crumb: "Detalhes do post",
            action: "get.post",
          } as Handle,
          children: [
            {
              index: true,
              element: <PostDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar post",
                action: "update.post",
              } as Handle,
              action: postEditAction,
              element: <PostEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar post",
            action: "create.post",
          } as Handle,
          action: postCreateAction,
          element: <PostCreate />,
        },
      ],
    },
    {
      path: "produtos",
      handle: {
        crumb: "Lista de produtos",
        action: "list.product",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar produto",
            action: "create.product",
          } as Handle,
          action: productCreateAction,
          element: <ProductCreate />,
        },
        {
          path: "detalhes/:productId",
          handle: {
            crumb: "Detalhes do produto",
            action: "get.product",
            tabBaseUrl: "product",
          } as Handle,
          children: [
            {
              element: <ProductDetailLayout />,
              children: [
                {
                  index: true,
                  element: <ProductDetail />,
                },
                {
                  path: "garantia",
                  handle: {
                    crumb: "Lista de garantias",
                    action: "list.warranty",
                  } as Handle,
                  element: <WarrantyList />,
                },
                {
                  path: "preventivo",
                  handle: {
                    crumb: "Lista de preventivos",
                    action: "list.preventive",
                  } as Handle,
                  element: <PreventiveList />,
                },
              ],
            },
            {
              path: "garantia",
              handle: {
                crumb: "Lista de garantias",
                action: "list.warranty",
              } as Handle,
              children: [
                {
                  path: "detalhes/:warrantyId",
                  handle: {
                    crumb: "Detalhes da garantia",
                    tabBaseUrl: "warranty",
                    action: "get.warranty",
                  } as Handle,
                  children: [
                    {
                      element: <WarrantyDetailLayout />,
                      children: [
                        {
                          index: true,
                          element: <WarrantyDetail />,
                        },
                        {
                          path: "falhas",
                          handle: {
                            crumb: "Lista de falhas",
                            action: "list.failure",
                          } as Handle,
                          element: <FailureList />,
                        },
                      ],
                    },
                    {
                      path: "falhas",
                      handle: {
                        crumb: "Lista de falhas",
                        action: "list.failure",
                      } as Handle,
                      children: [
                        {
                          path: "detalhes/:failureId",
                          handle: {
                            crumb: "Detalhes da falha",
                            action: "get.failure",
                          } as Handle,
                          children: [
                            {
                              index: true,
                              element: <FailureDetail />,
                            },
                            {
                              path: "editar",
                              handle: {
                                crumb: "Editar falha",
                                action: "update.failure",
                              } as Handle,
                              action: failureEditAction,
                              element: <FailureEdit />,
                            },
                          ],
                        },
                        {
                          path: "cadastrar",
                          handle: {
                            crumb: "Cadastrar falha",
                            action: "create.failure",
                          } as Handle,
                          action: failureCreateAction,
                          element: <FailureCreate />,
                        },
                      ],
                    },
                    {
                      path: "editar",
                      handle: {
                        crumb: "Editar garantia",
                        action: "update.warranty",
                      } as Handle,
                      action: warrantyEditAction,
                      element: <WarrantyEdit />,
                    },
                  ],
                },
                {
                  path: "cadastrar",
                  handle: {
                    crumb: "Cadastrar garantia",
                    action: "create.warranty",
                  } as Handle,
                  action: warrantyCreateAction,
                  element: <WarrantyCreate />,
                },
              ],
            },
            {
              path: "preventivo",
              handle: {
                crumb: "Lista de preventivos",
                action: "list.preventive",
              } as Handle,
              children: [
                {
                  path: "detalhes/:preventiveId",
                  handle: {
                    crumb: "Detalhes do preventivo",
                    action: "get.preventive",
                  } as Handle,
                  children: [
                    {
                      index: true,
                      element: <PreventiveDetail />,
                    },
                    {
                      path: "editar",
                      handle: {
                        crumb: "Editar preventivo",
                        action: "update.preventive",
                      } as Handle,
                      action: preventiveEditAction,
                      element: <PreventiveEdit />,
                    },
                  ],
                },
                {
                  path: "cadastrar",
                  handle: {
                    crumb: "Cadastrar preventivo",
                    action: "create.preventive",
                  } as Handle,
                  action: preventiveCreateAction,
                  element: <PreventiveCreate />,
                },
              ],
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar produto",
                action: "update.product",
              } as Handle,
              action: productEditAction,
              element: <ProductEdit />,
            },
          ],
        },
      ],
    },
    {
      path: "nbr",
      handle: {
        crumb: "Lista de NBRs",
        action: "list.nbr",
      } as Handle,
      children: [
        {
          path: "detalhes/:nbrId",
          handle: {
            crumb: "Detalhes do NBR",
            action: "get.nbr",
          } as Handle,
          children: [
            {
              index: true,
              element: <RecordNbrDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar NBR",
                action: "update.nbr",
              } as Handle,
              action: nbrEditAction,
              element: <RecordNbrEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar NBR",
            action: "create.nbr",
          } as Handle,
          action: nbrCreateAction,
          element: <RecordNbrCreate />,
        },
      ],
    },
  ],
};
