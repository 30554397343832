import { get } from "lodash";
import React from "react";

import { Link, SearchInput, SearchInputProps } from "@/shared/components";

import { useAuthorization } from "../contexts";
import { permissions } from "../utils";

interface FilterAndCreateProps extends React.PropsWithChildren {
  resourceName?: string;
  onSearchChange?: SearchInputProps["onDebounceChange"];
  createOnPress?: () => void;
  createAction?: AuthActions;
}

export function FilterAndCreate({
  resourceName,
  onSearchChange,
  createOnPress,
  createAction,
  children,
}: FilterAndCreateProps) {
  const { ability } = useAuthorization();

  const resourceNameDefined = resourceName !== undefined;
  const authorized =
    !createAction || ability.can(get(permissions, createAction));

  return (
    <div className="flex gap-2">
      <SearchInput className="flex-1" onDebounceChange={onSearchChange} />
      <div className="flex items-center">{children}</div>
      {authorized && resourceNameDefined && (
        <Link
          className="cursor-pointer px-16 py-3 font-medium"
          href={createOnPress ? undefined : "cadastrar/"}
          onPress={createOnPress}
        >
          Adicionar {resourceName}
        </Link>
      )}
    </div>
  );
}
