import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { PreventiveCreate } from "../schemas";

export async function preventiveCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<PreventiveCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      await apiProvider.services.PreventivosService.createObjPreventivoPost({
        requestBody,
      });

      toastQueue.add(
        {
          type: "success",
          message: "Preventivo adicionado no subsistema com sucesso",
        },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
