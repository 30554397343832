import { Trash } from "iconsax-react";
import { DialogTrigger } from "react-aria-components";

import { Button, DeleteModal, DeleteModalProps } from "@/shared/components";

export interface DeleteResourceButtonProps
  extends Pick<
    DeleteModalProps,
    "resource" | "action" | "showSpinner" | "subject" | "isResourceFeminine"
  > {}

export function DeleteResourceButton(props: DeleteResourceButtonProps) {
  return (
    <DialogTrigger>
      <Button className="p-0" styleType="default">
        <Trash className="text-error" />
      </Button>

      <DeleteModal size="compact" {...props} />
    </DialogTrigger>
  );
}
