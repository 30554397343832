import { Outlet, useLocation } from "react-router";

import { Breadcrumbs, PageTitle, TabPanel, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function CompanyLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("company");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados da construtora",
      action: "get.company",
    },
    {
      id: `${baseUrl}empreendimentos/`,
      href: `${baseUrl}empreendimentos/`,
      label: "Lista de empreendimentos",
      action: "list.development",
    },
  ]);

  return (
    <div className="flex h-full flex-col overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <Breadcrumbs />
      <PageTitle className="py-4">Detalhes da construtora</PageTitle>
      <Tabs
        aria-label="Módulos da construtora"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
