import { Time } from "@internationalized/date";

import { AssistanceAvailability } from "@/modules/assistance/modules/config/modules/availability/schemas";
import { TimeInterval } from "@/shared/components/form/availability/time-interval";
import { Construtora, Intervalo } from "@/shared/services";

import { Interval } from "./schema";

export function parseInterval(value: Intervalo): Interval {
  return new TimeInterval(
    new Time(value.inicio.hora, value.inicio.minuto),
    new Time(value.fim.hora, value.fim.minuto),
  );
}

export function serializeInterval(value: Interval): Intervalo {
  return {
    inicio: {
      hora: value.start.hour,
      minuto: value.start.minute,
    },
    fim: {
      hora: value.end.hour,
      minuto: value.end.minute,
    },
  };
}

export function deserializeDisponibilidade(
  disponibilidade: Construtora["disponibilidade"],
): AssistanceAvailability["disponibilidade"] {
  return (
    disponibilidade?.map(
      (dayIntervals) =>
        dayIntervals?.map((interval) => parseInterval(interval)) ?? null,
    ) ?? []
  );
}
export function serializeDisponibilidade(
  disponibilidade: AssistanceAvailability["disponibilidade"],
): NonNullable<Construtora["disponibilidade"]> {
  return (
    disponibilidade?.map(
      (dayIntervals) =>
        dayIntervals?.map((interval) => serializeInterval(interval)) ?? null,
    ) ?? []
  );
}
