import { useState } from "react";
import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import {
  WarrantyCommon,
  WarrantyCommonSchema,
} from "@/shared/modules/warranty/modules/create/schemas";
import { z } from "@/shared/utils";

import { AddWarrantyModal } from ".";

const WarrantyContainerSchema = z.object({
  garantias: z.array(WarrantyCommonSchema).default([]),
});

type WarrantyContainer = z.infer<typeof WarrantyContainerSchema>;

interface FormWarrantyListProps<T extends WarrantyContainer> {
  className?: string;
  control: Control<T>;
}

export function FormWarrantyList<T extends WarrantyContainer>({
  control,
  ...props
}: FormWarrantyListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<WarrantyContainer>;
  const [currentWarranty, setCurrentWarranty] = useState<WarrantyCommon>();

  return (
    <FormListInput
      {...props}
      control={_control}
      name="garantias"
      onAddPress={() => {
        setCurrentWarranty(undefined);
        turnOn();
      }}
      resourceOptions={{
        word: "garantia",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
                onEdit={() => {
                  setCurrentWarranty(e);
                  turnOn();
                }}
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          {isOn && (
            <AddWarrantyModal
              isOpen={isOn}
              onOpenChange={setOpen}
              useFormProps={{ defaultValues: currentWarranty }}
              onAdd={(value) => {
                if (currentWarranty) {
                  const updatedWarranty = values.map((warranty) =>
                    warranty === currentWarranty ? value : warranty,
                  );
                  update(updatedWarranty);
                  return turnOff();
                }
                update([...values, value]);
                return turnOff();
              }}
            />
          )}
        </>
      )}
    </FormListInput>
  );
}
