import {
  ActivityCard,
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
  StepDot,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteComments } from "@/shared/hooks/api";

import { CommentCardContent, CommentCreateForm } from "../components";

export function CommentList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteComments({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 rounded-lg bg-white-0 px-6 pt-4">
      <span className="text-base font-bold text-black-2">Comentários</span>
      <Separator />
      <div className="mb-5 flex max-h-[50vh] flex-col-reverse overflow-y-auto">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((comment, index) => (
            <StepDot
              key={comment.id}
              ref={index === flatData?.length - 1 ? ref : null}
            >
              <ActivityCard>
                <CommentCardContent
                  author={comment.usuario.nome}
                  date={comment.criadoEm}
                  comment={comment.texto}
                />
              </ActivityCard>
            </StepDot>
          ))}
        </InfiniteQueryHandler>
      </div>
      <div className="pl-6">
        <CommentCreateForm maintenanceId={+serviceId} />
      </div>
    </AnimatedContainer>
  );
}
