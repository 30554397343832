import Signature, { SignatureRef } from "@uiw/react-signature";
import { useEffect, useRef } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { FieldError } from "..";

export interface FormSignatureProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function FormSignature<T extends FieldValues>({
  control,
  name,
}: FormSignatureProps<T>) {
  const svg = useRef<SignatureRef>(null);

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    onChange(svg.current?.svg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col">
      <div className="rounded border border-white-3">
        <Signature ref={svg} width={800} height={200} />
      </div>
      <div className="px-10">
        <p className="mt-4 border-b border-b-gray-10 pb-1 text-sm text-gray-10">
          Assine aqui
        </p>
      </div>
      <FieldError>{error?.message}</FieldError>
    </div>
  );
}
