import { falsyToNull, z } from "@/shared/utils";

export const BlockCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: falsyToNull(z.string().trim().nullable()),
  empreendimentoId: z.number().int(),
});

export type BlockCreate = z.infer<typeof BlockCreateSchema>;

export const BlockBulkCreateSchema = z.object({
  zeros: z.number().int(),
  empreendimentoId: z.number().int(),
  qtdBlocos: z.number().int(),
  qtdAndares: z.number().int(),
  qtdAptAndar: z.number().int(),
});

export type BlockBulkCreate = z.infer<typeof BlockBulkCreateSchema>;

export const BlockHouseCreateSchema = z.object({
  empreendimentoId: z.number().int(),
  qtdBlocos: z.number().int(),
  qtdCasaBloco: z.number().int(),
});

export type BlockHouseCreate = z.infer<typeof BlockHouseCreateSchema>;
