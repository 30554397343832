import { useMemo } from "react";
import { ZodTypeAny } from "zod";

import { usePaginated } from "@/shared/hooks/use-paginated";

import { Card } from "../card";
import { CircularProgress } from "../circular-progress";
import { PageStepButtons } from "../page-step-buttons";
import { FormWizardCore, FormWizardCoreProps } from "./form-wizard-core";

export interface FormWizardProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
> extends FormWizardCoreProps<TSchema, TSubmitSchema> {}

export function FormWizard<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined = undefined,
>(props: FormWizardProps<TSchema, TSubmitSchema>) {
  const { page: initialPage, steps } = props;
  const { page, isFirstPage, isLastPage, previous, setPage } = usePaginated({
    initialPage,
    pageCount: steps.length,
  });

  const currentStep = useMemo(() => steps[page - 1], [page, steps]);

  return (
    <FormWizardCore {...props} page={page} onPageChange={setPage}>
      {({ step, submitHandler }) => (
        <>
          <Card className="mb-4 flex gap-5">
            <CircularProgress
              className="size-16"
              percentage={(page / props.steps.length) * 100}
            >
              {page} de {props.steps.length}
            </CircularProgress>
            <div className="flex flex-col justify-center font-semibold text-black-2">
              {currentStep.heading.head}
            </div>
          </Card>
          {step}
          <PageStepButtons
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onPrevious={previous}
            onNext={submitHandler}
            onSubmit={submitHandler}
          />
        </>
      )}
    </FormWizardCore>
  );
}
