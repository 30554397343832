import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";

import { Dialog, Link, Modal, SearchInput, Spinner } from "@/shared/components";
import { useInfiniteNbrs } from "@/shared/hooks/api";
import { Nbr } from "@/shared/services";

interface NbrListModalProps extends Omit<ModalOverlayProps, "children"> {
  onSelected?: (id: Nbr) => void;
}

export function NbrListModal({ onSelected, ...props }: NbrListModalProps) {
  const [{ flatData, isFetching }, { handleSearchChange }, { ref }] =
    useInfiniteNbrs({ status: true });

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Escolha um NBR"
        renderButtons={() => (
          <>
            <Link
              className="px-7 py-3"
              styleType="default"
              href="/app/cadastros/nbr/cadastrar/"
            >
              Cadastrar novo NBR
            </Link>
          </>
        )}
        data-cy="nbr-modal"
      >
        {({ close }) => (
          <div className="space-y-4">
            <SearchInput onDebounceChange={handleSearchChange} />
            <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll pr-2">
              {flatData?.map((e, i) => (
                <AriaButton
                  key={e.id}
                  ref={i === flatData.length - 1 ? ref : null}
                  className="mr-4 rounded-lg border border-white-3 p-3 text-left"
                  onPress={() => {
                    onSelected?.(e);
                    close();
                  }}
                >
                  {e.nome}
                </AriaButton>
              ))}
              {isFetching && <Spinner className="mx-auto" />}
            </div>
          </div>
        )}
      </Dialog>
    </Modal>
  );
}
