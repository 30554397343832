import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { CreateUnit } from "../schemas";

export async function unitCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<CreateUnit & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      if (payload.clientCreationType === "new-client") {
        await apiProvider.services.AcopladosService.postUnidadeWithOwnerAcopladosUnidadeDonoPost(
          {
            requestBody: {
              unidade: {
                ...payload.unidade,
                // HACK: React router dom parses any object into string, including Dates
                // that's why the typing says Date but it is actually an string 🤷
                // see action serialization: https://reactrouter.com/en/main/route/action#opt-in-serialization-types
                dataEntrega: payload.unidade.dataEntrega as unknown as string,
              },
              responsavel: payload.responsavel,
            },
          },
        );
      } else {
        await apiProvider.services.UnidadesService.createObjUnidadesPost({
          requestBody: {
            ...payload.unidade,
            // HACK: React router dom parses any object into string, including Dates
            // that's why the typing says Date but it is actually an string 🤷
            // see action serialization: https://reactrouter.com/en/main/route/action#opt-in-serialization-types
            dataEntrega: payload.unidade.dataEntrega as unknown as string,
            donoId:
              payload.clientCreationType === "registered-client"
                ? payload.unidade.donoId
                : undefined,
          },
        });
      }

      toastQueue.add(
        { type: "success", message: "Unidade criada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
