import { DialogTrigger } from "react-aria-components";

import {
  Button,
  Card,
  InfiniteQueryHandler,
  SearchInput,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteFaqs, useMaintenance } from "@/shared/hooks/api";

import { FaqModal } from "./faq-modal";

export function FaqData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  const [{ flatData, isFetching, error }, { handleSearchChange }, { ref }] =
    useInfiniteFaqs({
      subsistemaId: maintenanceQuery.data?.subsistemaId,
      text: "",
    });

  return (
    <>
      <div className="mb-4 flex">
        <SearchInput className="w-full" onDebounceChange={handleSearchChange} />
      </div>
      <div className="flex max-h-60 flex-col gap-2 overflow-y-auto">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((faq, i) => (
            <Card
              key={faq.id}
              ref={i === flatData.length - 1 ? ref : null}
              className="flex w-full items-center justify-between"
            >
              <p className="text-xs font-semibold text-typography-1">
                {faq.nome}
              </p>
              <DialogTrigger>
                <Button styleType="default" className="text-xs">
                  Ver resposta
                </Button>
                <FaqModal faq={faq} />
              </DialogTrigger>
            </Card>
          ))}
        </InfiniteQueryHandler>
      </div>
    </>
  );
}
