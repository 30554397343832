import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Contato, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationAddDevelopmentContactProps
  extends Omit<
    UseMutationOptions<Contato, Error, AddDevelopmentContactPayload>,
    "mutationFn"
  > {}

export interface AddDevelopmentContactPayload {
  contactId: number;
  developmentId: number;
}

export function useMutationAddDevelopmentContact(
  options?: UseMutationAddDevelopmentContactProps,
) {
  return useMutation({
    mutationFn: async ({
      contactId,
      developmentId,
    }: AddDevelopmentContactPayload) => {
      const contact =
        await apiProvider.services.ContatosService.getOneObjContatosIdGet({
          id: contactId,
        });
      return await apiProvider.services.ContatosService.createObjContatosPost({
        requestBody: { ...contact, empreendimentoId: developmentId },
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar contato no empreendimento: ${e.message}`,
      }),
    ...options,
  });
}
