import { ActionFunctionArgs, redirect } from "react-router";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { WorkerCreate } from "../../../schemas";

export async function editWorkerAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<Partial<WorkerCreate> & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      await apiProvider.services.ColaboradorService.updateColaboradorColaboradorIdPut(
        {
          id: +params.companyWorkerId!,
          requestBody: payload,
        },
      );

      toastQueue.add(
        {
          type: "success",
          message: "Usuário administrativo atualizado com sucesso",
        },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
