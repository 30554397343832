import { ModalOverlayProps } from "react-aria-components";
import { useParams } from "react-router";

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Modal,
  SearchInput,
  Spinner,
} from "@/shared/components";
import { useInfiniteUnits } from "@/shared/hooks/api";

import { SystemUnit } from "../schemas";

interface UnitSystemListModalProps extends Omit<ModalOverlayProps, "children"> {
  onAdd?: (units: SystemUnit[]) => void;
  selected: SystemUnit[];
  setSelected: React.Dispatch<React.SetStateAction<SystemUnit[]>>;
  tipoClienteId: number;
}

export function UnitSystemListModal({
  onAdd,
  selected,
  setSelected,
  tipoClienteId,
  ...props
}: UnitSystemListModalProps) {
  const { developmentId } = useParams();

  const [{ flatData, isFetching }, { handleSearchChange }, { ref }] =
    useInfiniteUnits({
      status: true,
      tipoId: tipoClienteId,
      empreendimentoId: Number(developmentId),
    });

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Escolha uma ou mais unidades"
        data-cy="category-company-modal"
        renderButtons={({ close }) => (
          <>
            <Button
              className="px-7 py-3"
              onPress={() => {
                onAdd?.(selected);
                close();
              }}
            >
              Concluir
            </Button>
          </>
        )}
      >
        <div className="space-y-4">
          <SearchInput onDebounceChange={handleSearchChange} />
          <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll pr-2">
            <CheckboxGroup
              aria-label="Modulos do empreendimento"
              validationBehavior="aria"
              onChange={(e) =>
                setSelected(
                  flatData
                    ?.map((e) => ({
                      ...e,
                      nome: `[${e.bloco.nome}] ${e.nome}`,
                    }))
                    .filter((el) => e.includes(el.id.toString())) || [],
                )
              }
              value={selected.map((e) => e.id.toString())}
            >
              {flatData?.map((e, i) => (
                <Checkbox
                  key={e.id}
                  className="react-aria-Checkbox group mr-4 rounded-lg border border-white-3 p-3 text-left"
                  value={e.id.toString()}
                  ref={i === flatData.length - 1 ? ref : null}
                >
                  <div className="flex w-full items-start transition-all">
                    <span className="text-center group-rac-selected:font-bold">
                      [{e.bloco.nome}] {e.nome}
                    </span>
                  </div>
                </Checkbox>
              ))}
            </CheckboxGroup>
            {isFetching && <Spinner className="mx-auto" />}
          </div>
        </div>
      </Dialog>
    </Modal>
  );
}
