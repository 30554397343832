import { Breadcrumbs, ErrorContainer, PageTitle } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface PostDetailErrorProps {
  error: Error;
}

export function PostDetailError({ error }: PostDetailErrorProps) {
  return (
    <>
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Erro</PageTitle>
      </div>
      <div className="space-y-4">
        <ErrorContainer
          message={
            error instanceof ApiError && error.status === 404
              ? "Post não encontrado..."
              : undefined
          }
        />
      </div>
    </>
  );
}
